import React from "react";
import { Loading } from "react-admin";
import { Divider, Typography, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  row: {
    padding: "20px 0 20px 0",
    textAlign: "left",
    borderBottom: "1px solid rgb(235, 235, 235)",
    display: "table !important",
    width: "100% !important",
    borderSpacing: "0px !important",
    "&:last-child": {
      borderBottom: "0px none white",
    },
  },
  leftCell: {
    display: "table-cell !important",
    width: "100% !important",
    verticalAlign: "top !important",
  },
  rightCell: {
    display: "table-cell !important",
    verticalAlign: "top !important",
  },
});

const YearlyPayoutsDetails = ({ loading, data, error, classes }) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR, {error.message}</p>;
  }
  return (
    <div>
      <Divider />
      <div className={classes.row}>
        <Typography variant="subtitle1" className={classes.leftCell}>
          Hours Used
        </Typography>
        <Typography variant="subtitle1" className={classes.rightCell}>
          {data.hoursUsed}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="subtitle1" className={classes.leftCell}>
          Payable Cancelled Hours
        </Typography>
        <Typography variant="subtitle1" className={classes.rightCell}>
          {data.hoursCancelled}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="subtitle1" className={classes.leftCell}>
          Yearly Earnings (in local currency)
        </Typography>
        <Typography variant="subtitle1" className={classes.rightCell}>
          {Number(data.payoutUsed).toLocaleString()}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography variant="subtitle1" className={classes.leftCell}>
          Yearly Earnings (from cancellations) (in local currency)
        </Typography>
        <Typography variant="subtitle1" className={classes.rightCell}>
          {Number(data.payoutCancelled).toLocaleString()}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(YearlyPayoutsDetails);

/* global Intercom */

import {
  GetMeRequest,
  UpdateMeRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/user/me/v1/me_pb";
import { Name } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/name_pb";
import providers from "./providers";
import { GET_ONE, UPDATE } from "react-admin";
import isEqual from "lodash/isEqual";
import * as storage from "../utils/storage";

const me = (type, params) => {
  switch (type) {
    case GET_ONE:
      return get(params);
    case UPDATE:
      return update(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for bookings ${type}`
      );
  }
};

export default me;

const get = async (params) => {
  const { Me } = providers;
  const request = new GetMeRequest();
  const response = await Me.getMe(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  const user = response.getResult().toObject();

  if (!user.name) {
    user.name = {};
  }

  if (!user.name.first) {
    user.name.first = "";
  }

  if (!user.name.last) {
    user.name.last = "";
  }

  if (window.errorHandler) {
    window.errorHandler.setUser(user.id);
  }
  storage.set("user", user);

  // Record when we last fetched this user
  storage.set("authFetchedAt", new Date().toUTCString(), false);

  if (!user.organisationsList) {
    localStorage.clear();
    throw new Error(
      "You're not an administrator of any spaces. Please contact operators@desana.io."
    );
  }

  let currentOrg = storage.get("currentOrganisation");
  if (!currentOrg) {
    storage.set("currentOrganisation", user.organisationsList[0]);
    currentOrg = user.organisationsList[0];
  } else {
    const updatedOrg =
      currentOrg &&
      currentOrg.organisation &&
      user.organisationsList.find(
        (org) => org.organisation.id === currentOrg.organisation.id
      );

    if (!isEqual(currentOrg, updatedOrg)) {
      storage.set("currentOrganisation", updatedOrg);
      currentOrg = storage.get("currentOrganisation");
      if (!isEqual(currentOrg.id, updatedOrg.id)) {
        window.location.reload();
        return;
      }
    }
  }

  if (import.meta.env.VITE_INTERCOM_APP_ID) {
    window.intercomSettings = {
      is_operator_admin: true,
      operator_dash_version: import.meta.env.VITE_CF_PAGES_COMMIT_SHA,
    };
    Intercom("boot", {
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      name: `${user.name?.first} ${user.name?.last}`,
      email: user.email,
      created_at: Math.round(new Date(user.createdAt).getTime() / 1000),
      user_id: user.id,
      user_hash: user.intercomData?.webHash,
      company: {
        id: currentOrg.organisation.id,
        name: currentOrg.organisation.name,
      },
    });
  }

  return {
    data: { ...user, id: params ? params.id : "" },
  };
};

const update = async (params) => {
  const { Me } = providers;
  const { data, previousData, id } = params;
  const updateRequest = new UpdateMeRequest();

  if (!isEqual(data.name, previousData.name)) {
    updateRequest.addFieldsToUpdate("name");
    const name = new Name();
    name.setFirst(data.name.first);
    name.setLast(data.name.last);
    updateRequest.setName(name);
  }

  if (!isEqual(data.hometown, previousData.hometown)) {
    updateRequest.addFieldsToUpdate("hometown");
    updateRequest.setHometown(data.hometown);
  }

  if (!isEqual(data.bio, previousData.bio)) {
    updateRequest.addFieldsToUpdate("bio");
    updateRequest.setBio(data.bio);
  }

  if (updateRequest.getFieldsToUpdateList().length === 0) {
    return Promise.resolve({ data });
  }

  const response = await Me.updateMe(updateRequest, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  const meResult = response.getResult();

  storage.set("user", meResult.toObject());

  return {
    data: {
      ...meResult.toObject(),
      id,
    },
  };
};

import { GET_LIST, UPDATE } from "react-admin";
import providers from "./providers";
import * as storage from "../utils/storage";
import { getSpacesForSpaceRole } from "../utils/auth";
import { SpaceAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import {
  ListPendingResourceBookingRequestsRequest,
  RespondToResourceBookingRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/booking/v1/resource_booking_pb";

const pendingResourceBookings = (type, params) => {
  switch (type) {
    case GET_LIST:
      return list(params);
    case UPDATE:
      return update(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for bookings ${type}`
      );
  }
};

export default pendingResourceBookings;

const list = async () => {
  const { Bookings } = providers;
  const request = new ListPendingResourceBookingRequestsRequest();
  const currentOrg = storage.get("currentOrganisation");
  request.setOwner(currentOrg.organisation.id);

  const viewBookingPermissions = getSpacesForSpaceRole(currentOrg, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
  ]);

  // If we don't have permission to edit bookings
  if (viewBookingPermissions === false) {
    throw new Error("Permission denied");
  }

  if (viewBookingPermissions !== true) {
    request.setSpacesList(viewBookingPermissions);
  }
  const response = await Bookings.listPendingResourceBookingRequests(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  const { count, resultsList } = response.toObject();

  return {
    total: count,
    data: resultsList.map((result) => ({
      ...result,
      bookingId: result.id,
      bookingSlotId: result.bookingSlot,
      id: `${result.id},${result.bookingSlot}`,
      timezone: result?.resource?.timezone || result.space?.timezone,
    })),
  };
};

const update = async (params) => {
  const { Bookings } = providers;
  const { data, id } = params;
  const request = new RespondToResourceBookingRequest();
  request
    .setApproved(data.approved)
    .setBookingId(data.bookingId)
    .setBookingSlotId(data.bookingSlotId);

  if (!data.approved) {
    request
      .setDesanaMessage(data.desanaMessage)
      .setUserMessage(data.userMessage)
      .setRejectionReason(data.rejectionReason);
  }

  const response = await Bookings.respondToResourceBooking(request, {
    authorization: storage.get("desana-tkn", false),
  });

  if (!response.getSuccess()) {
    throw new Error(
      "Something went wrong when responding to the booking request!"
    );
  }

  return { data: { ...data, id } };
};

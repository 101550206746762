import Big from "big.js";

const getFullPrice = (rate, duration) =>
  rate && duration ? Big(rate).mul(duration).round(2).toNumber() : 0;

const formatRates = (rates) => ({
  ...rates,
  fullDayFullPrice: getFullPrice(rates?.fullDay, rates?.fullDayDuration),
  halfDayFullPrice: getFullPrice(rates?.halfDay, rates?.halfDayDuration),
});

const getHourlyFromFullPrice = (fullPrice, duration) =>
  fullPrice && duration ? Big(fullPrice).div(duration).round(2).toNumber() : 0;

export { formatRates, getHourlyFromFullPrice };

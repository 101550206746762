import { BookingStatus } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/booking_pb";
import { Card, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrayField,
  Datagrid,
  EmailField,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useResourceContext,
} from "react-admin";
import { hoursToHoursAndMinutes } from "../common/hours-to-hours-and-minutes";
import { CurrencyField } from "../components/CurrencyField";
import LocalDateTimeField from "../components/LocalDateTimeField";
import RespondToBookingRequest from "../components/RespondToBookingRequest";
import SquareImageField from "../components/SquareImageField";
import UserProfileQuickPreviewButton from "../components/UserProfileQuickPreviewButton";
import Attendees from "./Attendees";

const BookingTitle = ({ record }) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: record.space.timezone,
  };

  return (
    <span>
      Overview{" "}
      {record
        ? `for ${new Date(record.startsAt).toLocaleDateString(
            navigator.locale,
            options
          )} for ${record.resource.name} at ${record.space.name}`
        : ""}
    </span>
  );
};

const useStyles = makeStyles({
  summary: {
    cursor: "pointer",
  },
  inline: {
    display: "inline",
  },
});

const useIsPrivateOfficeContext = () => {
  const name = useResourceContext();
  return name === "privateOfficeBookings";
};

const ResourceDetailsAside = ({ record }) => {
  const isPrivateOfficeContext = useIsPrivateOfficeContext();
  if (!record) {
    return null;
  }

  return (
    <Card style={{ width: 280, marginLeft: 20 }}>
      <SimpleShowLayout record={record}>
        <Typography variant="h6">{record.resource.name}</Typography>
        <div>{record.space.name}</div>
        <br />
        {isPrivateOfficeContext && (
          <Typography paragraph={true}>
            Note: this represents all Private Day Offices at this space
          </Typography>
        )}
        <SquareImageField
          size="m"
          record={record}
          source="resource.image"
          label=""
          sortable={false}
        />

        <LocalDateTimeField
          record={record}
          source="startsAt"
          timezoneSource="resource.timezone"
          label="Date"
          showDate={true}
          showTime={false}
          locales={navigator.language}
        />
        <FunctionField
          label="Capacity"
          render={({ resourceCapacityRange }) => {
            if (!resourceCapacityRange) {
              return <div>-</div>;
            }
            return (
              <div>
                {resourceCapacityRange.lo} - {resourceCapacityRange.hi}
              </div>
            );
          }}
        />
        <FunctionField
          label="Available?"
          render={({ status }) =>
            status === BookingStatus.BOOKING_STATUS_AVAILABLE ? (
              <TextField
                source="text"
                record={{
                  text: "Open",
                }}
              />
            ) : (
              <TextField
                source="text"
                style={{ fontWeight: "bold" }}
                record={{
                  text: "Closed",
                }}
              />
            )
          }
        />
        <Divider />
        <LocalDateTimeField
          record={record}
          source="startsAt"
          timezoneSource="resource.timezone"
          label="Opening time"
          showDate={false}
          showTime
          locales={navigator.language}
          options={{ hour: "2-digit", minute: "2-digit" }}
        />
        <LocalDateTimeField
          record={record}
          source="endsAt"
          timezoneSource="resource.timezone"
          label="Closing time"
          showDate={false}
          showTime
          locales={navigator.language}
          options={{ hour: "2-digit", minute: "2-digit" }}
        />
        <Divider />
        <CurrencyField
          label="Total estimated payout"
          source="totalCost"
          spaceId={record.space?.id}
          addLabel={true}
          style={{ fontWeight: "bold" }}
        />
      </SimpleShowLayout>
    </Card>
  );
};

const memberRowStyle = (record) => ({
  textDecoration: record.cancelledAt ? "line-through" : "none",
});

const ResourceBookingsShow = (props) => {
  const classes = useStyles();
  return (
    <Show {...props} title={<BookingTitle />} aside={<ResourceDetailsAside />}>
      <SimpleShowLayout>
        <FunctionField
          addLabel={false}
          render={(record) => {
            if (record.pendingMembersList.length === 0) {
              return null;
            }
            return (
              <>
                <Typography variant="h6">
                  🚨 Pending booking requests
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Please respond to these booking requests as soon as possible.
                </Typography>
                <ArrayField
                  source="pendingMembersList"
                  label="Please respond to these booking requests as soon as possible."
                >
                  <Datagrid rowStyle={memberRowStyle}>
                    <UserProfileQuickPreviewButton
                      source="user"
                      sortable={false}
                      label=""
                    />
                    <LocalDateTimeField
                      source="startsAt"
                      timezoneSource="timezone"
                      label="Arriving"
                      showTime
                      showDate={false}
                      sortable={false}
                      options={{ hour: "2-digit", minute: "2-digit" }}
                    />
                    <LocalDateTimeField
                      source="endsAt"
                      timezoneSource="timezone"
                      label="Leaving"
                      showTime
                      showDate={false}
                      sortable={false}
                      options={{ hour: "2-digit", minute: "2-digit" }}
                    />

                    <FunctionField
                      label="Duration"
                      render={(record) => hoursToHoursAndMinutes(record.hours)}
                    />
                    <FunctionField
                      label="Attendees"
                      render={(bookingSlot) => (
                        <Attendees
                          bookingSlot={bookingSlot}
                          resource={record.resource}
                        />
                      )}
                    />
                    <FunctionField
                      sortable={false}
                      label="Estimated payout"
                      render={(record) => {
                        if (record.skipOperatorPayout) {
                          return (
                            <div>
                              <div><CurrencyField source="cost" spaceId={record.space?.id} />*</div>
                              <Typography variant="caption">
                                *This booking was not paid out via the normal process
                              </Typography>
                            </div>
                          );
                        }

                        return (
                          <CurrencyField source="cost" spaceId={record.space?.id} />
                        );
                      }}
                    />
                    <CurrencyField
                      source="cost"
                      label="Estimated payout"
                      spaceId={record.space?.id}
                    />
                    <RespondToBookingRequest bookedResource={record.resource} />
                  </Datagrid>
                </ArrayField>
                <br />
                <br />
              </>
            );
          }}
        />

        <FunctionField
          addLabel={false}
          render={(r) => {
            return (
              <Typography variant="h6" className={classes.inline}>
                Confirmed bookings ({r?.confirmedMembersList.length || 0})
              </Typography>
            );
          }}
        />
        <FunctionField
          addLabel={false}
          render={(record) => (
            <ArrayField source="confirmedMembersList" label="">
              <Datagrid rowStyle={memberRowStyle}>
                <UserProfileQuickPreviewButton
                  source="user"
                  sortable={false}
                  label=""
                />
                <EmailField source="bookerEmailAlias" />
                <LocalDateTimeField
                  source="startsAt"
                  timezoneSource="timezone"
                  label="Arriving"
                  showTime
                  showDate={false}
                  sortable={false}
                  options={{ hour: "2-digit", minute: "2-digit" }}
                />
                <LocalDateTimeField
                  source="endsAt"
                  timezoneSource="timezone"
                  label="Leaving"
                  showTime
                  showDate={false}
                  sortable={false}
                  options={{ hour: "2-digit", minute: "2-digit" }}
                />

                <FunctionField
                  label="Duration"
                  render={(record) => hoursToHoursAndMinutes(record.hours)}
                />
                <FunctionField
                  label="Attendees"
                  render={(bookingSlot) => (
                    <Attendees
                      bookingSlot={bookingSlot}
                      resource={record.resource}
                    />
                  )}
                />
                <FunctionField
                  sortable={false}
                  label="Estimated payout"
                  render={(record) => {
                    if (record.skipOperatorPayout) {
                      return (
                        <div>
                          <div><CurrencyField source="cost" spaceId={record.space?.id} />*</div>
                          <Typography variant="caption">
                            *This booking was not paid out via the normal process
                          </Typography>
                        </div>
                      );
                    }

                    return (
                      <CurrencyField source="cost" spaceId={record.space?.id} />
                    );
                  }}
                />
              </Datagrid>
            </ArrayField>
          )}
        />
        <br />
        <br />
        <details>
          <summary className={classes.summary}>
            <FunctionField
              addLabel={false}
              render={(r) => {
                return (
                  <Typography variant="h6" className={classes.inline}>
                    Cancelled or rejected bookings (
                    {r?.cancelledMembersList.length || 0})
                  </Typography>
                );
              }}
            />
          </summary>
          <FunctionField
            addLabel={false}
            render={(record) => (
              <ArrayField source="cancelledMembersList">
                <Datagrid rowStyle={memberRowStyle}>
                  <UserProfileQuickPreviewButton
                    source="user"
                    sortable={false}
                    label=""
                  />
                  <LocalDateTimeField
                    source="startsAt"
                    timezoneSource="timezone"
                    label="Arriving"
                    showTime
                    showDate={false}
                    sortable={false}
                    options={{ hour: "2-digit", minute: "2-digit" }}
                  />
                  <LocalDateTimeField
                    source="endsAt"
                    timezoneSource="timezone"
                    label="Leaving"
                    showTime
                    showDate={false}
                    sortable={false}
                    options={{ hour: "2-digit", minute: "2-digit" }}
                  />
                  <FunctionField
                    label="Duration"
                    render={(record) => hoursToHoursAndMinutes(record.hours)}
                  />
                  <FunctionField
                    label="Attendees"
                    render={(record) => (
                      <Attendees
                        bookingSlot={record.bookingSlot}
                        resource={record.resource}
                      />
                    )}
                  />
                  <FunctionField
                    label="Status"
                    render={(record) => {
                      let status = "Rejected by operator";
                      if (record.cancelledAt) {
                        status = `Cancelled ${
                          record.refunded
                            ? "and you were not paid"
                            : "but you were still paid"
                        }`;
                      }
                      return (
                        <span
                          style={{
                            textDecoration: "none",
                            display: "inline-block",
                          }}
                        >
                          <TextField
                            source="status"
                            record={{
                              status,
                            }}
                          />
                        </span>
                      );
                    }}
                  />
                </Datagrid>
              </ArrayField>
            )}
          />
        </details>
      </SimpleShowLayout>
    </Show>
  );
};

export default ResourceBookingsShow;

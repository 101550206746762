import { ValueType } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/value_type_pb";
import React from "react";
import {
  BooleanInput,
  DateInput,
  Loading,
  NumberInput,
  ReferenceFieldController,
  TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";
import { StandardFormLayout } from "../components/StandardFormLayout";

const validateProperty = [];

export const ResourcePropertiesInput = (props) => {
  const { values } = useFormState();

  return (
    <ReferenceFieldController
      label="Properties"
      reference="resourceTypes"
      source="type.id"
      record={values}
    >
      {({ referenceRecord }) => {
        if (!referenceRecord) {
          return <Loading />;
        }

        values.resourceType = referenceRecord;
        return (
          <StandardFormLayout>
            {referenceRecord.propertiesList.map((property) => {
              // Try to find the existing property in the record
              const existingProperty =
                ((props.record && props.record.propertiesHashmap) || {})[
                property.id
                ] || {};

              return (
                <ResourcePropertyInput
                  key={property.id}
                  property={property}
                  existingProperty={existingProperty}
                  disabled={property.id === import.meta.env.VITE_INSTANT_BOOK_RESOURCE_PROPERTY_ID}
                  {...props}
                />
              );
            })}
          </StandardFormLayout>
        );
      }}
    </ReferenceFieldController>
  );
};

const ResourcePropertyInput = ({ property, existingProperty, ...props }) => {
  const unitLabel = property.unitNamePlural
    ? ` (${property.unitNamePlural})`
    : "";
  const source = `propertiesHashmap[${property.id}]`;
  switch (property.type) {
    case ValueType.VALUE_TYPE_BOOL:
      return (
        <BooleanInput
          {...props}
          source={source}
          label={property.name}
          helperText={property.description}
          format={(val) =>
            val && val.boolValue !== undefined && val.boolValue !== null
              ? val.boolValue
              : false
          }
          options={{}}
          parse={(val) => ({
            boolValue: val,
            resourcePropertyType: property.id,
          })}
        />
      );
    case ValueType.VALUE_TYPE_INT:
      return (
        <NumberInput
          {...props}
          source={source}
          label={`${property.name}${unitLabel}`}
          helperText={property.description}
          step={1}
          validate={validateProperty}
          format={(val) =>
            val && val.intValue !== undefined && val.intValue !== null
              ? val.intValue
              : 0
          }
          parse={(val) => ({
            intValue: val ? parseInt(val, 10) : 0,
            resourcePropertyType: property.id,
          })}
        />
      );
    case ValueType.VALUE_TYPE_DOUBLE:
      return (
        <NumberInput
          {...props}
          source={source}
          label={`${property.name}${unitLabel}`}
          helperText={property.description}
          validate={validateProperty}
          format={(val) =>
            val && val.doubleValue !== undefined && val.doubleValue !== null
              ? val.doubleValue
              : 0
          }
          parse={(val) => ({
            doubleValue: val ? parseFloat(val) : 0,
            resourcePropertyType: property.id,
          })}
        />
      );
    case ValueType.VALUE_TYPE_STRING:
      return (
        <TextInput
          {...props}
          source={source}
          label={`${property.name}`}
          helperText={property.description}
          validate={validateProperty}
          format={(val) =>
            val && val.stringValue !== undefined && val.stringValue !== null
              ? val.stringValue
              : ""
          }
          parse={(val) => ({
            stringValue: val,
            resourcePropertyType: property.id,
          })}
          autoComplete="off"
        />
      );
    case ValueType.VALUE_TYPE_DATE:
      return (
        <DateInput
          {...props}
          source={source}
          label={`${property.name}`}
          helperText={property.description}
          validate={validateProperty}
          format={(val) =>
            val && val.stringValue !== undefined && val.stringValue !== null
              ? val.stringValue
              : ""
          }
          parse={(val) => ({
            stringValue: val,
            resourcePropertyType: property.id,
          })}
          autoComplete="off"
        />
      );
    default:
      break;
  }
  return <div>Unrecognised property!</div>;
};

import { GetResourceRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/resource/v1/resource_pb";
import providers from "../providers";
import { fromDatabase } from "./mappers/from-database";

export const getResource = async (params) => {
  const { Resources } = providers;
  const request = new GetResourceRequest();
  request.setId(params.id);
  const response = await Resources.getResource(request, {
    authorization: localStorage.getItem("desana-tkn"),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  const resource = response.getResult().toObject();

  return {
    data: fromDatabase(resource),
  };
};

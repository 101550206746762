import { PricingModelOption } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/pricing_model_pb";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import IconMessage from "@material-ui/icons/MessageOutlined";
import { Button, useGetMany } from "react-admin";
import { useFormState } from "react-final-form";
import DurationBasedPricing from "./DurationBasedPricing";
import StandardPricing from "./StandardPricing";

const Pricing = () => {
  const { values } = useFormState();
  const { data: resourceTypes, loaded } = useGetMany(
    "resourceTypes",
    [values.type?.id],
    { enabled: values.type?.id }
  );
  const resourceType =
    resourceTypes && resourceTypes.find((rt) => rt?.id === values.type?.id);

  return (
    <>
      {(!values?.type || !resourceType) && (
        <Typography variant="h6">
          Pricing cannot be set until resource type for resource has been chosen
        </Typography>
      )}
      {values?.type &&
        loaded &&
        resourceType.pricingModel?.model !==
          PricingModelOption.PRICING_MODEL_OPTION_DURATION_BASED &&
        resourceType.pricingModel?.model !==
          PricingModelOption.PRICING_MODEL_OPTION_STANDARD && (
          <Typography variant="h6">
            Resource Type pricing model must be set before Pricing on a resource
            can be set
          </Typography>
        )}
      {resourceType?.pricingModel?.model ===
        PricingModelOption.PRICING_MODEL_OPTION_DURATION_BASED && (
        <DurationBasedPricing />
      )}
      {resourceType?.pricingModel?.model ===
        PricingModelOption.PRICING_MODEL_OPTION_STANDARD && (
        <StandardPricing pricingModel={resourceType?.pricingModel} />
      )}
      <ChangePricing />
    </>
  );
};

const ChangePricing = () => {
  const { values } = useFormState();

  if (!values?.name || !values?.space?.id) {
    return null;
  }

  return (
    <Box component="span" m={2}>
      <Card mt={2}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Want to change your pricing?
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you'd like to change your pricing, please get in touch with us
            below.
          </Typography>
          <Button
            label="Message us"
            alignIcon="right"
            color="primary"
            variant="outlined"
            onClick={() =>
              window.Intercom(
                "showNewMessage",
                `I'd like to change the pricing for "${values?.name}" (${values?.id}) at "${values?.space?.name}" (${values?.space?.id}) to: `
              )
            }
            style={{ marginTop: "20px" }}
          >
            <IconMessage />
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Pricing;

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Notification,
  useAuthProvider,
  useLogin,
  useNotify,
  useTranslate,
} from "react-admin";
import { Field, Form } from "react-final-form";
import LogoIcon from "../components/LogoIcon";
import { lightTheme } from "../themes";
import { sendMagicLink } from "../ra-auth-desana/authProvider";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  card: {
    marginTop: "6em",
    justifyContent: "center",
    maxWidth: "32rem",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 6px 0px #ccc",
  },
  successIcon: {
    textAlign: "center",
    color: "#00c853",
    width: "40px",
    height: "40px",
  },
  centered: {
    textAlign: "center",
  },
  message: {
    textAlign: "center",
    fontWeight: 600,
    color: "#00c853",
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
    justifyContent: "center",
  },
  button: {
    borderRadius: 48 / 2,
    padding: "0 16px",
    minHeight: "auto",
    minWidth: 48,
    height: 48,
  },
}));

const renderInput = ({
  // @ts-ignore
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const renderTermsInput = ({
  input: { value, ...inputProps }, // eslint-disable-line react/prop-types
  classes,
}) => {
  return (
    <FormControlLabel
      control={<Checkbox value={value ? "true" : "false"} {...inputProps} />}
      label={
        <>
          I agree to the Desana{" "}
          <a
            href="https://help.desana.io/en/collections/3292762-terms-and-conditions-and-policies"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              color="secondary"
              component="span"
              className={classes.textButtons}
            >
              Terms and Conditions and Policies
            </Typography>
          </a>{" "}
          (Including our code of conduct and cookie policy)
        </>
      }
    />
  );
};

const initialState = {
  loading: false,
  magicLinkSent: false,
  data: {
    tsAndCsAccepted: false,
    email: "",
    token: "",
  },
};

const getUrlHashParams = (location) => {
  if (!location || !location.hash) {
    return {};
  }

  const urlParams = new URLSearchParams(location.hash.substring(1));
  const email = urlParams.get("email");
  const token = urlParams.get("token");

  return {
    email,
    token,
    tsAndCsAccepted: !!email && !!token,
  };
};

const Login = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [data, setData] = useState<{
    email?: string;
    token?: string;
    tsAndCsAccepted?: boolean;
  }>(getUrlHashParams(location));

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const verify = useLogin();

  const handleSubmit = async (auth) => {
    setLoading(true);

    if (data.token) {
      await new Promise((res) => setTimeout(res, 1000));
      try {
        await verify(
          { email: auth.email, token: auth.token },
          location.state ? location.state.nextPathname : "/"
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setData({ ...data, token: "", tsAndCsAccepted: false });
        window.history.replaceState(
          "",
          "",
          `#email=${encodeURIComponent(data.email)}`
        );
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          { type: "warning" }
        );
      }
    } else {
      try {
        await sendMagicLink(auth);
        setMagicLinkSent(true);
      } catch (error) {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          { type: "warning" }
        );
      }
    }
  };

  const resetFormAndState = () => {
    setLoading(false);
    setMagicLinkSent(false);
    setData({ email: "", token: "", tsAndCsAccepted: false });
  };

  const validate = (values) => {
    const errors: { email?: string; tsAndCsAccepted?: string } = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    if (!values.tsAndCsAccepted)
      errors.tsAndCsAccepted = translate("ra.validation.required");
    return errors;
  };

  // Only send under the right circumstances
  if (
    data &&
    data.email &&
    data.token &&
    data.tsAndCsAccepted &&
    !loading &&
    !magicLinkSent
  ) {
    handleSubmit(data);
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={data}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, invalid }) => (
        <form onSubmit={handleSubmit} noValidate className="notranslate">
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LogoIcon />
                </Avatar>
              </div>
              <Typography variant="h5" className={classes.centered}>
                Sign in to continue.
              </Typography>

              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="email"
                    type="email"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("desana.auth.email")}
                    disabled={loading || magicLinkSent}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    id="tsAndCsAccepted"
                    name="tsAndCsAccepted"
                    disabled={loading || magicLinkSent}
                    classes={classes}
                    type={"checkbox"}
                    // @ts-ignore
                    component={renderTermsInput}
                  />
                </div>
                {magicLinkSent && (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    // @ts-ignore
                    className={classes.successGrid}
                  >
                    <Grid item>
                      <CheckCircle className={classes.successIcon} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        className={classes.message}
                      >
                        Please check your email!
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
              <CardActions className={classes.actions}>
                {!magicLinkSent && (
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading || submitting || pristine || invalid}
                    className={classes.button}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("desana.auth.send")}
                  </Button>
                )}
                {magicLinkSent && (
                  <Button
                    onClick={resetFormAndState}
                    variant="text"
                    size="small"
                    color="secondary"
                  >
                    Not working? Try again.
                  </Button>
                )}
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const LoginWithTheme = (props) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;

const PRIVATE_OFFICES_ENABLED =
  import.meta.env.VITE_PRIVATE_OFFICES_ENABLED === "true";

const RESOURCE_TYPES = {
  MEETING_ROOM: import.meta.env.VITE_RESOURCE_TYPE_MEETING_ROOM,
  PRIVATE_OFFICE: import.meta.env.VITE_RESOURCE_TYPE_PRIVATE_OFFICE,
  EVENT_SPACE: import.meta.env.VITE_RESOURCE_TYPE_EVENT_SPACE,
};

export { PRIVATE_OFFICES_ENABLED, RESOURCE_TYPES };

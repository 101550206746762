import { GET_LIST, GET_ONE } from "react-admin";
import { OrganisationMini } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/organisation_pb";
import {
  Paging,
  Sorter,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import providers from "./providers";
import * as storage from "../utils/storage";
import { getSpacesForSpaceRole } from "../utils/auth";
import { SpaceAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import {
  GetResourceBookingRequest,
  ListResourceBookingsRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/booking/v1/resource_booking_pb";

const resourceBookings = (type, params, resourceType) => {
  switch (type) {
    case GET_LIST:
      return list(params, resourceType);
    case GET_ONE:
      return get(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for bookings ${type}`
      );
  }
};

export default resourceBookings;

const list = async (params, resourceType) => {
  const { Bookings } = providers;
  const request = new ListResourceBookingsRequest();
  const currentOrganisation = storage.get("currentOrganisation");
  const owner = new OrganisationMini();
  owner.setId(currentOrganisation.organisation.id);
  request.setOwner(owner);
  const currentOrg = storage.get("currentOrganisation");
  const viewBookingPermissions = getSpacesForSpaceRole(currentOrg, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
  ]);

  // If we don't have permission to edit bookings
  if (viewBookingPermissions === false) {
    throw new Error("Permission denied");
  }

  // TODO: Add ability to filter resources by spaces
  // if (viewBookingPermissions !== true) {
  //   request.setSpacesList(viewBookingPermissions);
  // }

  if (params.pagination) {
    const { page, perPage } = params.pagination;
    request.setPaging(
      new Paging().setFrom((page - 1) * perPage).setSize(perPage)
    );
  }

  if (params.filter) {
    if (params.filter.timePeriod) {
      request.setTimePeriod(params.filter.timePeriod);
    }

    if (params.filter.statusList) {
      request.setStatusList([params.filter.statusList]);
    }
  }

  if (resourceType) {
    request.setResourceTypesList([resourceType]);
  }

  const { field, order } = params.sort;
  const sorter = new Sorter();
  sorter.setKey(field.replace(/([A-Z])/g, "_$1").toLowerCase());
  sorter.setOrder(order.toLowerCase());
  request.setSortList([sorter]);

  const response = await Bookings.listResourceBookings(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  return {
    total: response.getCount(),
    data: response
      .getResultsList()
      .map((result) => result.toObject())
      .map((b) => transformer(b)),
  };
};

const get = async (params) => {
  const { Bookings } = providers;
  const request = new GetResourceBookingRequest();
  request.setId(params.id.split(",")[0]);
  const response = await Bookings.getResourceBooking(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  const b = response.getResult().toObject();
  return {
    data: transformer(b),
  };
};

const getTotalCost = (booking) =>
  booking.membersList
    .reduce((acc, member) => acc + getCost(member), 0);

const transformer = (booking) => ({
  ...booking,
  totalCost: getTotalCost(booking),
  membersList: booking.membersList.map((m) => memberTransformer(m, booking)),
  ...booking.membersList.reduce(
    (acc, m) => {
      if (
        !!m.cancelledAt ||
        (m.operatorRejectionReason && m.operatorRejectionReason.operatorReason)
      ) {
        acc.cancelledMembersCount += 1;
        acc.cancelledMembersList.push(memberTransformer(m, booking));
        return acc;
      }
      if (m.pending && m.managerApproved === true) {
        acc.pendingCount += 1;
        acc.pendingMembersList.push(
          pendingMemberTransformer(
            {
              ...m,
              id: `${booking.id},${m.id}`,
              bookingId: booking.id,
              bookingSlotId: m.id,
            },
            booking
          )
        );
      } else if (m.managerApproved === true) {
        acc.confirmedMembersList.push(memberTransformer(m, booking));
      }

      return acc;
    },
    {
      cancelledMembersList: [],
      confirmedMembersList: [],
      pendingMembersList: [],
      cancelledMembersCount: 0,
      pendingCount: 0,
    }
  ),
});

const memberTransformer = (m, booking) =>
  Object.assign({}, m, {
    cost: getCost(m),
    timezone: booking?.resource?.timezone || booking.space?.timezone,
    space: booking.space,
    capacityRange: booking.resourceCapacityRange
  });

const pendingMemberTransformer = (m, booking) =>
  Object.assign({}, m, memberTransformer(m, booking), {
    cost: m.cost || m.hours * booking.costPerHour,
  });

const getCost = (
  { refunded, cost, operatorApproved },
) => {
  if (refunded) {
    return 0;
  }

  if (!operatorApproved) {
    return 0;
  }

  return cost;
};

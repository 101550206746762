import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useQueryWithStore } from "react-admin";
import { optimiseImage } from "../common/image-proxy";

const organisationIconStyle = {
  avatar: {
    height: 25,
    width: 25,
  },
};

const OrganisationIcon = ({ classes }) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getOne",
    resource: "switch-org",
    payload: { id: "current" },
  });
  if (!loaded) {
    return <AccountCircle />;
  }
  if (error) {
    return <AccountCircle />;
  }

  if (!data || !data.org) {
    return <AccountCircle />;
  }

  const { org } = data;
  if (!org.profileImage) {
    return <AccountCircle />;
  }
  let profileImage = null;
  if (typeof org.profileImage.image === "string") {
    profileImage = org.profileImage.image;
  } else {
    profileImage = org.profileImage;
  }
  return (
    <Avatar
      className={classes.avatar}
      src={optimiseImage(profileImage, { size: 60 })}
    />
  );
};

export default withStyles(organisationIconStyle)(OrganisationIcon);

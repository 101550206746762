declare global {
  var Intercom;
}

import {
  LoginRequest,
  LoginSource,
  LogoutRequest,
  VerifyRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/authentication/v1/authentication_pb";
import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  GET_ONE,
} from "react-admin";
import me from "../ra-data-desana/me";
import providers from "../ra-data-desana/providers";
import * as storage from "../utils/storage";

const auth = async (type, params) => {
  if (type === AUTH_LOGIN) {
    return verify(params);
  }

  if (type === AUTH_ERROR) {
    if (params && params.code === 16) {
      return Promise.reject(params);
    }
  }

  if (type === AUTH_LOGOUT) {
    if (storage.get("desana-tkn", false)) {
      return logout();
    }
  }

  if (type === AUTH_CHECK) {
    let currentUser = storage.get("user");
    if (!currentUser) {
      await me(GET_ONE);
      currentUser = storage.get("user");
    }
    if (
      !currentUser.name ||
      !currentUser.name.first ||
      !currentUser.name.last
    ) {
      if (window.location.pathname !== "/profile") {
        window.location.replace("/profile");
      }
      return Promise.resolve();
    }

    const authFetchedAt = storage.get("authFetchedAt", false);
    if (authFetchedAt) {
      try {
        const lastFetched = +new Date(authFetchedAt);
        // If the user was last fetched over two minutes ago, refresh
        if (+new Date() - lastFetched > 120000) {
          me(GET_ONE);
        }
      } catch (err) {
        console.error("Error parsing authFetchedAt date");
      }
    } else {
      me(GET_ONE);
    }
  }

  if (type === AUTH_GET_PERMISSIONS) {
    if (!storage.get("desana-tkn", false)) {
      return Promise.reject();
    }

    const currentOrganisation = storage.get("currentOrganisation");
    if (currentOrganisation) {
      return Promise.resolve(currentOrganisation);
    }

    const currentUser = storage.get("user");
    if (
      currentUser &&
      currentUser.organisationsList &&
      currentUser.organisationsList[0]
    ) {
      return Promise.resolve(currentUser.organisationsList[0]);
    }
    return Promise.reject();
  }
  return Promise.resolve();
};

export const sendMagicLink = async (params) => {
  const authService = providers.Authentication;
  const request = new LoginRequest();
  request.setEmail(params.email);
  request.setSource(LoginSource.LOGIN_SOURCE_HOST_DASHBOARD);
  const response = await authService.login(request);

  return {
    data: {
      id: params.email,
      ...response,
    },
  };
};

export const verify = async ({
  email,
  token,
}: {
  email: string;
  token: string;
}) => {
  const { Authentication } = providers;
  const request = new VerifyRequest();
  request.setClientId(navigator.userAgent);
  request.setEmail(email);
  request.setToken(token);

  const response = await Authentication.verify(request);
  storage.set("desana-tkn", response.getToken(), false);
  document.cookie = `${
    import.meta.env.VITE_AUTH_COOKIE_KEY
  }=${response.getToken()}; path=/; secure; domain=desana.io; samesite=lax;`;
  await me(GET_ONE);
  return response;
};

export const logout = async () => {
  const { Authentication } = providers;
  const token = storage.get("desana-tkn", false);
  localStorage.clear();
  Intercom("shutdown");
  const request = new LogoutRequest();
  await Authentication.logout(request, {
    authorization: token,
  });
};

export default auth;

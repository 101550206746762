import { GET_LIST, GET_ONE } from "react-admin";
import {
  Paging,
  Sorter,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import providers from "./providers";
import * as storage from "../utils/storage";
import { getSpacesForSpaceRole } from "../utils/auth";
import { SpaceAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import {
  ListReportsRequest,
  GetReportRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/report/v1/report_pb";

const reports = (type, params) => {
  switch (type) {
    case GET_LIST:
      return list(params);
    case GET_ONE:
      return get(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for reports ${type}`
      );
  }
};

export default reports;

const list = async (params) => {
  const { Reports } = providers;
  const request = new ListReportsRequest();
  const currentOrganisation = storage.get("currentOrganisation");
  const viewPayoutPermissions = getSpacesForSpaceRole(currentOrganisation, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_PAYOUTS,
  ]);

  // If we don't have permission to view payouts
  if (viewPayoutPermissions === false) {
    throw new Error("Permission denied");
  }

  request.setOrganisationId(currentOrganisation.organisation.id);

  if (params.pagination) {
    const { page, perPage } = params.pagination;
    request.setPaging(
      new Paging().setFrom((page - 1) * perPage).setSize(perPage)
    );
  }

  const { field, order } = params.sort;
  const sorter = new Sorter();
  sorter.setKey(field.replace(/([A-Z])/g, "_$1").toLowerCase());
  sorter.setOrder(order.toLowerCase());

  const response = await Reports.listReports(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  return {
    total: response.getCount(),
    data: response.getResultsList().map((result) => mapper(result.toObject())),
  };
};

const get = async (params) => {
  const { Reports } = providers;
  const request = new GetReportRequest();
  request.setId(params.id);
  const response = await Reports.getReport(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  return { data: mapper(response.getResult().toObject()) };
};

const mapper = (report) => ({
  ...report,
  attachmentsList: report.attachmentsList.map((attachment) => ({
    ...attachment,
    location: `${import.meta.env.VITE_REPORTING_URL}/operator/reports/${
      report.id
    }/files/${encodeURIComponent(attachment.location)}`,
  })),
});

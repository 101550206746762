import { ResourceBookingOperatorRejectionReason } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/resource_booking_pb";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import IconCheck from "@material-ui/icons/CheckCircle";
import IconClear from "@material-ui/icons/Clear";
import { useEffect, useState } from "react";
import {
  Button,
  FormWithRedirect,
  Loading,
  SaveButton,
  SelectInput,
  TextInput,
  required,
  useGetOne,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";

const styles = (theme) => ({
  container: {
    display: "flex",
  },
  button: {
    flex: "1 1 auto",
    margin: `${theme.spacing(1)}px`,
  },
  error: {
    color: theme.palette.error.dark,
  },
});

const validateRejectionReasonText = [required()];
const validateRejectionReason = [required()];
const validateRejectionForm = (values) =>
  values.rejectionReason ===
    ResourceBookingOperatorRejectionReason.RESOURCE_BOOKING_OPERATOR_REJECTION_REASON_OTHER &&
    !values.desanaMessage
    ? {
      desanaMessage: {
        message:
          "Please tell us a little about why you're rejecting this request.",
      },
    }
    : {};

const getIsAttendeeListRequired = (space, resource) => {
  if (!space?.resourceTypeOptionsList || !resource) {
    return false;
  }
  return space.resourceTypeOptionsList?.find(
    ({ resourceTypeId }) => resourceTypeId === resource.resourceType.id
  )?.attendeeListRequired;
};

const RespondToBookingRequest = withStyles(styles)(
  ({ classes, record, onSuccess, bookedResource }) => {
    const { data: space, isLoading } = useGetOne("spaces", record.space?.id, {
      enabled: !!record.space?.id,
    });

    const [showAcceptConfirmation, setShowAcceptDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    useEffect(() => {
      return () => {
        setShowAcceptDialog(false);
        setShowRejectDialog(false);
      };
    }, []);

    const notify = useNotify();
    const refresh = useRefresh();
    const onAcceptedSuccess = () => {
      setShowAcceptDialog(false);
      onSuccess && onSuccess(record.id);
      notify(`Successfully responded to booking request!`, "success");
      refresh();
    };

    const onRejectedSuccess = () => {
      setShowRejectDialog(false);
      onSuccess && onSuccess(record.id);
      notify(`Successfully responded to booking request!`, "success");
      refresh();
    };

    const onFailure = (error) =>
      notify(`Respond to booking request error: ${error.message}`, "error");

    const [respond, { loading }] = useMutation();

    if (isLoading) {
      <div className={classes.container}>
        <Loading />
      </div>;
    }

    const isAttendeeListRequired = getIsAttendeeListRequired(
      space,
      bookedResource
    );

    const handleAccept = () => {
      respond(
        {
          type: "update",
          resource: "pendingResourceBookings",
          payload: {
            id: record.id,
            data: {
              approved: true,
              bookingId: record.bookingId,
              bookingSlotId: record.bookingSlotId,
            },
          },
        },
        {
          onSuccess: onAcceptedSuccess,
          onFailure,
        }
      );
    };

    const handleReject = (values) => {
      respond(
        {
          type: "update",
          resource: "pendingResourceBookings",
          payload: {
            id: record.id,
            data: {
              approved: false,
              bookingId: record.bookingId,
              bookingSlotId: record.bookingSlotId,
              ...values,
            },
          },
        },
        {
          onSuccess: onRejectedSuccess,
          onFailure,
        }
      );
    };

    return (
      <div className={classes.container}>
        <Button
          label="Reject"
          onClick={() => setShowRejectDialog(true)}
          className={classes.button}
          color="error"
          variant="outlined"
        >
          <IconClear />
        </Button>
        <Button
          label="Accept"
          className={classes.button}
          variant="contained"
          onClick={() => setShowAcceptDialog(true)}
        >
          <IconCheck />
        </Button>
        <Dialog
          fullWidth
          open={showAcceptConfirmation}
          onClose={() => setShowAcceptDialog(false)}
          aria-label="Confirm booking"
        >
          <DialogTitle>Accept booking?</DialogTitle>
          {isLoading && (
            <DialogContent>
              <DialogContentText>
                <Loading />
              </DialogContentText>
            </DialogContent>
          )}
          {!isLoading && (
            <>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to accept this booking request?
                  <br />
                  <br />
                  When you confirm this booking:
                  <ul>
                    <li>
                      {record.user?.name?.first || "The booker"} will be
                      notified and the booking will be finalised.
                    </li>
                    {isAttendeeListRequired && (
                      <>
                        <li>
                          {record.user?.name?.first || "The booker"} will be
                          reminded to provide an accurate attendee list 24 hours
                          before the booking
                        </li>
                        <li>
                          The attendee list will be available to you in the
                          dashboard
                        </li>
                      </>
                    )}
                  </ul>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={() => setShowAcceptDialog(false)}
                  disabled={loading}
                ></Button>
                <Button
                  label="Accept"
                  onClick={handleAccept}
                  disabled={loading}
                  variant="contained"
                >
                  <IconCheck />
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        <Dialog
          fullWidth
          open={showRejectDialog}
          onClose={() => setShowRejectDialog(false)}
          aria-label="Reject booking request"
        >
          <DialogTitle>Why are you rejecting this booking request?</DialogTitle>
          <FormWithRedirect
            resource="posts"
            save={handleReject}
            validate={validateRejectionForm}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <DialogContent>
                  <Typography variant="h6">For Desana's use</Typography>
                  <DialogContentText>
                    This reason will not be shared with{" "}
                    {record.user?.name?.first}.
                  </DialogContentText>

                  <SelectInput
                    fullWidth
                    source="rejectionReason"
                    helperText={`Why are you rejecting this request? This reason will NOT be shared with ${record.user?.name?.first}.`}
                    validate={validateRejectionReason}
                    choices={[
                      {
                        id: ResourceBookingOperatorRejectionReason.RESOURCE_BOOKING_OPERATOR_REJECTION_REASON_ALREADY_BOOKED,
                        name: "We're already booked for this date and time.",
                      },
                      {
                        id: ResourceBookingOperatorRejectionReason.RESOURCE_BOOKING_OPERATOR_REJECTION_REASON_MAINTENANCE,
                        name: "We're closed at that date and time.",
                      },
                      {
                        id: ResourceBookingOperatorRejectionReason.RESOURCE_BOOKING_OPERATOR_REJECTION_REASON_OTHER,
                        name: "We have another reason why we're rejecting this request.",
                      },
                    ]}
                  />
                  <TextInput
                    fullWidth
                    multiline
                    source="desanaMessage"
                    label="Provide more details"
                    validate={validateRejectionReasonText}
                  />
                  <Typography
                    style={{ fontWeight: "bold", color: "red" }}
                    gutterBottom
                  >
                    Are alternative times available?<br />Is there an alternative room available?<br />If no alternatives are available, please tell us.
                  </Typography>
                  <Divider style={{ marginBottom: "1em" }} />
                  <Typography variant="h6">For the booker</Typography>
                  <TextInput
                    fullWidth
                    multiline
                    source="userMessage"
                    label={`Tell ${record.user?.name?.first} why you're rejecting the booking. Please don't reference prices!`}
                    helperText=""
                  />
                  <Typography
                    style={{ fontWeight: "bold", color: "red" }}
                    gutterBottom
                  >
                    If the requested room is already booked, please suggest an alternative if you have one.
                  </Typography>
                  <Typography>
                    Desana users do not see prices. It is their employers who deal with prices, so mentioning this here will not be useful.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={() => setShowRejectDialog(false)}
                    disabled={loading}
                  />
                  <SaveButton
                    label="Reject"
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                    variant="contained"
                  />
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      </div>
    );
  }
);

export default RespondToBookingRequest;

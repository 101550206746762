import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";
import { Empty } from "../components/Empty";

const ReportsList = (props) => (
  <List
    {...props}
    sort={{ field: "sortWeight", order: "ASC" }}
    title="Payout Reports"
    empty={<Empty resourceLabel="reports" />}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid rowClick="show">
      <TextField label="Report" source="name" sortable={false} />
      <DateField
        label="Month"
        source="dateRange.lo"
        options={{ month: "long", year: "numeric" }}
        sortable={false}
      />
    </Datagrid>
  </List>
);

export default ReportsList;

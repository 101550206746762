import { OrganisationAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import { getOrganisationDefaultRolesMapping, getSpacesDefaultRolesMapping } from "./role-mappings";

const extractOrganisationRoles = (roleTypeMapping, isOwner) => {
  if (isOwner) {
    return [OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER];
  }

  const defaultRolesMapping = getOrganisationDefaultRolesMapping();
  const cleanRoleTypeMapping = roleTypeMapping.map(
    ({ currentRole }, index) => ({
      ...defaultRolesMapping[index],
      currentRole,
    })
  );


  return extractRoles(cleanRoleTypeMapping);
};

const extractSpacesRoles = (roleTypeMapping) => {
  /**
   * Due to the way the way the we add spaces and setup the roletype mapping in the client,
   * if we add a new space, some of the role permissions and hirerarchies will not be set,
   * so we need to merge the updated role mapping with the default mapping to get the values we
   * need to extract the roles
   */
  const defaultRolesMapping = getSpacesDefaultRolesMapping();
  const cleanRoleTypeMapping = roleTypeMapping.map(
    ({ currentRole }, index) => ({
      ...defaultRolesMapping[index],
      currentRole,
    })
  );

  return extractRoles(cleanRoleTypeMapping);
};

export { extractOrganisationRoles, extractSpacesRoles };

const extractRoles = (roleTypeMapping) =>
  roleTypeMapping.flatMap(getRolesFromRoleTypeMapping);

const getRolesFromRoleTypeMapping = (roleMapping) => {
  const { currentRole, roleHierarchy } = roleMapping;

  // Filter unset roles
  if (currentRole < 1) {
    return [];
  }

  const currentRoleIndex = getCurrentRoleIndex(roleHierarchy, currentRole);

  if (currentRoleIndex === 0) {
    return currentRole;
  }

  const childRoles = getChildRoles(roleHierarchy, currentRoleIndex);

  return [currentRole, ...childRoles];
};

const getCurrentRoleIndex = (roleHierarchy, currentRole) =>
  roleHierarchy.findIndex(({ role }) => role === currentRole);

const getChildRoles = (roleHierarchy, currentRoleIndex) =>
  roleHierarchy.slice(0, currentRoleIndex).map(({ role }) => role);

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import React from "react";
import { GET_ONE, Query } from "react-admin";
import { Redirect } from "react-router";
import { hasPermission } from "../utils/auth";
import { PendingResourceBookings } from "./PendingResourceBookings";
import SpaceViewsChart from "./SpaceViewsChart";
import TodaysOverviewDetails from "./TodaysOverviewDetails";
import YearlyPayoutsChart from "./YearlyPayoutsChart";
import YearlyPayoutsDetails from "./YearlyPayoutsDetails";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 320,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperGrid: {
    height: 320,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "grid",
    alignContent: "baseline",
  },
});

class Dashboard extends React.Component {
  render() {
    const { classes, permissions } = this.props;
    if (!permissions) {
      return null;
    }

    const canViewBookings = hasPermission(
      permissions,
      OrganisationAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
      SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS
    );

    const canManageBookings = hasPermission(
      permissions,
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_BOOKINGS,
      SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS
    );

    const canViewPayouts = hasPermission(
      permissions,
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYOUTS
    );

    const canViewAnalytics = hasPermission(
      permissions,
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_ANALYTICS
    );

    if (!canViewBookings && !canViewPayouts && !canViewAnalytics) {
      return <Redirect to="/spaces" />;
    }

    return (
      <div className={classes.root}>
        <Query
          type={GET_ONE}
          resource="dashboardAnalytics"
          payload={{ id: "dashboardAnalytics" }}
        >
          {({ data, loading, error }) => {
            if (error) {
              return <p>ERROR, {error.message}</p>;
            }
            if (!data) {
              data = {};
            }
            return (
              <Grid container spacing={2}>
                {canViewBookings && (
                  <Grid item xs={12} md={12} xl={6}>
                    <Card className={classes.paperGrid} variant="outlined">
                      <Typography variant="h6" gutterBottom>
                        Today&apos;s Overview
                      </Typography>
                      <TodaysOverviewDetails
                        loading={loading}
                        data={data.todaysOverview && data.todaysOverview.result}
                        error={data.todaysOverview && data.todaysOverview.error}
                      />
                    </Card>
                  </Grid>
                )}
                {canManageBookings && <PendingResourceBookings />}
                {canViewPayouts && (
                  <Grid item xs={12} lg={6}>
                    <Card className={classes.paper} variant="outlined">
                      <Typography
                        variant="h6"
                        gutterBottom
                      >{`${new Date().toLocaleDateString(navigator.language, {
                        year: "numeric",
                      })} Earnings`}</Typography>
                      <YearlyPayoutsChart
                        loading={loading}
                        data={data.yearlyPayouts && data.yearlyPayouts.result}
                        error={data.yearlyPayouts && data.yearlyPayouts.error}
                      />
                    </Card>
                  </Grid>
                )}
                {canViewPayouts && (
                  <Grid item xs={12} lg={6}>
                    <Card className={classes.paper} variant="outlined">
                      <Typography
                        variant="h6"
                        gutterBottom
                      >{`${new Date().toLocaleDateString(navigator.language, {
                        year: "numeric",
                      })} Details`}</Typography>
                      <YearlyPayoutsDetails
                        loading={loading}
                        data={data.yearlyPayouts && data.yearlyPayouts.result}
                        error={data.yearlyPayouts && data.yearlyPayouts.error}
                      />
                    </Card>
                  </Grid>
                )}
                {canViewAnalytics && (
                  <Grid item xs={12} lg={6}>
                    <Card className={classes.paper} variant="outlined">
                      <Typography variant="h6" gutterBottom>
                        30 Day Views of Your Spaces
                      </Typography>
                      <SpaceViewsChart
                        loading={loading}
                        data={data.spaceViews && data.spaceViews.result}
                        error={data.spaceViews && data.spaceViews.error}
                      />
                    </Card>
                  </Grid>
                )}
              </Grid>
            );
          }}
        </Query>
      </div>
    );
  }
}
export default withStyles(styles)(Dashboard);

import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Edit, SimpleForm, TextInput, required } from "react-admin";
import SaveOnlyToolbar from "../components/SaveOnlyToolbar";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Which information will workers see?</Typography>
      <br />
      <Typography variant="body2">
        Workers will <b>not</b> be able to see these details, but we need them
        so we know who you are.
      </Typography>
      <br />
      <Typography variant="body2">
        Please specify your name as a minimum requirement.
      </Typography>
    </div>
  );
};

const CompleteProfileWarning = (props) => {
  if (props.record?.name?.first && props.record?.name?.last) {
    return null;
  }

  return (
    // @ts-ignore
    <Alert fullWidth severity="warning" style={{ marginBottom: "1em" }}>
      <Typography variant="h6">Complete your profile to continue</Typography>
      <Typography variant="body2">
        Please provide us with your basic information in order to proceed to
        your dashboard.
      </Typography>
    </Alert>
  );
};

const MeEdit = () => (
  <Edit
    id="profile"
    basePath="/profile"
    resource="me"
    undoable={false}
    title="Your Profile"
    aside={<Aside />}
  >
    <SimpleForm toolbar={<SaveOnlyToolbar />} redirect="/">
      <CompleteProfileWarning />
      <TextInput source="name.first" label="First Name" validate={required()} />
      <TextInput source="name.last" label="Last Name" validate={required()} />
      <TextInput source="email" disabled />
    </SimpleForm>
  </Edit>
);

export default MeEdit;

import React from "react";
import { FileField, Show, SimpleShowLayout, TextField } from "react-admin";

const ReportTitle = ({ record }) => {
  return record.name;
};

const ReportShow = (props) => {
  return (
    <Show {...props} title={<ReportTitle />}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
        <TextField label="Start Date" source="dateRange.lo" />
        <TextField label="End Date" source="dateRange.hi" />
        <TextField source="reference" />
        <FileField
          source="attachmentsList"
          src="location"
          title="name"
          download
          target="_blank"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default ReportShow;

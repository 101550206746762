import { GET_LIST, GET_MANY } from "react-admin";
import {
  ListResourceTypesRequest,
  ListSpaceAmenitiesRequest,
  ListSpaceRulesRequest,
  ListSpaceTypesRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/configuration/v1/configuration_pb";
import providers from "./providers";
import { get } from "../utils/storage";

const configuration = (type, params, resource) => {
  switch (type) {
    case GET_LIST:
    case GET_MANY:
      return list(type, params, resource);
    // case GET_ONE:
    //   // url = `${apiUrl}/${resource}/${params.id}`;
    //   const request = new IdRequest();
    //   request.setId(params.id);
    //   // const { field, order } = params.sort;
    //   return new Promise((resolve, reject) => {
    //     service.hostGet(
    //       request,
    //       {
    //         authorization: localStorage.getItem("desana-tkn")
    //       },
    //       (err, response) => {
    //         if (err) {
    //           return reject(err);
    //         }
    //         if (!response) {
    //           return reject(new Error("No response returned from server"));
    //         }
    //         resolve({ data: response.toObject() });
    //       }
    //     );
    //   });
    default:
      throw new Error(
        `Unsupported Data Provider request type for configuration ${resource} ${type}`
      );
  }
};

export default configuration;

const list = async (type, params, resource) => {
  const service = providers.Configuration;
  let request;
  let method;
  switch (resource) {
    case "spaceAmenities":
      request = new ListSpaceAmenitiesRequest();
      method = "listSpaceAmenities";
      break;
    case "spaceRules":
      request = new ListSpaceRulesRequest();
      method = "listSpaceRules";
      break;
    case "spaceTypes":
      request = new ListSpaceTypesRequest();
      method = "listSpaceTypes";
      break;
    case "resourceTypes":
      request = new ListResourceTypesRequest();
      method = "listResourceTypes";
      break;
    default:
      throw new Error(
        `Unsupported Data Provider request type for configuration ${resource} ${type}`
      );
  }

  const response = await service[method](request, {
    authorization: get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  let data = response.getResultsList().map((result) => result.toObject());
  if (type === GET_MANY) {
    data = data.filter(({ id }) => params.ids.includes(id));
  }

  return {
    total: data.length,
    data,
  };
};

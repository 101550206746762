import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Loading } from "react-admin";

const styles = {
  tooltipLabel: {
    fontFamily:
      "Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif",
  },
};

const YearlyPayoutsChart = ({ loading, data, error }) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR, {error.message}</p>;
  }
  return (
    <ResponsiveContainer>
      <BarChart
        data={data.monthsList}
        nameKey="name"
        margin={{ top: 0, right: 0, bottom: 40, left: 10 }}
        fontFamily="Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif"
      >
        <Tooltip
          formatter={(value, name) => [
            Number(value).toLocaleString(),
            name === "payoutUsed" ? "Earnings" : "Cancellations",
          ]}
          separator=": "
          labelFormatter={(value) => {
            const nameParts = [];
            const dateParts = value.split("|");
            nameParts.push(
              new Date(dateParts[0]).toLocaleDateString(navigator.language, {
                year: "numeric",
                month: "long",
              })
            );
            if (dateParts[1] === "true") {
              nameParts.push("(Estimated)");
            }

            return nameParts.join(" ");
          }}
          cursor={{ fill: "#eee" }}
          labelStyle={styles.tooltipLabel}
        />
        <CartesianGrid stroke="#ccc" vertical={false} />
        <Bar dataKey="payoutUsed" stackId="a" label="Earnings" fill="#6092C0" />
        <Bar
          dataKey="payoutCancelled"
          stackId="a"
          label="Cancelled bookings"
          fill="#79AAD9"
        />
        <XAxis
          dataKey="name"
          tickFormatter={(value) =>
            new Date(value.split("|")[0]).toLocaleDateString(
              navigator.language,
              {
                month: "short",
              }
            )
          }
          tickLine={false}
          minTickGap={10}
          axisLine={{ strokeWidth: 2 }}
          fontSize={13}
        />
        <YAxis
          label={{ value: "Earnings (in local currency)", angle: 90 }}
          axisLine={false}
          orientation="right"
          tickLine={false}
          fontSize={13}
          width={70}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default YearlyPayoutsChart;

import React from "react";
import { makeStyles } from "@material-ui/core";
import get from "lodash/get";
import { optimiseImage } from "../common/image-proxy";

const imageSize = {
  xs: 80,
  s: 160,
  m: 240,
  l: 360,
};

const useStyles = makeStyles({
  squareImage: ({ size }) => ({
    objectFit: "cover",
    width: imageSize[size],
    height: imageSize[size],
  }),
  fallback: ({ size }) => ({
    border: "1px solid #ddd",
    width: imageSize[size],
    height: imageSize[size],
  }),
});

const SquareImageField = ({ source, record, size = "xs" }) => {
  const img = get(record, source);
  const classes = useStyles({ size });

  if (!img) {
    return <div className={classes.fallback} />;
  }

  return (
    <img
      alt=""
      className={classes.squareImage}
      src={optimiseImage(img, {
        size: imageSize.s,
      })}
      loading="lazy"
    />
  );
};

export default SquareImageField;

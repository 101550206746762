import React from "react";
import {
  List,
  Datagrid,
  NumberField,
  ArrayField,
  Responsive,
  SimpleList,
  Filter,
  SelectInput,
  SingleFieldList,
  TextField,
  FunctionField,
} from "react-admin";
import LocalDateTimeField from "../components/LocalDateTimeField";
import { TimePeriod } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/time_pb";
import UserAvatar from "../components/UserAvatar";
import SquareImageField from "../components/SquareImageField";
import { BookingStatus } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/booking_pb";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { CurrencyField } from "../components/CurrencyField";

const timePeriodChoices = [
  {
    name: "Upcoming",
    id: TimePeriod.TIME_PERIOD_FUTURE,
  },
  {
    name: "Past",
    id: TimePeriod.TIME_PERIOD_PAST,
  },
  {
    name: "All",
    id: TimePeriod.TIME_PERIOD_UNSPECIFIED,
  },
];

const BookingsFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label=""
      source="timePeriod"
      choices={timePeriodChoices}
      allowEmpty={false}
      alwaysOn
    />
  </Filter>
);

const list = (props) => (
  <List
    {...props}
    sort={{ field: "startsAt", order: "ASC" }}
    exporter={false}
    actions={null}
    filters={<BookingsFilter />}
    filterDefaultValues={{
      timePeriod: TimePeriod.TIME_PERIOD_FUTURE,
      statusList: BookingStatus.BOOKING_STATUS_AVAILABLE,
    }}
    bulkActionButtons={false}
  >
    <Responsive
      small={
        <SimpleList
          linkType="show"
          primaryText={(record) =>
            `${record.resource.name} - ${record.space.name}`
          }
          secondaryText={(record) =>
            `${new Date(record.startsAt).toLocaleDateString(
              navigator.languages,
              {
                weekday: "short",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZone: record.space.timezone,
              }
            )} - ${new Date(record.endsAt).toLocaleTimeString(
              navigator.languages,
              {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: record.space.timezone,
              }
            )}`
          }
          tertiaryText={(record) =>
            `${record.confirmedMembersList.length} ${
              record.confirmedMembersList.length === 1 ? "member" : "members"
            }`
          }
        />
      }
      medium={
        <Datagrid rowClick="show">
          <SquareImageField source="resource.image" label="" sortable={false} />
          <TextField
            label="Meeting Room Name"
            source="resource.name"
            sortable={false}
          />
          <TextField label="Space" source="space.name" sortable={false} />
          <LocalDateTimeField
            label="Date"
            source="startsAt"
            showTime
            locales={navigator.language}
            timezoneSource="space.timezone"
            options={{
              dateStyle: "full",
            }}
          />
          <FunctionField
            source="pendingCount"
            label={
              <div>
                Number of pending requests
                <Tooltip title="These bookings need to be accepted or rejected before they're finalised.">
                  <IconButton aria-label="delete">
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
              </div>
            }
            sortable={false}
            render={(record) =>
              `${record.pendingCount > 0 ? "❗️ " : ""}${record.pendingCount}`
            }
          />
          <ArrayField
            source="confirmedMembersList"
            label="Lead bookers"
            sortable={false}
          >
            <SingleFieldList linkType={false}>
              <UserAvatar source="user.profileImage" nameSource="user.name" />
            </SingleFieldList>
          </ArrayField>

          <CurrencyField
            source="totalCost"
            spaceIdSource="space.id"
            label="Total estimated payout"
            sortable={false}
            style={{ fontWeight: "bold" }}
          />
        </Datagrid>
      }
    />
  </List>
);

export default list;

import React from "react";
import {
  BooleanField,
  List,
  Datagrid,
  TextField,
  Responsive,
  SimpleList,
  NumberField,
  usePermissions,
} from "react-admin";
import SquareImageField from "../components/SquareImageField";
import { CurrencyField } from "../components/CurrencyField";
import { hasPermission } from "../utils/auth";
import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";

const SpacesList = (props) => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      exporter={false}
      actions={null}
      filters={null}
      bulkActionButtons={false}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => `${record.address.city}`}
            tertiaryText={(record) => (record.listed ? "Listed" : "Unlisted")}
          />
        }
        medium={
          <Datagrid
            rowClick={
              hasPermission(
                permissions,
                OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_SPACES,
                SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_DETAILS
              )
                ? "edit"
                : "show"
            }
          >
            <SquareImageField
              source="imagesList.0.url"
              label=""
              sortable={false}
            />
            <TextField source="name" />
            <TextField source="address.city" label="City" />
            <BooleanField source="listed" label="Listed?" sortable={false} />
            <NumberField source="capacity" />
            <CurrencyField
              source="costPerHour"
              currencySource="currency"
              label="Price per hour per person"
            />
          </Datagrid>
        }
      />
    </List>
  );
};

export default SpacesList;

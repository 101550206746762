import { OrganisationAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import {
  getOrganisationDefaultRolesMapping,
  getSpacesDefaultRolesMapping,
} from "./role-mappings";

const mapAdmin = (admin) => ({
  ...admin,
  isOwner: Boolean(
    admin.rolesList.find(
      (role) => role === OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER
    )
  ),
  roleTypeMapping: mapRolesList(
    admin.rolesList,
    getOrganisationDefaultRolesMapping()
  ),
  notificationsList: (admin.notificationsList || []).map(
    (notificationPreference) => notificationPreference.type
  ),
  spacesList: (admin.spacesList || []).map((space) => ({
    ...space,
    roleTypeMapping: Object.values(
      mapRolesList(space.rolesList, getSpacesDefaultRolesMapping())
    ),
    notificationsList: (space.notificationsList || []).map(
      (notificationPreference) => notificationPreference.type
    ),
  })),
});

export default mapAdmin;

const mapRolesList = (rolesList, roleTypeMapping) => {
  rolesList.forEach((role) => {
    const roleTypeIndex = getRoleTypeIndex(roleTypeMapping, role);

    if (roleTypeIndex > -1) {
      setCurrentRoleHierarachyIndex(roleTypeIndex, roleTypeMapping, role);
    }
  });

  return roleTypeMapping;
};

const getRoleTypeIndex = (roleTypeMapping, role) =>
  roleTypeMapping.findIndex((roleTypePermissions) =>
    roleTypePermissions.roleHierarchy.find(
      (permission) => permission.role === role
    )
  );

const setCurrentRoleHierarachyIndex = (
  roleTypeIndex,
  roleTypeMapping,
  role
) => {
  if (roleTypeIndex > -1) {
    const { roleHierarchy, currentRole } = roleTypeMapping[roleTypeIndex];

    const currentRoleIndex = roleHierarchy.findIndex(
      (permission) => permission.role === currentRole
    );
    const newPermissionIndex = roleHierarchy.findIndex(
      (permission) => permission.role === role
    );
    roleTypeMapping[roleTypeIndex].currentRole =
      newPermissionIndex > currentRoleIndex ? role : currentRole;
  }
};

import providers from "../providers";
import isEqual from "lodash/isEqual";
import { UpdateBankAccountRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/bankaccount/v1/bank_account_pb";
import { Address } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/address_pb";
import { countryCodesById } from "../../common/country-codes-by-id";

const update = async ({ data, previousData, id }) => {
  const { BankAccounts } = providers;
  const request = new UpdateBankAccountRequest();
  request.setId(id);

  if (!isEqual(data.companyName, previousData.companyName)) {
    request.addFieldsToUpdate("company_name");
    request.setCompanyName(data.companyName);
  }

  if (!isEqual(data.vatNumber, previousData.vatNumber)) {
    request.addFieldsToUpdate("vat_number");
    request.setVatNumber(data.vatNumber);
  }

  if (!isEqual(data.bankName, previousData.bankName)) {
    request.addFieldsToUpdate("bank_name");
    request.setBankName(data.bankName);
  }

  if (!isEqual(data.country, previousData.country)) {
    request.addFieldsToUpdate("country");
    request.setCountry(data.country);
  }

  if (!isEqual(data.currency, previousData.currency)) {
    request.addFieldsToUpdate("currency");
    request.setCurrency(data.currency);
  }

  if (!isEqual(data.taxRate, previousData.taxRate)) {
    request.addFieldsToUpdate("tax_rate");
    request.setTaxRate(data.taxRate);
  }

  if (!isEqual(data.organisationId, previousData.organisationId)) {
    request.addFieldsToUpdate("organisation_id");
    request.setOrganisationId(data.organisationId);
  }

  if (!isEqual(data.spaceId, previousData.spaceId)) {
    request.addFieldsToUpdate("space_id");
    request.setSpaceId(data.spaceId);
  }

  // Account details

  if (!isEqual(data.accountNumber, previousData.accountNumber)) {
    request.addFieldsToUpdate("account_number");
    request.setAccountNumber(data.accountNumber);
  }

  if (!isEqual(data.routingNumber, previousData.routingNumber)) {
    request.addFieldsToUpdate("routing_number");
    request.setRoutingNumber(data.routingNumber);
  }

  if (!isEqual(data.bic, previousData.bic)) {
    request.addFieldsToUpdate("bic");
    request.setBic(data.bic);
  }

  if (!isEqual(data.ibanNumber, previousData.ibanNumber)) {
    request.addFieldsToUpdate("iban_number");
    request.setIbanNumber(data.ibanNumber);
  }

  if (!isEqual(data.ifscCode, previousData.ifscCode)) {
    request.addFieldsToUpdate("ifsc_code");
    request.setIfscCode(data.ifscCode);
  }

  if (!isEqual(data.bsbCode, previousData.bsbCode)) {
    request.addFieldsToUpdate("bsb_code");
    request.setBsbCode(data.bsbCode);
  }

  if (!isEqual(data.bankCode, previousData.bankCode)) {
    request.addFieldsToUpdate("bank_code");
    request.setBankCode(data.bankCode);
  }

  if (!isEqual(data.branchCode, previousData.branchCode)) {
    request.addFieldsToUpdate("branch_code");
    request.setBranchCode(data.branchCode);
  }

  if (!isEqual(data.phoneNumber, previousData.phoneNumber)) {
    request.addFieldsToUpdate("phone_number");
    request.setPhoneNumber(data.phoneNumber);
  }

  if (!isEqual(data.abartn, previousData.abartn)) {
    request.addFieldsToUpdate("abartn");
    request.setAbartn(data.abartn);
  }

  if (!isEqual(data.businessNumber, previousData.businessNumber)) {
    request.addFieldsToUpdate("business_number");
    request.setBusinessNumber(data.businessNumber);
  }

  if (!isEqual(data.transitNumber, previousData.transitNumber)) {
    request.addFieldsToUpdate("transit_number");
    request.setTransitNumber(data.transitNumber);
  }

  if (!isEqual(data.institutionNumber, previousData.institutionNumber)) {
    request.addFieldsToUpdate("institution_number");
    request.setInstitutionNumber(data.institutionNumber);
  }

  if (!isEqual(data.email, previousData.email)) {
    request.addFieldsToUpdate("email");
    request.setEmail(data.email);
  }

  if (!isEqual(data.clabe, previousData.clabe)) {
    request.addFieldsToUpdate("clabe");
    request.setClabe(data.clabe);
  }

  if (
    !isEqual(
      data.detailsIdentificationNumber,
      previousData.detailsIdentificationNumber
    )
  ) {
    request.addFieldsToUpdate("details_identification_number");
    request.setDetailsIdentificationNumber(data.detailsIdentificationNumber);
  }

  if (!isEqual(data.idDocumentType, previousData.idDocumentType)) {
    request.addFieldsToUpdate("id_document_type");
    request.setIdDocumentType(data.idDocumentType);
  }

  if (!isEqual(data.idDocumentNumber, previousData.idDocumentNumber)) {
    request.addFieldsToUpdate("id_document_number");
    request.setIdDocumentNumber(data.idDocumentNumber);
  }

  if (!isEqual(data.detailsCnpj, previousData.detailsCnpj)) {
    request.addFieldsToUpdate("details_cnpj");
    request.setDetailsCnpj(data.detailsCnpj);
  }

  if (!isEqual(data.rut, previousData.rut)) {
    request.addFieldsToUpdate("rut");
    request.setRut(data.rut);
  }

  if (
    !isEqual(data.address, previousData.address) &&
    (data.address || previousData.address)
  ) {
    if (data.address && Object.values(data.address).some((value) => !!value)) {
      request.addFieldsToUpdate("address");

      // Whole address update because the update mask only accounts
      // for top level keys
      const address = new Address()
        .setLine1(data.address.line1)
        .setLine2(data.address.line2)
        .setCity(data.address.city)
        .setRegion(data.address.region)
        .setZip(data.address.zip)
        .setCountry(
          countryCodesById[data.country].replace("COUNTRY_CODE_", "")
        );

      request.setAddress(address);
    } else {
      request.addFieldsToUpdate("address");
      request.clearAddress();
    }
  }

  if (!isEqual(data.taxId, previousData.taxId)) {
    request.addFieldsToUpdate("tax_id");
    request.setTaxId(data.taxId);
  }

  if (request.getFieldsToUpdateList().length === 0) {
    return {
      data: previousData,
    };
  }


  const updated = await BankAccounts.updateBankAccount(request, {
    authorization: localStorage.getItem("desana-tkn"),
  });

  return {
    data: updated.getResult().toObject(),
  };
};

export default update;

import {
  ResourceProperty,
  UpdateResourceRequest,
  DeleteResourceImageRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/resource/v1/resource_pb";
import { Range } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import { ValueType } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/value_type_pb";
import { camelCase, isEqual, snakeCase, upperFirst } from "lodash";
import xor from "lodash/xorWith";
import providers from "../providers";
import { fromDatabase } from "./mappers/from-database";

const fieldNames = [
  "instantBook",
  "sortWeight",
  "name",
  "description",
  "advancedAvailability",
  "additionalRules",
  "autoresponder",
  "minimumAdvanceNotice",
];

export const updateResource = async ({ data, previousData, id }) => {
  const { Resources } = providers;
  const request = new UpdateResourceRequest();
  request.setId(id);

  for (const fieldName of fieldNames) {
    if (!isEqual(data[fieldName], previousData[fieldName])) {
      request.addFieldsToUpdate(snakeCase(fieldName).replace("_list", ""));
      request[`set${upperFirst(camelCase(fieldName))}`](data[fieldName]);
    }
  }

  if (!isEqual(data.propertiesHashmap, previousData.propertiesHashmap)) {
    request.addFieldsToUpdate("properties");
    const resourcePropertyIdToType = (
      data.resourceType.propertiesList || []
    ).reduce(
      (acc, property) => ({
        ...acc,
        [property.id]: property.type,
      }),
      {}
    );

    request.setPropertiesList(
      Object.values(data.propertiesHashmap)
        .map(
          ({
            boolValue,
            intValue,
            doubleValue,
            stringValue,
            resourcePropertyType,
          }) => {
            const propertyType = resourcePropertyIdToType[resourcePropertyType];

            if (!propertyType) {
              return undefined;
            }

            const property = new ResourceProperty().setResourcePropertyType(
              resourcePropertyType
            );

            switch (propertyType) {
              case ValueType.VALUE_TYPE_BOOL:
                property.setBoolValue(boolValue);
                break;
              case ValueType.VALUE_TYPE_INT:
                property.setIntValue(intValue);
                break;
              case ValueType.VALUE_TYPE_DOUBLE:
                property.setDoubleValue(doubleValue);
                break;
              case ValueType.VALUE_TYPE_STRING:
              case ValueType.VALUE_TYPE_DATE:
                property.setStringValue(stringValue);
                break;
              default:
                break;
            }

            return property;
          }
        )
        .filter((val) => !!val)
    );
  }

  if (!isEqual(data.imagesList, previousData.imagesList)) {
    const differentImages = xor(
      data.imagesList,
      previousData.imagesList,
      isEqual
    );

    for (const diff of differentImages) {
      if (diff.rawFile) {
        const formData = new FormData();
        formData.append(`image`, diff.rawFile);
        await fetch(`${providers.imageUploadUrl}/resources/${id}`, {
          method: "POST",
          body: formData,
          headers: {
            authorization: localStorage.getItem("desana-tkn"),
          },
        });
        continue;
      }

      const deleteImageRequest = new DeleteResourceImageRequest();
      deleteImageRequest.setId(id);
      deleteImageRequest.setUrl(diff.url);
      await Resources.deleteResourceImage(deleteImageRequest, {
        authorization: localStorage.getItem("desana-tkn"),
      });
    }
  }

  if (!isEqual(data.capacityRange, previousData.capacityRange)) {
    request.addFieldsToUpdate("capacity_range");
    const capacityRange = new Range();
    capacityRange.setLo(data.capacityRange.lo);
    capacityRange.setHi(data.capacityRange.hi);
    request.setCapacityRange(capacityRange);
  }

  // if (!isEqual(data.wifiDetails, previousData.wifiDetails)) {
  //   request.addFieldsToUpdate("wifi_details");
  //   const wifi = new WifiDetails();
  //   wifi.setAdditionalDetails(data.wifiDetails.additionalDetails);
  //   wifi.setNetworkName(data.wifiDetails.networkName);
  //   wifi.setPassword(data.wifiDetails.password);
  //   request.setWifiDetails(wifi);
  // }

  const updated = await Resources.updateResource(request, {
    authorization: localStorage.getItem("desana-tkn"),
  });

  return {
    data: fromDatabase(updated.getResult().toObject()),
  };
};

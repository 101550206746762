import React from "react";
import {
  Button,
  Tab,
  ImageField,
  NumberField,
  ReferenceArrayField,
  SelectField,
  TabbedShowLayout,
  TextField,
  Show,
  Datagrid,
} from "react-admin";
import IconMessage from "@material-ui/icons/MessageOutlined";
// import { OpeningTimesField } from "../components/OpeningTimesField";
import { currencyOptions } from "../organisations/getAccountFields";

const SpaceTitle = ({ record }) => {
  return <span>{record.name}</span>;
};

const SpaceEdit = ({ classes, ...props }) => {
  return (
    <Show title={<SpaceTitle />} undoable={false} {...props}>
      <TabbedShowLayout redirect={false}>
        <Tab label="Basic Details">
          <TextField source="name" autoCorrect="off" spellCheck="off" />
          <TextField multiline fullWidth source="description" />
          <NumberField
            source="capacity"
            label="How many desks are available?"
          />
          <NumberField
            source="advancedAvailability"
            helperText="How many days in advance can this space be booked?"
            step={1}
          />
          <NumberField
            source="minimumAdvanceNotice"
            helperText="How many minutes notice would you like for same day bookings?"
          />
          <TextField
            multiline
            fullWidth
            source="autoresponder"
            label="Autoresponse message when workers book your space"
          />
        </Tab>
        <Tab label="Pricing">
          <NumberField
            disabled
            source="costPerHour"
            label="Price per hour"
            FieldProps={{
              step: 0.01,
            }}
          />
          <SelectField
            disabled
            source="currency"
            label="Currency"
            helperText="This will automatically be set when the bank account details are set"
            choices={currencyOptions}
            defaultValue={20}
          />
          <Button
            label="Change Pricing"
            alignIcon="right"
            color="primary"
            variant="outlined"
            onClick={() => window.Intercom("showNewMessage")}
          >
            <IconMessage />
          </Button>
        </Tab>
        <Tab label="Address &amp; Location">
          <TextField source="address.line1" label="Line 1" />
          <TextField source="address.line2" label="Line 2" />
          <TextField source="address.city" label="City" />
          <TextField source="address.country" label="Country" />
          <TextField source="address.zip" label="Postcode" />
          <TextField
            multiline
            fullWidth
            source="address.instructions"
            label="Entry instructions"
          />
        </Tab>
        {/* <Tab label="Opening Times">
          <OpeningTimesField />
        </Tab> */}
        <Tab label="Images">
          <ImageField
            source="optimisedImagesList"
            src="url"
            label="Space images"
          />
        </Tab>
        <Tab label="Wi-Fi Details">
          <TextField source="wifiDetails.networkName" label="Wi-Fi network" />
          <TextField source="wifiDetails.password" label="Wi-Fi password" />
          <TextField
            multiline
            fullWidth
            source="wifiDetails.additionalDetails"
            label="Additional details"
          />
          <NumberField
            source="downloadSpeed"
            label="Download speed (Mbps)"
            min={0}
            step={1}
          />
          <NumberField
            source="uploadSpeed"
            label="Upload speed (Mbps)"
            min={0}
            step={1}
          />
        </Tab>
        <Tab label="Amenities">
          <ReferenceArrayField
            source="amenitiesList"
            reference="spaceAmenities"
            label="Amenities"
          >
            <Datagrid linkType={false}>
              <TextField source="name" />
              <TextField source="description" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="Rules">
          <ReferenceArrayField
            source="rulesList"
            reference="spaceRules"
            label="Rules"
          >
            <Datagrid linkType={false}>
              <TextField source="name" />
              <TextField source="description" />
            </Datagrid>
          </ReferenceArrayField>
          <TextField multiline fullWidth source="additionalRules" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SpaceEdit;

import { formatRates } from "./rates";

export const fromDatabase = (resource) => {
  return {
    ...resource,
    costPerHour: Math.fround(resource.rates.base * 1000) / 1000,
    openingTimesMap: [...Array(7)].map((_, index) => {
      const openingTime = (resource.openingTimesMap || []).find(
        (ot) => ot[0] === `${index + 1}`
      );
      return {
        index: index + 1,
        isOpen: !!openingTime,
        open: openingTime ? getTimeString(openingTime[1].open) : "09:00",
        close: openingTime ? getTimeString(openingTime[1].close) : "17:00",
      };
    }),
    wifiDetails: resource.wifiDetails ? resource.wifiDetails : {},
    propertiesHashmap: (resource.propertiesList || []).reduce(
      (acc, property) => ({
        ...acc,
        [property.resourcePropertyType]: property,
      }),
      {}
    ),
    rates: formatRates(resource.rates),
  };
}

const getTimeString = (time) => {
  const hour = `${time.hour}`.padStart(2, "0");
  const minute = `${time.minute}`.padStart(2, "0");
  return `${hour}:${minute}`;
};

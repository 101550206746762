export const hoursToHoursAndMinutes = (hours) => {
  const mins = hours * 60;
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  let composed = "";
  if (h > 0) {
    composed = `${composed} ${h} ${h === 1 ? "hour" : "hours"}`;
  }

  if (m > 0) {
    composed = `${composed} ${m} ${m === 1 ? "minute" : "minutes"}`;
  }
  return composed;
};

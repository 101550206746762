import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Description from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import React from "react";
import {
  AppBar,
  MenuItemLink,
  Responsive,
  usePermissions,
  UserMenu,
} from "react-admin";
import { Link } from "react-router-dom";
import OrganisationIcon from "../organisations/OrganisationIcon";
import { hasPermission } from "../utils/auth";
import { get } from "../utils/storage";

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  logo: {
    height: 20,
    paddingRight: 25,
    paddingBottom: "0.2rem",
  },
  logoSmall: {
    height: 30,
    width: 30,
    paddingRight: 25,
  },
  spacer: {
    flex: 0,
  },
};

const HostUserMenu = (props) => {
  const userPermissions = usePermissions().permissions;

  const hasEditOrgPermissions = hasPermission(
    userPermissions,
    OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_DETAILS
  );

  let editOrganisationLinkText = hasEditOrgPermissions
    ? "Edit Business"
    : "View Business";

  const hasEditPaymentDetailPermissions = hasPermission(
    userPermissions,
    OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_PAYMENT_DETAILS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_PAYMENT_DETAILS
  );

  editOrganisationLinkText += hasEditPaymentDetailPermissions
    ? " & Bank Details"
    : " Details";

  let showEditDetails = false;
  let org;

  try {
    org = get("currentOrganisation");
    if (org) {
      showEditDetails = true;
    }
  } catch (err) { }

  return (
    <UserMenu {...props} icon={<OrganisationIcon />} label="Account">
      {showEditDetails && (
        <MenuItemLink
          primaryText={editOrganisationLinkText}
          leftIcon={<SettingsIcon />}
          to={`/organisations/${org.organisation?.id}`}
        />
      )}
      <MenuItemLink
        to="/profile"
        primaryText="My Profile"
        leftIcon={<AccountCircle />}
      />
      {(get("user") || { organisationsList: [] }).organisationsList.length >
        1 ? (
        <MenuItemLink
          to="/switch-org/current"
          primaryText="Switch Organisations"
          leftIcon={<SwapHoriz />}
        />
      ) : null}
      <TermsItem />
    </UserMenu>
  );
};

const TermsItem = React.forwardRef((props, ref) => (
  <MenuItemLink
    to={{
      pathname:
        "https://help.desana.io/en/collections/3292762-terms-and-conditions-and-policies",
    }}
    target="_blank"
    primaryText="Terms & Policies"
    leftIcon={<Description />}
  />
));

const DesanaAppBar = withStyles(styles)(({ classes, ...props }) => (
  <AppBar {...props} userMenu={<HostUserMenu />}>
    <Responsive
      small={
        <Link to="/">
          <img
            alt="Desana Operator Dashboard - Thanks for being amazing"
            src="/icon-white.png"
            className={classes.logoSmall}
          />
        </Link>
      }
      medium={
        <Link to="/">
          <img
            alt="Desana Operator Dashboard - Thanks for being amazing"
            src="/desana-logo-white.png"
            className={classes.logo}
          />
        </Link>
      }
    />
    <Typography
      variant="h6"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    <span className={classes.spacer} />
  </AppBar>
));

export default DesanaAppBar;

/**
 * Gets a value from local storage
 * @param {String} key The key name in local storage
 * @param {Boolean} [parse=true] Should the value be parsed or not?
 */
const get = (key, parse = true) => {
  const fetched = localStorage.getItem(key);

  if (!fetched || !parse) {
    return fetched;
  }

  if (fetched === "undefined") {
    return undefined;
  }

  return JSON.parse(fetched);
};

/**
 * Sets a value in local storage
 * @param {String} key The key name in local storage
 * @param {Any} value The value to be stored
 * @param {Boolean} [serialize=true] Should the value be serialized or not?
 */
const set = (key, value, serialize = true) => {
  let v;
  v = { value }.value;

  if (serialize) {
    v = JSON.stringify(v);
  }

  localStorage.setItem(key, v);
};

export { get, set };

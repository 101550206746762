import { GET_ONE, GET_LIST, GET_MANY, UPDATE } from "react-admin";
import { get, set } from "../utils/storage";
import Me from "./me";

const switchOrg = (type, params) => {
  switch (type) {
    case GET_ONE:
      return getOne(params);
    case GET_LIST:
      return list(params);
    case GET_MANY:
      return list(params);
    case UPDATE:
      return update(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for swtich-org ${type}`
      );
  }
};

export default switchOrg;

const getOne = async () => {
  await Me(GET_ONE);
  const currentOrg = get("currentOrganisation");
  return {
    data: {
      orgId: currentOrg.organisation.id,
      org: currentOrg.organisation,
      id: "current",
    },
  };
};

const list = async () => {
  const { data } = await Me(GET_ONE);
  return {
    total: data.organisationsList.length,
    data: data.organisationsList
      .filter((a) => a.organisation && a.organisation.name)
      .sort((a, b) => a.organisation.name.localeCompare(b.organisation.name))
      .map((org) => ({
        id: org.organisation.id,
        name: org.organisation.name,
        org,
      })),
  };
};

const update = async (params) => {
  const user = get("user");
  const newOrg = user.organisationsList.find(
    (o) => o.organisation.id === params.data.orgId
  );

  if (!newOrg) {
    throw new Error("Please choose a valid organisation;");
  }
  set("currentOrganisation", newOrg);
  window.location.assign("/");
  return {
    data: params.data,
  };
};

import { isArray, isObject, includes, isEqualWith, omitBy } from "lodash";

export const comparisonFunc = (a, b) => {
  if (isArray(a) || isArray(b)) return;
  if (!isObject(a) || !isObject(b)) return;

  if (!includes(a, undefined) && !includes(b, undefined)) return;

  // Call recursively, after filtering all undefined properties
  return isEqualWith(
    omitBy(a, (value) => value === undefined || value === null),
    omitBy(b, (value) => value === undefined || value === null),
    comparisonFunc
  );
};

import { optimiseImage } from "../common/image-proxy";
export const imageFieldFormatter = (data) => {
  // Single image
  if (data && data.image) {
    if (!data.image) {
      return data;
    }
    return {
      ...data,
      image: optimiseImage(data.image, { size: 0 }),
    };
  } else if (data && data.url) {
    return {
      ...data,
      url: optimiseImage(data.url, { size: 0 }),
    };
  }

  return data;
};

const en = {
  desana: {
    auth: {
      email: "Email address",
      magicLink: "Magic link URL",
      send: "Send Magic Link",
      verify: "Verify",
      sent: "Magic link sent!",
      error: "Error sending magic link! Please try again.",
      validation: {
        magicLinkInvalid: "Please enter a valid magic link",
      },
    },
    userProfile: {
      show: "View profile",
      memberSince: "Member since",
    },
    organisationSwitcher: {
      success: "Organisation successfully changed!",
    },
  },
};

export default en;

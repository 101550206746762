export const darkTheme = {
  palette: {
    type: "dark", // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#2ba8b2",
    },
    secondary: {
      main: "#167c77",
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 1.5,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiFab: {
      primary: {
        fontWeight: 600,
      },
      root: {
        boxShadow: "none",
      },
    },
  },
  typography: {
    fontFamily: [
      "Avenir",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
    headline: {
      fontFamily: [
        "ITC Souvenir",
        "Georgia",
        "Times",
        '"Times New Roman"',
        "serif",
      ].join(","),
      fontSize: 28,
    },
  },
  sidebar: {
    width: 240, // The default value is 240
    closedWidth: 55, // The default value is 55
  },
  menu: {
    width: 240,
    closedWidth: 55,
  },
};

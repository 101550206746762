import React from "react";
import { linkToRecord, Loading, useRecordContext } from "react-admin";
import { Typography, withStyles } from "@material-ui/core";
import { Link } from "ra-ui-materialui";
import {
  Datagrid,
  SimpleShowLayout,
  ArrayField,
  FunctionField,
} from "react-admin";
import LocalDateTimeField from "../components/LocalDateTimeField";
import UserProfileQuickPreviewButton from "../components/UserProfileQuickPreviewButton";

const styles = (theme) => ({
  container: {
    display: "grid",
    overflow: "auto",
  },
  root: {
    padding: 0,
    margin: 0,
    textAlign: "left",
    display: "table !important",
    width: "100% !important",
    borderSpacing: "0px !important",
    "&:first-child": {
      padding: 0,
    },
  },
  arrayField: {
    "& div[class^='Labeled']": {
      padding: 0,
    },
    "& div[class^='MuiFormControl']": {
      display: "flex",
      margin: 0,
    },
    "& thead tr": {
      height: "30px",
    },
  },
});

const TodaysOverviewDetails = ({ loading, data, error, classes }) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR, {error.message}</p>;
  }

  let content = "";
  if (!data.data || (data.data.length === 0 && !data.nextBooking)) {
    content = (
      <Typography variant="subtitle1">
        You have no upcoming bookings.
      </Typography>
    );
  } else if (data.data.length > 0) {
    content = todaysBookings(data, classes);
  } else {
    content = (
      <Typography variant="subtitle1">
        You have no bookings for today. Your next booking is{" "}
        <Link to={`/bookings/${data.nextBooking.id}/show`}>
          {new Date(data.nextBooking.startsAt).toLocaleDateString(
            navigator.language,
            {
              timeZone: data.nextBooking.space.timezone,
              weekday: "long",
              day: "2-digit",
              month: "long",
              year: "numeric",
            }
          )}
        </Link>
        .
      </Typography>
    );
  }

  // const firstBooking = data.data[0];
  // let content = "";
  // if (firstBooking && firstBooking.membersList.length > 0) {
  //   const firstBookingStartsAt = new Date(firstBooking.startsAt);
  //   const firstBookingIsToday = isToday(firstBookingStartsAt);

  //   content = true ? (
  //     todaysBooking(firstBooking, classes)
  //   ) : (
  //     <Typography variant="subtitle1">
  //       You have no bookings for today. Your next booking is{" "}
  //       <Link to={`/bookings/${firstBooking.id}/show`}>
  //         {firstBookingStartsAt.toLocaleDateString(navigator.language, {
  //           weekday: "long",
  //           day: "2-digit",
  //           month: "long",
  //           year: "numeric",
  //         })}
  //       </Link>
  //       .
  //     </Typography>
  //   );
  // } else {
  //   content = (
  //     <Typography variant="subtitle1">
  //       You have no upcoming bookings.
  //     </Typography>
  //   );
  // }

  return <div className={classes.container}>{content}</div>;
};
const memberRowStyle = (record, index) => ({
  textDecoration: record.cancelledAt ? "line-through" : "none",
});

const todaysBookings = (data, classes) => (
  <SimpleShowLayout record={data} classes={{ root: classes.root }}>
    <ArrayField source="data" label="" className={classes.arrayField}>
      <Datagrid rowStyle={memberRowStyle} selectedIds={[]}>
        <UserProfileQuickPreviewButton
          source="user"
          sortable={false}
          label=""
        />
        <LinkToSpace label="Where?" sortable={false} />
        <LocalDateTimeField
          source="startsAt"
          label="Arriving"
          showTime
          showDate={false}
          sortable={false}
          options={{ hour: "2-digit", minute: "2-digit" }}
          timezoneSource="space.timezone"
        />
        <LocalDateTimeField
          source="endsAt"
          label="Leaving"
          showTime
          showDate={false}
          sortable={false}
          options={{ hour: "2-digit", minute: "2-digit" }}
          timezoneSource="space.timezone"
        />
        <FunctionField
          label="Duration"
          sortable={false}
          render={(record) => bookingHoursToHoursMinutes(record)}
        />
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
);

const bookingHoursToHoursMinutes = (booking) => {
  const mins = booking.hours * 60;
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  let composed = "";
  if (h > 0) {
    composed = `${composed} ${h} ${h === 1 ? "hour" : "hours"}`;
  }

  if (m > 0) {
    composed = `${composed} ${m} ${m === 1 ? "minute" : "minutes"}`;
  }
  return composed;
};

const LinkToSpace = (props) => {
  const record = useRecordContext(props);
  const linkToUser = linkToRecord("/spaces", record.space.id, "edit");

  return <Link to={linkToUser}>{record.space.name}</Link>;
};

export default withStyles(styles)(TodaysOverviewDetails);

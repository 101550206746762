import { CreateBankAccountRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/bankaccount/v1/bank_account_pb";
import { Address } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/address_pb";
import { countryCodesById } from "../../common/country-codes-by-id";
import providers from "../providers";

const create = async (params) => {
  const { BankAccounts } = providers;
  const { data } = params;
  const request = new CreateBankAccountRequest();

  if (data.companyName) {
    request.setCompanyName(data.companyName);
  }

  if (data.vatNumber) {
    request.setVatNumber(data.vatNumber);
  }

  if (data.bankName) {
    request.setBankName(data.bankName);
  }

  if (data.country) {
    request.setCountry(data.country);
  }

  if (data.currency) {
    request.setCurrency(data.currency);
  }

  if (data.taxRate) {
    request.setTaxRate(data.taxRate);
  }

  if (data.organisationId) {
    request.setOrganisationId(data.organisationId);
  }

  if (data.spaceId) {
    request.setSpaceId(data.spaceId);
  }

  // Account details

  if (data.accountNumber) {
    request.setAccountNumber(data.accountNumber);
  }

  if (data.routingNumber) {
    request.setRoutingNumber(data.routingNumber);
  }

  if (data.bic) {
    request.setBic(data.bic);
  }

  if (data.ibanNumber) {
    request.setIbanNumber(data.ibanNumber);
  }

  if (data.ifscCode) {
    request.setIfscCode(data.ifscCode);
  }

  if (data.bsbCode) {
    request.setBsbCode(data.bsbCode);
  }

  if (data.bankCode) {
    request.setBankCode(data.bankCode);
  }

  if (data.branchCode) {
    request.setBranchCode(data.branchCode);
  }

  if (data.phoneNumber) {
    request.setPhoneNumber(data.phoneNumber);
  }

  if (data.abartn) {
    request.setAbartn(data.abartn);
  }

  if (data.businessNumber) {
    request.setBusinessNumber(data.businessNumber);
  }

  if (data.transitNumber) {
    request.setTransitNumber(data.transitNumber);
  }

  if (data.institutionNumber) {
    request.setInstitutionNumber(data.institutionNumber);
  }

  if (data.email) {
    request.setEmail(data.email);
  }

  if (data.clabe) {
    request.setClabe(data.clabe);
  }

  if (data.detailsIdentificationNumber) {
    request.setDetailsIdentificationNumber(data.detailsIdentificationNumber);
  }

  if (data.idDocumentType) {
    request.setIdDocumentType(data.idDocumentType);
  }

  if (data.idDocumentNumber) {
    request.setIdDocumentNumber(data.idDocumentNumber);
  }

  if (data.detailsCnpj) {
    request.setDetailsCnpj(data.detailsCnpj);
  }

  if (data.rut) {
    request.setRut(data.rut);
  }

  if (data.address && Object.keys(data.address).length > 0) {
    const address = new Address();
    if (data.address.line1) {
      address.setLine1(data.address.line1);
    }
    if (data.address.line2) {
      address.setLine2(data.address.line2);
    }
    if (data.address.city) {
      address.setCity(data.address.city);
    }
    if (data.address.region) {
      address.setRegion(data.address.region);
    }
    if (data.address.zip) {
      address.setZip(data.address.zip);
    }
    if (data.country) {
      address.setCountry(
        countryCodesById[data.country].replace("COUNTRY_CODE_", "")
      );
    }
    request.setAddress(address);
  }

  if (data.taxId) {
    request.setTaxId(data.taxId);
  }

  let bankAccount = await BankAccounts.createBankAccount(request, {
    authorization: localStorage.getItem("desana-tkn"),
  });

  return {
    data: bankAccount.getResult().toObject(),
  };
};

export default create;

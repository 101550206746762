import { Typography } from "@material-ui/core";
import { OperatorNotificationType } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/notifications_pb";
import get from "lodash/get";
import React from "react";
import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  email,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import {
  getOrganisationDefaultRolesMapping,
  getSpacesDefaultRolesMapping,
} from "../ra-data-desana/organisations/admin/role-mappings";

const ALL_NOTIFICATION_TYPES = Object.freeze([
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_BOOKINGS}`,
    name: "Hot desk booking notifications",
  },
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_MEETING_ROOM_BOOKINGS}`,
    name: "Meeting room booking notifications",
  },
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_PRIVATE_OFFICE_BOOKINGS}`,
    name: "Private office booking notifications",
  },
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_OTHER_BOOKINGS}`,
    name: "Other booking notifications",
  },
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_FINANCIAL}`,
    name: "Financial",
  },
]);

const SPACE_NOTIFICATION_TYPES = Object.freeze([
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_BOOKINGS}`,
    name: "Hot desk booking notifications for this space",
  },
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_MEETING_ROOM_BOOKINGS}`,
    name: "Meeting room booking notifications for this space",
  },
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_PRIVATE_OFFICE_BOOKINGS}`,
    name: "Private office booking notifications for this space",
  },
  {
    id: `${OperatorNotificationType.OPERATOR_NOTIFICATION_TYPE_OTHER_BOOKINGS}`,
    name: "Other booking notifications for this space",
  },
]);

const emailAddressValidator = [required(), email()];
const spaceValidator = required();

const AdminInput = (props) => {
  return (
    <SimpleFormIterator
      disableAdd={props.disabled}
      disableRemove={props.disabled}
    >
      <FormDataConsumer>
        {({ scopedFormData, formData, getSource }) => {
          const isOwner = !!scopedFormData?.isOwner || false;

          return (
            <>
              <TextInput
                label="Email Address"
                source={getSource("email")}
                validate={emailAddressValidator}
                fullWidth
                disabled={
                  !!get(formData, getSource("user.createdAt")) ||
                  props?.disabled
                }
              />
              <br />
              <Typography variant="subtitle1">
                Organisation permissions
              </Typography>
              <br />
              <BooleanInput
                label="Owner (all roles and all spaces)"
                source={getSource("isOwner")}
                defaultValue={true}
                fullWidth
                disabled={props?.disabled}
              />
              {scopedFormData &&
                getOrganisationPermissionInputs(
                  isOwner,
                  getSource,
                  props.disabled
                )}
              <CheckboxGroupInput
                source={getSource("notificationsList")}
                label="Organisation level notifications"
                choices={ALL_NOTIFICATION_TYPES}
                disabled={props?.disabled}
              />
              {scopedFormData && !isOwner ? (
                <ArrayInput
                  source={getSource("spacesList")}
                  label="Space permissions"
                  disabled={props?.disabled}
                >
                  <SimpleFormIterator>
                    <FormDataConsumer>
                      {({ getSource: getSpaceSource }) => (
                        <>
                          <ReferenceInput
                            source={`${getSpaceSource("id")}`}
                            reference="spaces"
                            label="Space"
                            filter={{ organisation: props.record.id }}
                            validate={spaceValidator}
                            perPage={100}
                          >
                            <SelectInput
                              source={`${getSpaceSource("name")}`}
                              label="Name"
                              disabled={props?.disabled}
                            />
                          </ReferenceInput>
                          {getSpacesDefaultRolesMapping().map(
                            (roleMapping, index) => (
                              <RadioButtonGroupInput
                                key={`space-${roleMapping.name}`}
                                row
                                source={`${getSpaceSource(
                                  "roleTypeMapping"
                                )}[${index}].currentRole`}
                                label={roleMapping.name}
                                defaultValue={-1}
                                choices={[
                                  {
                                    id: -1,
                                    name: "None",
                                  },
                                  ...roleMapping.roleHierarchy.map(
                                    (permission) => ({
                                      id: permission.role,
                                      name: permission.type,
                                    })
                                  ),
                                ]}
                                disabled={props?.disabled}
                                fullWidth
                              />
                            )
                          )}
                          <CheckboxGroupInput
                            source={`${getSpaceSource("notificationsList")}`}
                            label="Space level notifications"
                            choices={SPACE_NOTIFICATION_TYPES}
                            disabled={props?.disabled}
                          />
                        </>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              ) : null}
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  );
};

const getOrganisationPermissionInputs = (isOwner, getSource, isDisabled) => {
  return getOrganisationDefaultRolesMapping().map((roleMapping, index) => (
    <RadioButtonGroupInput
      key={`org-${roleMapping.name}`}
      row
      source={
        isOwner
          ? `${getSource("roleTypeMapping")}[${index}].roleHierarchy[${roleMapping.roleHierarchy.length - 1
          }].role`
          : `${getSource("roleTypeMapping")}[${index}].currentRole`
      }
      label={roleMapping.name}
      defaultValue={
        isOwner
          ? roleMapping.roleHierarchy[roleMapping.roleHierarchy.length - 1].role
          : -1
      }
      disabled={isOwner || isDisabled}
      choices={[
        {
          id: -1,
          name: "None",
        },
        ...roleMapping.roleHierarchy.map((permission) => ({
          id: permission.role,
          name: permission.type,
        })),
      ]}
      fullWidth
    />
  ));
};

export default AdminInput;

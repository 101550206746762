import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import { Box, Divider, Typography, Collapse } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import PrivateOffice from "@material-ui/icons/Business";
import {
  default as BookingsIcon,
  default as ResourceBookingsIcon,
} from "@material-ui/icons/Event";
import { default as HelpIcon } from "@material-ui/icons/Help";
import SpacesIcon from "@material-ui/icons/LocationCity";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import EventSpaceIcon from "@material-ui/icons/LocalActivity";
import ReceiptIcon from "@material-ui/icons/Receipt";
import createHistory from "history/createBrowserHistory";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

import {
  Admin,
  DashboardMenuItem,
  Layout,
  Menu,
  MenuItemLink,
  Resource,
  usePermissions,
} from "react-admin";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import BookingsList from "./bookings/BookingsList";
import BookingsShow from "./bookings/BookingsShow";
import DesanaAppBar from "./components/DesanaAppBar";
import { PRIVATE_OFFICES_ENABLED } from "./config";
import Dashboard from "./dashboard/Dashboard";
import desanaMessagesEN from "./i18n/en";
import LoginPage from "./login/LoginPage";
import MeEdit from "./me/MeEdit";
import OrganisationEdit from "./organisations/OrganisationEdit";
import OrganisationSwitcher from "./organisations/OrganisationSwitcher";
import desanaAuthProvider from "./ra-auth-desana/authProvider";
import desanaDataProvider from "./ra-data-desana";
import ReportShow from "./reports/ReportShow";
import ReportsList from "./reports/ReportsList";
import ResourceBookingsList from "./resource-bookings/ResourceBookingsList";
import ResourceBookingsShow from "./resource-bookings/ResourceBookingsShow";
import { ResourceEdit } from "./resources/ResourceEdit";
import { ResourcesList } from "./resources/ResourcesList";
import SpaceEdit from "./spaces/SpaceEdit";
import SpaceShow from "./spaces/SpaceShow";
import SpacesList from "./spaces/SpacesList";
import { lightTheme } from "./themes";
import { hasPermission, hasSomePermissions } from "./utils/auth";
import { get } from "./utils/storage";
const history = createHistory();

const messages = {
  en: {
    ...englishMessages,
    ...desanaMessagesEN,
  },
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const dataProvider = desanaDataProvider(
  import.meta.env.VITE_GRPC_URL,
  import.meta.env.VITE_IMAGE_UPLOADS_URL,
  import.meta.env.VITE_OPENING_TIMES_URL
);

const MyLayout = (props) => (
  <Layout {...props} appBar={DesanaAppBar} menu={OperatorMenu} />
);

const myTheme = createTheme(lightTheme);

const App = () => (
  <Admin
    dashboard={Dashboard}
    dataProvider={dataProvider}
    loginPage={LoginPage}
    i18nProvider={i18nProvider}
    authProvider={desanaAuthProvider}
    layout={MyLayout}
    theme={myTheme}
    history={history}
    customRoutes={[
      <Route key="profile" path="/profile" component={MeEdit} />,
      <Redirect
        from="/resourceBookings/:id/show"
        to="/meetingRoomBookings/:id/show"
      />,
      <Redirect from="/resourceBookings" to="/meetingRoomBookings" />,
    ]}
    disableTelemetry
  >
    {(permissions) => {
      const hasEditSpacePermission = hasPermission(
        permissions,
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_DETAILS,
        SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_DETAILS
      );


      const hasEditBookingsPermission = hasPermission(
        permissions,
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_BOOKINGS,
        SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS
      );
      const hasViewBookingsPermission = hasPermission(
        permissions,
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_BOOKINGS,
        SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS
      ) || hasEditBookingsPermission;

      const hasViewSpacePermission = hasPermission(
        permissions,
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_SPACES,
        SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_DETAILS
      ) || hasEditSpacePermission || hasViewBookingsPermission || hasEditBookingsPermission;

      return [
        hasViewBookingsPermission ? (
          <Resource name="bookings" list={BookingsList} show={BookingsShow} />
        ) : null,
        hasViewBookingsPermission ? (
          <Resource
            options={{ label: "Meeting Room Bookings" }}
            name="meetingRoomBookings"
            list={ResourceBookingsList}
            icon={ResourceBookingsIcon}
            show={ResourceBookingsShow}
          />
        ) : null,
        PRIVATE_OFFICES_ENABLED &&
          hasViewBookingsPermission ? (
          <Resource
            options={{ label: "Private Day Office Bookings" }}
            name="privateOfficeBookings"
            list={ResourceBookingsList}
            icon={ResourceBookingsIcon}
            show={ResourceBookingsShow}
          />
        ) : null,
        hasViewSpacePermission ? (
          <Resource
            name="spaces"
            list={SpacesList}
            show={SpaceShow}
            edit={
              hasEditSpacePermission
                ? SpaceEdit
                : null
            }
            icon={SpacesIcon}
          />
        ) : null,
        hasViewSpacePermission ? (
          <Resource
            options={{ label: "Meeting Rooms" }}
            name="meetingRooms"
            list={ResourcesList}
            edit={
              hasEditSpacePermission
                ? ResourceEdit
                : null
            }
          />
        ) : null,
        PRIVATE_OFFICES_ENABLED &&
          hasViewSpacePermission ? (
          <Resource
            options={{ label: "Private Day Offices" }}
            name="privateOffices"
            list={ResourcesList}
            edit={
              hasEditSpacePermission
                ? ResourceEdit
                : null
            }
          />
        ) : null,
        hasViewSpacePermission ? (
          <Resource
            options={{ label: "Event Spaces" }}
            name="eventSpaces"
            list={ResourcesList}
          />
        ) : null,
        hasPermission(
          permissions,
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYOUTS,
          SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_PAYOUTS
        ) ? (
          <Resource name="reports" list={ReportsList} show={ReportShow} />
        ) : null,
        <Resource name="spaceAmenities" />,
        <Resource name="spaceRules" />,
        <Resource name="spaceTypes" />,
        <Resource name="resourceTypes" />,
        <Resource name="users" />,
        <Resource name="me" />,
        <Resource name="switch-org" edit={OrganisationSwitcher} />,
        <Resource name="organisations" edit={OrganisationEdit} />,
        <Resource name="locations" />,
        <Resource name="dashboardAnalytics" />,
        <Resource name="pendingResourceBookings" />,
      ]
    }}
  </Admin>
);

const OperatorMenu = () => {
  const { isLoading, permissions } = usePermissions();
  const isMenuOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  if (isLoading) {
    return;
  }

  const hasEditSpacePermission = hasPermission(
    permissions,
    OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_DETAILS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_DETAILS
  );
  const hasViewSpacePermission = hasPermission(
    permissions,
    OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_SPACES,
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_DETAILS
  ) || hasEditSpacePermission;

  const hasEditBookingsPermission = hasPermission(
    permissions,
    OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS
  );
  const hasViewBookingsPermission = hasPermission(
    permissions,
    OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS
  ) || hasEditBookingsPermission;

  return (
    <Menu>
      {hasSomePermissions(
        permissions,
        [
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_ANALYTICS,
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYOUTS,
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_BOOKINGS,
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_BOOKINGS,
        ],
        [
          SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_ANALYTICS,
          SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_PAYOUTS,
          SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
          SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
        ]
      ) ? (
        <>
          <DashboardMenuItem />
          <Box my="1em">
            <Divider variant="middle" />
          </Box>
        </>
      ) : null}
      {hasViewBookingsPermission ? (
        <>
          <Collapse in={isMenuOpen}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                paddingInline: "20px",
                fontVariantCaps: "all-petite-caps",
              }}
            >
              Bookings
            </Typography>
          </Collapse>
          <MenuItemLink
            to="/bookings"
            primaryText="Hot Desks"
            tooltipProps={{ title: "Hot Desk Bookings" }}
            leftIcon={<BookingsIcon />}
          />
          <MenuItemLink
            to="/meetingRoomBookings"
            primaryText="Meeting Rooms"
            tooltipProps={{ title: "Meeting Room Bookings" }}
            leftIcon={<BookingsIcon />}
          />
          {PRIVATE_OFFICES_ENABLED && (
            <MenuItemLink
              to="/privateOfficeBookings"
              primaryText="Private Day Offices"
              tooltipProps={{ title: "Private Day Office Bookings" }}
              leftIcon={<BookingsIcon />}
            />
          )}
          <Box my="1em">
            <Divider variant="middle" />
          </Box>
        </>
      ) : null}
      {hasViewSpacePermission ? (
        <>
          <Collapse in={isMenuOpen}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                paddingInline: "20px",
                fontVariantCaps: "all-petite-caps",
                // opacity: isMenuOpen ? 1 : 0,
              }}
            >
              Resources
            </Typography>
          </Collapse>
          <MenuItemLink
            to="/spaces"
            primaryText="Spaces"
            tooltipProps={{ title: "Spaces" }}
            leftIcon={<SpacesIcon />}
          />
          <MenuItemLink
            to="/meetingRooms"
            primaryText="Meeting Rooms"
            tooltipProps={{ title: "Meeting Rooms" }}
            leftIcon={<MeetingRoomIcon />}
          />
          {PRIVATE_OFFICES_ENABLED && (
            <MenuItemLink
              to="/privateOffices"
              primaryText="Private Day Offices"
              tooltipProps={{ title: "Private Day Offices" }}
              leftIcon={<PrivateOffice />}
            />
          )}
          <MenuItemLink
            to="/eventSpaces"
            primaryText="Event Spaces"
            tooltipProps={{ title: "Event Spaces" }}
            leftIcon={<EventSpaceIcon />}
          />
          <Box my="1em">
            <Divider variant="middle" />
          </Box>
        </>
      ) : null}
      {hasPermission(
        permissions,
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYOUTS
      ) ? (
        <>
          <Collapse in={isMenuOpen}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                paddingInline: "20px",
                fontVariantCaps: "all-petite-caps",
              }}
            >
              Reports
            </Typography>
          </Collapse>
          <MenuItemLink
            to="/reports"
            primaryText="Payout Reports"
            leftIcon={<ReceiptIcon />}
          />
          <Box my="1em">
            <Divider variant="middle" />
          </Box>
        </>
      ) : null}
      <MenuItemLink
        to="#"
        onClick={(e) => {
          e.preventDefault();
          window.Intercom("show");
        }}
        primaryText="Help &amp; Support"
        leftIcon={<HelpIcon />}
      />
    </Menu>
  );
};

(function () {
  const token = get("desana-tkn", false);
  document.cookie = `${import.meta.env.VITE_AUTH_COOKIE_KEY
    }=${token}; path=/; secure; domain=desana.io; samesite=lax;`;
})();

export default App;

import React from "react";
import { Edit, SimpleForm, ReferenceInput, SelectInput } from "react-admin";
import SaveOnlyToolbar from "../components/SaveOnlyToolbar";

const OrganisationSwitcher = props => (
  <Edit undoable={false} title="Switch Organisations" {...props}>
    <SimpleForm toolbar={<SaveOnlyToolbar />}>
      <ReferenceInput
        label="Organisations"
        source="orgId"
        reference="switch-org"
        perPage={500}
      >
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default OrganisationSwitcher;

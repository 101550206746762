import { AuthenticationServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/authentication/v1/authentication_grpc_web_pb";
import { BankAccountsServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/bankaccount/v1/bank_account_grpc_web_pb";
import { BookingsServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/booking/v1/booking_grpc_web_pb";
import { ConfigurationServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/configuration/v1/configuration_grpc_web_pb";
import { AnalyticsServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/analytics/v1/analytics_grpc_web_pb";
import { MeServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/user/me/v1/me_grpc_web_pb";
import { OrganisationsServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/organisation/v1/organisation_grpc_web_pb";
import { ResourceServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/resource/v1/resource_grpc_web_pb";
import { SpacesServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/space/v1/space_grpc_web_pb";
import { UsersServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/user/user/v1/user_grpc_web_pb";
import { ReportsServicePromiseClient } from "@desanaio/public-hops-grpc-web/dist/desana/host/report/v1/report_grpc_web_pb";

/**
 * @typedef Providers
 * @type {object}
 * @property {AnalyticsServicePromiseClient} Analytics
 * @property {BankAccountsServicePromiseClient} BankAccounts
 * @property {AuthenticationServicePromiseClient} Authentication
 * @property {BookingsServicePromiseClient} Bookings
 * @property {ConfigurationServicePromiseClient} Configuration
 * @property {MeServicePromiseClient} Me
 * @property {OrganisationsServicePromiseClient} Organisations
 * @property {ResourceServicePromiseClient} Resources
 * @property {SpacesServicePromiseClient} Spaces
 * @property {UsersServicePromiseClient} Users
 */

/** @type {Providers} */
let providers;
let _imageUploadUrl;
let _openingTimesUrl;
/**
 * Initialises the providers
 * @param {string} url The gRPC server
 * @returns {{ Authentication: AuthenticationClient }}
 */
const init = (apiUrl, imageUploadUrl, openingTimesUrl) => {
  _imageUploadUrl = imageUploadUrl;
  _openingTimesUrl = openingTimesUrl;

  /** @type {Providers} */
  const _providers = {
    Analytics: new AnalyticsServicePromiseClient(apiUrl, null, null),
    BankAccounts: new BankAccountsServicePromiseClient(apiUrl, null, null),
    Authentication: new AuthenticationServicePromiseClient(apiUrl, null, null),
    Bookings: new BookingsServicePromiseClient(apiUrl, null, null),
    Configuration: new ConfigurationServicePromiseClient(apiUrl, null, null),
    Me: new MeServicePromiseClient(apiUrl, null, null),
    Organisations: new OrganisationsServicePromiseClient(apiUrl, null, null),
    Resources: new ResourceServicePromiseClient(apiUrl, null, null),
    Spaces: new SpacesServicePromiseClient(apiUrl, null, null),
    Users: new UsersServicePromiseClient(apiUrl, null, null),
    Reports: new ReportsServicePromiseClient(apiUrl, null, null),
  };

  providers = _providers;

  if (window.__GRPCWEB_DEVTOOLS__) {
    const enableDevTools = window.__GRPCWEB_DEVTOOLS__;
    enableDevTools(Object.keys(_providers).map((key) => _providers[key]));
  }

  return _providers;
};

const dataProviders = {
  init,
  get imageUploadUrl() {
    return _imageUploadUrl;
  },
  get openingTimesUrl() {
    return _openingTimesUrl;
  },
  /**
   * @return { BankAccountsServicePromiseClient } BankAccounts client
   */
  get BankAccounts() {
    if (!providers) {
      throw new Error("BankAccounts not intialised");
    }
    return providers.BankAccounts;
  },
  /**
   * @return { ConfigurationServicePromiseClient } Configuration client
   */
  get Configuration() {
    if (!providers) {
      throw new Error("Configuration not intialised");
    }
    return providers.Configuration;
  },

  /**
   * @return { AuthenticationServicePromiseClient } Authentication client
   */
  get Authentication() {
    if (!providers) {
      throw new Error("Authentication not intialised");
    }
    return providers.Authentication;
  },
  /**
   * @return { SpacesServicePromiseClient } Spaces client
   */
  get Spaces() {
    if (!providers) {
      throw new Error("Spaces not intialised");
    }
    return providers.Spaces;
  },
  /**
   * @return { BookingsServicePromiseClient } Bookings client
   */
  get Bookings() {
    if (!providers) {
      throw new Error("Bookings not intialised");
    }
    return providers.Bookings;
  },
  /**
   * @return { OrganisationsServicePromiseClient } Organisations client
   */
  get Organisations() {
    if (!providers) {
      throw new Error("Organisations not intialised");
    }
    return providers.Organisations;
  },
  /**
   * @return { MeServicePromiseClient } Me client
   */
  get Me() {
    if (!providers) {
      throw new Error("Me not intialised");
    }
    return providers.Me;
  },
  /**
   * @return { UsersServicePromiseClient } Me client
   */
  get Users() {
    if (!providers) {
      throw new Error("Users not intialised");
    }
    return providers.Users;
  },
  /**
   * @return { AnalyticsServicePromiseClient } Me client
   */
  get Analytics() {
    if (!providers) {
      throw new Error("Analytics not intialised");
    }
    return providers.Analytics;
  },
  /**
   * @return { ResourceServicePromiseClient } Me client
   */
  get Resources() {
    if (!providers) {
      throw new Error("Resources not intialised");
    }
    return providers.Resources;
  },
  /**
   * @return { ReportsServicePromiseClient } Reports client
   */
  get Reports() {
    if (!providers) {
      throw new Error("Reports not intialised");
    }
    return providers.Reports;
  },
};

export default dataProviders;

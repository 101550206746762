import { Box, Card, CardContent, Divider, Typography } from "@material-ui/core";
import IconMessage from "@material-ui/icons/MessageOutlined";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  TextInput,
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
} from "react-admin";
import { useFormState } from "react-final-form";
import { imageFieldFormatter } from "../common/image-field-formatter";
import { DesanaTabbedFormTabs } from "../components/DesanaTabbedFormTabs";
import SaveOnlyToolbar from "../components/SaveOnlyToolbar";
import Pricing from "./Pricing";
import { ResourcePropertiesInput } from "./ResourcePropertiesInput";

const validateName = [required(), minLength(3), maxLength(40)];
const validateDescription = [minLength(10), maxLength(700)];
const validateSpace = [required()];
const validateCapacity = [required(), minValue(1)];
const validateAdvancedAvailability = [required(), minValue(7), maxValue(365)];

const ResourceEditForm = ({ classes, redirect, record, ...props }) => {
  return (
    <TabbedForm
      undoable={false}
      tabs={<DesanaTabbedFormTabs hasAside={false} />}
      redirect={redirect}
      toolbar={<SaveOnlyToolbar />}
      record={record}
      {...props}
    >
      <FormTab label="Basic Details">
        <TextInput
          source="name"
          autoComplete="space-name"
          autoCorrect="off"
          spellCheck="off"
          validate={validateName}
        />
        <TextInput
          source="description"
          multiline
          fullWidth
          validate={validateDescription}
        />
        <TextInput
          multiline
          fullWidth
          source="autoresponder"
          helperText="Autoresponse message when workers book"
        />
        <ReferenceInput
          label="Space"
          source="space.id"
          reference="spaces"
          perPage={100}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput validate={validateSpace} disabled />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Booking Info">
        <BooleanInput
          source="instantBook"
          helperText={`Can this ${(
            record?.type?.name || "resource"
          ).toLowerCase()} be booked without approval?`}
          disabled
        />

        <Divider fullWidth style={{ marginBottom: "1em" }} />
        <Typography variant="h6">Capacity</Typography>
        <NumberInput
          source="capacityRange.hi"
          label="Maximum"
          helperText="Maxiumum amount of people can this resource accommodate?"
          validate={validateCapacity}
          disabled
        />
        <NumberInput
          source="capacityRange.lo"
          label="Minimum"
          helperText="Minimum amount of people can this resource accommodate?"
          validate={validateCapacity}
          defaultValue={1}
          disabled
        />
        <Divider fullWidth style={{ marginBottom: "1em" }} />
        <Typography variant="h6">Availability</Typography>
        <NumberInput
          source="advancedAvailability"
          helperText={`How many days in advance can this ${(
            record?.type?.name || "resource"
          ).toLowerCase()} be booked?`}
          step={1}
          defaultValue={30}
          validate={validateAdvancedAvailability}
          disabled
        />
        <NumberInput
          source="minimumAdvanceNotice"
          helperText={`How many minutes notice before this ${(
            record?.type?.name || "resource"
          ).toLowerCase()} can be booked?`}
          inputProps={{
            step: 15,
            min: 0,
            max: 60,
          }}
          defaultValue={1440}
          disabled
        />
        <Divider fullWidth />
        <ChangeAvailability />
      </FormTab>
      <FormTab label="Pricing">
        <Pricing />
      </FormTab>
      <FormTab label="Properties">
        <ResourcePropertiesInput />
      </FormTab>
      <FormTab label="Images">
        <ImageInput
          source="imagesList"
          label="Space images"
          accept="image/*"
          multiple
        >
          <FunctionField
            render={(record) => {
              if (record.rawFile) {
                return <ImageField record={record} />;
              }
              const optimisedRecord = imageFieldFormatter(record);
              return (
                <ImageField record={optimisedRecord} source="url" title="" />
              );
            }}
          />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  );
};

const ChangeAvailability = () => {
  const { values } = useFormState();

  if (!values?.name || !values?.id) {
    return null;
  }

  return (
    <Box component="span" m={2}>
      <Card mt={2}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Want to change your details?
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you'd like to change any of these details, please get in touch
            with us below.
          </Typography>

          <Button
            label="Change details"
            alignIcon="right"
            color="primary"
            variant="outlined"
            onClick={() =>
              window.Intercom(
                "showNewMessage",
                `I'd like to change the details for "${values.name}" (${values.id}) to:\n\n`
              )
            }
            style={{ marginTop: "20px" }}
          >
            <IconMessage />
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ResourceEditForm;

import React from "react";
import { Edit } from "react-admin";
import ResourceEditForm from "./ResourceEditForm";

const Title = ({ record }) => {
  return <span>Editing {record ? `"${record.name}"` : ""}</span>;
};

export const ResourceEdit = (props) => (
  <Edit {...props} title={<Title />} undoable={false}>
    <ResourceEditForm redirect={false} />
  </Edit>
);

import { PricingTimeUnit } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/pricing_model_pb";
import { Box, Divider, Typography } from "@material-ui/core";
import { NumberInput, required, SelectInput } from "react-admin";
import { currencyOptions } from "../../organisations/getAccountFields";

const validatePricePerHour = [required()];

const StandardPricing = ({ pricingModel }) => {
  const isDayUnit =
    pricingModel.standardPricingOptions.pricingTimeUnit ===
    PricingTimeUnit.PRICING_TIME_UNIT_DAY;
  return (
    <Box display={{ md: "block", lg: "flex" }} fullWidth>
      <Box
        flex={6}
        display={{ md: "block", lg: "block" }}
        mr={{ md: 0, lg: "1em" }}
      >
        <Typography variant="h6">Currency</Typography>
        <Box>
          <SelectInput
            source="currency"
            label="Currency"
            helperText="This will automatically be set when the bank account details are set"
            choices={currencyOptions}
            defaultValue={20}
            fullWidth
            disabled
          />
        </Box>
        <Divider style={{ marginBottom: "1em" }} />
        <Typography variant="h6">
          {isDayUnit ? "Daily" : "Hourly"} rate
        </Typography>
        <Typography variant="body2">
          This will be used when calculating the cost of bookings at this
          resource.
        </Typography>
        <Box>
          <NumberInput
            source="rates.base"
            label={`${isDayUnit ? "Daily" : "Hourly"} rate`}
            helperText="In the currency defined above"
            inputProps={{
              step: 0.05,
            }}
            validate={validatePricePerHour}
            fullWidth
            disabled
          />
        </Box>
        <Divider style={{ marginBottom: "1em", marginTop: "1em" }} />
      </Box>
    </Box>
  );
};

export default StandardPricing;

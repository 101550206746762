import { Children } from "react";
import { FormInput } from "react-admin";

export const StandardFormLayout = ({
  basePath,
  record,
  resource,
  variant,
  margin,
  children,
}) => (
  <div>
    {Children.map(
      children,
      (input) =>
        input && (
          <FormInput
            basePath={basePath}
            input={input}
            record={record}
            resource={resource}
            variant={input.props.variant || variant}
            margin={input.props.margin || margin}
          />
        )
    )}
  </div>
);

import React from "react";
import { Toolbar, SaveButton } from "react-admin";

const SaveOnlyToolbar = props => (
  <Toolbar {...props} undoable={undefined}>
    <SaveButton />
  </Toolbar>
);

export default SaveOnlyToolbar;

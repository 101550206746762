// export const countryCodesById = Object.fromEntries(
//   Object.entries(CountryCode).map(([key, value]) => [value, key])
// );

// Generate this list using the above function
export const countryCodesById = {
  0: "COUNTRY_CODE_UNSPECIFIED",
  1: "COUNTRY_CODE_AD",
  2: "COUNTRY_CODE_AE",
  3: "COUNTRY_CODE_AF",
  4: "COUNTRY_CODE_AG",
  5: "COUNTRY_CODE_AI",
  6: "COUNTRY_CODE_AL",
  7: "COUNTRY_CODE_AM",
  8: "COUNTRY_CODE_AO",
  9: "COUNTRY_CODE_AQ",
  10: "COUNTRY_CODE_AR",
  11: "COUNTRY_CODE_AS",
  12: "COUNTRY_CODE_AT",
  13: "COUNTRY_CODE_AU",
  14: "COUNTRY_CODE_AW",
  15: "COUNTRY_CODE_AX",
  16: "COUNTRY_CODE_AZ",
  17: "COUNTRY_CODE_BA",
  18: "COUNTRY_CODE_BB",
  19: "COUNTRY_CODE_BD",
  20: "COUNTRY_CODE_BE",
  21: "COUNTRY_CODE_BF",
  22: "COUNTRY_CODE_BG",
  23: "COUNTRY_CODE_BH",
  24: "COUNTRY_CODE_BI",
  25: "COUNTRY_CODE_BJ",
  26: "COUNTRY_CODE_BL",
  27: "COUNTRY_CODE_BM",
  28: "COUNTRY_CODE_BN",
  29: "COUNTRY_CODE_BO",
  30: "COUNTRY_CODE_BQ",
  31: "COUNTRY_CODE_BR",
  32: "COUNTRY_CODE_BS",
  33: "COUNTRY_CODE_BT",
  34: "COUNTRY_CODE_BV",
  35: "COUNTRY_CODE_BW",
  36: "COUNTRY_CODE_BY",
  37: "COUNTRY_CODE_BZ",
  38: "COUNTRY_CODE_CA",
  39: "COUNTRY_CODE_CC",
  40: "COUNTRY_CODE_CD",
  41: "COUNTRY_CODE_CF",
  42: "COUNTRY_CODE_CG",
  43: "COUNTRY_CODE_CH",
  44: "COUNTRY_CODE_CI",
  45: "COUNTRY_CODE_CK",
  46: "COUNTRY_CODE_CL",
  47: "COUNTRY_CODE_CM",
  48: "COUNTRY_CODE_CN",
  49: "COUNTRY_CODE_CO",
  50: "COUNTRY_CODE_CR",
  51: "COUNTRY_CODE_CU",
  52: "COUNTRY_CODE_CV",
  53: "COUNTRY_CODE_CW",
  54: "COUNTRY_CODE_CX",
  55: "COUNTRY_CODE_CY",
  56: "COUNTRY_CODE_CZ",
  57: "COUNTRY_CODE_DE",
  58: "COUNTRY_CODE_DJ",
  59: "COUNTRY_CODE_DK",
  60: "COUNTRY_CODE_DM",
  61: "COUNTRY_CODE_DO",
  62: "COUNTRY_CODE_DZ",
  63: "COUNTRY_CODE_EC",
  64: "COUNTRY_CODE_EE",
  65: "COUNTRY_CODE_EG",
  66: "COUNTRY_CODE_EH",
  67: "COUNTRY_CODE_ER",
  68: "COUNTRY_CODE_ES",
  69: "COUNTRY_CODE_ET",
  70: "COUNTRY_CODE_FI",
  71: "COUNTRY_CODE_FJ",
  72: "COUNTRY_CODE_FK",
  73: "COUNTRY_CODE_FM",
  74: "COUNTRY_CODE_FO",
  75: "COUNTRY_CODE_FR",
  76: "COUNTRY_CODE_GA",
  77: "COUNTRY_CODE_GB",
  78: "COUNTRY_CODE_GD",
  79: "COUNTRY_CODE_GE",
  80: "COUNTRY_CODE_GF",
  81: "COUNTRY_CODE_GG",
  82: "COUNTRY_CODE_GH",
  83: "COUNTRY_CODE_GI",
  84: "COUNTRY_CODE_GL",
  85: "COUNTRY_CODE_GM",
  86: "COUNTRY_CODE_GN",
  87: "COUNTRY_CODE_GP",
  88: "COUNTRY_CODE_GQ",
  89: "COUNTRY_CODE_GR",
  90: "COUNTRY_CODE_GS",
  91: "COUNTRY_CODE_GT",
  92: "COUNTRY_CODE_GU",
  93: "COUNTRY_CODE_GW",
  94: "COUNTRY_CODE_GY",
  95: "COUNTRY_CODE_HK",
  96: "COUNTRY_CODE_HM",
  97: "COUNTRY_CODE_HN",
  98: "COUNTRY_CODE_HR",
  99: "COUNTRY_CODE_HT",
  100: "COUNTRY_CODE_HU",
  101: "COUNTRY_CODE_ID",
  102: "COUNTRY_CODE_IE",
  103: "COUNTRY_CODE_IL",
  104: "COUNTRY_CODE_IM",
  105: "COUNTRY_CODE_IN",
  106: "COUNTRY_CODE_IO",
  107: "COUNTRY_CODE_IQ",
  108: "COUNTRY_CODE_IR",
  109: "COUNTRY_CODE_IS",
  110: "COUNTRY_CODE_IT",
  111: "COUNTRY_CODE_JE",
  112: "COUNTRY_CODE_JM",
  113: "COUNTRY_CODE_JO",
  114: "COUNTRY_CODE_JP",
  115: "COUNTRY_CODE_KE",
  116: "COUNTRY_CODE_KG",
  117: "COUNTRY_CODE_KH",
  118: "COUNTRY_CODE_KI",
  119: "COUNTRY_CODE_KM",
  120: "COUNTRY_CODE_KN",
  121: "COUNTRY_CODE_KP",
  122: "COUNTRY_CODE_KR",
  123: "COUNTRY_CODE_KW",
  124: "COUNTRY_CODE_KY",
  125: "COUNTRY_CODE_KZ",
  126: "COUNTRY_CODE_LA",
  127: "COUNTRY_CODE_LB",
  128: "COUNTRY_CODE_LC",
  129: "COUNTRY_CODE_LI",
  130: "COUNTRY_CODE_LK",
  131: "COUNTRY_CODE_LR",
  132: "COUNTRY_CODE_LS",
  133: "COUNTRY_CODE_LT",
  134: "COUNTRY_CODE_LU",
  135: "COUNTRY_CODE_LV",
  136: "COUNTRY_CODE_LY",
  137: "COUNTRY_CODE_MA",
  138: "COUNTRY_CODE_MC",
  139: "COUNTRY_CODE_MD",
  140: "COUNTRY_CODE_ME",
  141: "COUNTRY_CODE_MF",
  142: "COUNTRY_CODE_MG",
  143: "COUNTRY_CODE_MH",
  144: "COUNTRY_CODE_MK",
  145: "COUNTRY_CODE_ML",
  146: "COUNTRY_CODE_MM",
  147: "COUNTRY_CODE_MN",
  148: "COUNTRY_CODE_MO",
  149: "COUNTRY_CODE_MP",
  150: "COUNTRY_CODE_MQ",
  151: "COUNTRY_CODE_MR",
  152: "COUNTRY_CODE_MS",
  153: "COUNTRY_CODE_MT",
  154: "COUNTRY_CODE_MU",
  155: "COUNTRY_CODE_MV",
  156: "COUNTRY_CODE_MW",
  157: "COUNTRY_CODE_MX",
  158: "COUNTRY_CODE_MY",
  159: "COUNTRY_CODE_MZ",
  160: "COUNTRY_CODE_NA",
  161: "COUNTRY_CODE_NC",
  162: "COUNTRY_CODE_NE",
  163: "COUNTRY_CODE_NF",
  164: "COUNTRY_CODE_NG",
  165: "COUNTRY_CODE_NI",
  166: "COUNTRY_CODE_NL",
  167: "COUNTRY_CODE_NO",
  168: "COUNTRY_CODE_NP",
  169: "COUNTRY_CODE_NR",
  170: "COUNTRY_CODE_NU",
  171: "COUNTRY_CODE_NZ",
  172: "COUNTRY_CODE_OM",
  173: "COUNTRY_CODE_PA",
  174: "COUNTRY_CODE_PE",
  175: "COUNTRY_CODE_PF",
  176: "COUNTRY_CODE_PG",
  177: "COUNTRY_CODE_PH",
  178: "COUNTRY_CODE_PK",
  179: "COUNTRY_CODE_PL",
  180: "COUNTRY_CODE_PM",
  181: "COUNTRY_CODE_PN",
  182: "COUNTRY_CODE_PR",
  183: "COUNTRY_CODE_PS",
  184: "COUNTRY_CODE_PT",
  185: "COUNTRY_CODE_PW",
  186: "COUNTRY_CODE_PY",
  187: "COUNTRY_CODE_QA",
  188: "COUNTRY_CODE_RE",
  189: "COUNTRY_CODE_RO",
  190: "COUNTRY_CODE_RS",
  191: "COUNTRY_CODE_RU",
  192: "COUNTRY_CODE_RW",
  193: "COUNTRY_CODE_SA",
  194: "COUNTRY_CODE_SB",
  195: "COUNTRY_CODE_SC",
  196: "COUNTRY_CODE_SD",
  197: "COUNTRY_CODE_SE",
  198: "COUNTRY_CODE_SG",
  199: "COUNTRY_CODE_SH",
  200: "COUNTRY_CODE_SI",
  201: "COUNTRY_CODE_SJ",
  202: "COUNTRY_CODE_SK",
  203: "COUNTRY_CODE_SL",
  204: "COUNTRY_CODE_SM",
  205: "COUNTRY_CODE_SN",
  206: "COUNTRY_CODE_SO",
  207: "COUNTRY_CODE_SR",
  208: "COUNTRY_CODE_SS",
  209: "COUNTRY_CODE_ST",
  210: "COUNTRY_CODE_SV",
  211: "COUNTRY_CODE_SX",
  212: "COUNTRY_CODE_SY",
  213: "COUNTRY_CODE_SZ",
  214: "COUNTRY_CODE_TC",
  215: "COUNTRY_CODE_TD",
  216: "COUNTRY_CODE_TF",
  217: "COUNTRY_CODE_TG",
  218: "COUNTRY_CODE_TH",
  219: "COUNTRY_CODE_TJ",
  220: "COUNTRY_CODE_TK",
  221: "COUNTRY_CODE_TL",
  222: "COUNTRY_CODE_TM",
  223: "COUNTRY_CODE_TN",
  224: "COUNTRY_CODE_TO",
  225: "COUNTRY_CODE_TR",
  226: "COUNTRY_CODE_TT",
  227: "COUNTRY_CODE_TV",
  228: "COUNTRY_CODE_TW",
  229: "COUNTRY_CODE_TZ",
  230: "COUNTRY_CODE_UA",
  231: "COUNTRY_CODE_UG",
  232: "COUNTRY_CODE_UM",
  233: "COUNTRY_CODE_US",
  234: "COUNTRY_CODE_UY",
  235: "COUNTRY_CODE_UZ",
  236: "COUNTRY_CODE_VA",
  237: "COUNTRY_CODE_VC",
  238: "COUNTRY_CODE_VE",
  239: "COUNTRY_CODE_VG",
  240: "COUNTRY_CODE_VI",
  241: "COUNTRY_CODE_VN",
  242: "COUNTRY_CODE_VU",
  243: "COUNTRY_CODE_WF",
  244: "COUNTRY_CODE_WS",
  245: "COUNTRY_CODE_YE",
  246: "COUNTRY_CODE_YT",
  247: "COUNTRY_CODE_ZA",
  248: "COUNTRY_CODE_ZM",
  249: "COUNTRY_CODE_ZW",
};

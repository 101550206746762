import { ListBankAccountsRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/bankaccount/v1/bank_account_pb";
import { Paging } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import providers from "../providers";
import { bankAccountMapper } from "./mapper";

const list = async (params) => {
  const { BankAccounts } = providers;
  const request = new ListBankAccountsRequest();

  if (params.pagination) {
    const { page, perPage } = params.pagination;
    const pagination = new Paging();
    pagination.setFrom((page - 1) * perPage);
    pagination.setSize(perPage);
    request.setPaging(pagination);
  }

  const response = await BankAccounts.listBankAccounts(request, {
    authorization: localStorage.getItem("desana-tkn"),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  return {
    total: response.getCount(),
    data: response.getResultsList().map((r) => bankAccountMapper(r)),
  };
};

export default list;

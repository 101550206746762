import {
  Card,
  CardContent,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { NumberInput, SelectInput, required } from "react-admin";
import { useFormState } from "react-final-form";
import { currencyOptions } from "../../organisations/getAccountFields";
import { getHourlyFromFullPrice } from "../../ra-data-desana/resources/mappers/rates";

const validatePricePerHour = [required()];

const DurationBasedPricing = () => {
  const { values } = useFormState();

  return (
    <>
      <Box display={{ md: "block", lg: "flex" }} fullWidth>
        <Box
          flex={6}
          display={{ md: "block", lg: "block" }}
          mr={{ md: 0, lg: "1em" }}
        >
          <Typography variant="h6">Hourly rate</Typography>
          <Typography variant="body2">
            This will be used on bookings less than{" "}
            {values?.rates?.halfDayDuration} unless the calculated half day rate
            multiplied by the number of hours is cheaper.
          </Typography>
          <Box>
            <NumberInput
              source="rates.base"
              label="Base hourly rate"
              helperText="In the currency defined below"
              inputProps={{
                step: 0.05,
              }}
              validate={validatePricePerHour}
              fullWidth
              disabled
            />
          </Box>
          {values?.rates?.halfDayFullPrice ? (
            <>
              <Divider style={{ marginBottom: "1em", marginTop: "1em" }} />
              <Box>
                <Typography variant="h6">Half day price (optional)</Typography>
                <Typography variant="body2">
                  This will be used on bookings between{" "}
                  {values?.rates?.halfDayDuration} hours and{" "}
                  {values?.rates?.fullDayDuration} hours unless the calculated
                  full day rate multiplied by the full day duration is cheaper.
                </Typography>
                <Box>
                  <NumberInput
                    source="rates.halfDayDuration"
                    label="Half day duration (Hourly)"
                    defaultValue={0}
                    inputProps={{
                      step: 0.05,
                    }}
                    fullWidth
                    disabled
                  />
                  <NumberInput
                    source="rates.halfDayFullPrice"
                    label="Half day price"
                    helperText="Set as 0 if not applicable."
                    defaultValue={0}
                    inputProps={{
                      step: 0.05,
                    }}
                    validate={validatePricePerHour}
                    fullWidth
                    disabled
                  />
                  <Typography variant="body1">
                    Equivalent hourly rate:{" "}
                    {getHourlyFromFullPrice(
                      values?.rates?.halfDayFullPrice,
                      values?.rates?.halfDayDuration
                    )}{" "}
                    (Rounded to 2 decimal places)
                  </Typography>
                </Box>
              </Box>
            </>
          ) : undefined}
          {values?.rates?.fullDayFullPrice ? (
            <>
              <Divider style={{ marginBottom: "1em" }} />
              <Box>
                <Typography variant="h6">Full day price (optional)</Typography>
                <Typography variant="body2">
                  This will be used on bookings lasting{" "}
                  {values?.rates?.fullDayDuration} hours or longer.
                </Typography>
                <NumberInput
                  source="rates.fullDayDuration"
                  label="Full day duration (Hourly)"
                  defaultValue={0}
                  inputProps={{
                    step: 0.05,
                  }}
                  fullWidth
                  disabled
                />
                <NumberInput
                  source="rates.fullDayFullPrice"
                  label="Full day price"
                  helperText="Set as 0 if not applicable."
                  defaultValue={0}
                  inputProps={{
                    step: 0.05,
                  }}
                  validate={validatePricePerHour}
                  fullWidth
                  disabled
                />

                <Typography variant="body1">
                  Equivalent hourly rate:{" "}
                  {getHourlyFromFullPrice(
                    values?.rates?.fullDayFullPrice,
                    values?.rates?.fullDayDuration
                  )}{" "}
                  (Rounded to 2 decimal places)
                </Typography>
              </Box>
            </>
          ) : undefined}
          <Divider style={{ marginBottom: "1em", marginTop: "1em" }} />
          <Box>
            <Typography variant="h6">Price Cap (optional)</Typography>
            <Typography variant="body2">
              This will be used as a cost limit for all bookings at this resource.
            </Typography>
            <NumberInput
              source="rates.priceCap"
              label="Price Cap"
              helperText="Set as 0 if not applicable."
              defaultValue={0}
              inputProps={{
                step: 0.05,
              }}
              fullWidth
              disabled
            />
          </Box>
          <Divider style={{ marginBottom: "1em" }} />
          <Typography variant="h6">Currency</Typography>
          <Box>
            <SelectInput
              source="currency"
              label="Currency"
              helperText="This will automatically be set when the bank account details are set"
              choices={currencyOptions}
              defaultValue={20}
              fullWidth
              disabled
            />
          </Box>
        </Box>
        <Box flex={6}>
          {values?.rates?.fullDayFullPrice &&
          values?.rates?.halfDayFullPrice ? (
            <Card display="block" style={{ marginBottom: "1em" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  How does Desana's pricing work?
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Desana uses a tiered pricing system which attempts to give our
                  customers the best value possible. If you do not have tiered
                  pricing, only the hourly rate will be used.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  This example uses a half day duration of 4 hours, and a full
                  day duration of 7 hours
                </Typography>
                <p></p>
                <Typography variant="h6" gutterBottom>
                  Some examples
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Equivalent hourly rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Base hourly rate</TableCell>
                      <TableCell>£6</TableCell>
                      <TableCell>£6</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Half day price</TableCell>
                      <TableCell>£12</TableCell>
                      <TableCell>£3</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Full day price</TableCell>
                      <TableCell>£14</TableCell>
                      <TableCell>£2</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography variant="body2" gutterBottom>
                  <p>
                    If someone books for <b>1 hour</b>, we charge them the{" "}
                    <b>base hourly rate</b> x 1 = £6.
                  </p>
                  <p>
                    If someone books for <b>2 hours</b>, we charge them the{" "}
                    <b>base hourly rate</b> x 2 = £12.
                  </p>
                  <p>
                    If someone books for <b>4 hours</b>, we charge them the{" "}
                    <b>half day hourly rate</b> x 4 = £12.
                  </p>
                  <p>
                    If someone books for <b>7 hours</b>, we charge them the{" "}
                    <b>whole day hourly rate</b> x 7 = £14.
                  </p>
                  <p>
                    <b>Choosing the preferable rate</b>
                  </p>
                  <p>
                    We always make sure that the person is charged whatever's
                    the cheapest for them. So, for example:
                  </p>
                  <p>
                    If someone books for <b>3 hours</b>, we charge them the{" "}
                    <b>half day hourly rate</b> x 4 = £12, rather than the base
                    hourly rate x 3 = £18.
                  </p>
                  <p>
                    If someone books for <b>6 hours</b>, we charge them the{" "}
                    <b>full day hourly rate</b> x 7 = £14, rather than the half
                    day hourly rate x 6 = £18.
                  </p>
                </Typography>
              </CardContent>
            </Card>
          ) : undefined}
        </Box>
      </Box>
    </>
  );
};

export default DurationBasedPricing;

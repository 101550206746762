import { GetBankAccountRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/bankaccount/v1/bank_account_pb";
import providers from "../providers";
import { bankAccountMapper } from "./mapper";

const get = async (params) => {
  const { BankAccounts } = providers;
  const request = new GetBankAccountRequest();
  request.setId(params.id);
  const response = await BankAccounts.getBankAccount(request, {
    authorization: localStorage.getItem("desana-tkn"),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  return {
    data: bankAccountMapper(response.getResult().toObject()),
  };
};

export default get;

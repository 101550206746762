import React from "react";
import { BooleanInput, FormDataConsumer, TextInput } from "react-admin";
import { withStyles, createStyles } from "@material-ui/core/styles";

const daysOfWeek = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};
const styles = (theme) =>
  createStyles({
    toggle: {
      maxWidth: "5em",
    },
    fadeOut: {
      visibility: "hidden",
      opacity: 0,
      transition: "visibility 0s linear 300ms, opacity 300ms",
    },
    fadeIn: {
      visibility: "visible",
      opacity: 1,
      transition: "visibility 0s linear 0s, opacity 300ms",
    },
  });

const timeValidator = (value) => {
  if (!value) {
    return "A time is required";
  }
  if (value.split(":")[1] % 15 !== 0) {
    return "Must be a 15 minute interval";
  }
  return undefined;
};

const closingTimeValidator = (value) => {
  if (value && value === "00:00") {
    return "Must be before midnight";
  }
  return undefined;
};

export const OpeningTimesInput = withStyles(styles)(({ classes }) => (
  <FormDataConsumer key="formDataConsumer">
    {({ formData }) => {
      const form = [];
      for (let weekday = 0; weekday <= 6; weekday++) {
        form.push(
          <BooleanInput
            record={formData}
            source={`openingTimesMap.${weekday}.isOpen`}
            label={daysOfWeek[weekday + 1]}
            key={`toggle${weekday}`}
            className={classes.toggle}
            disabled
          />
        );
        let foundOpeningTime = (formData.openingTimesMap || {})[weekday] || {
          isOpen: false,
        };

        if (!foundOpeningTime.open) {
          foundOpeningTime.open = "09:00";
        }
        if (!foundOpeningTime.close) {
          foundOpeningTime.close = "17:00";
        }

        if (!foundOpeningTime.index) {
          foundOpeningTime.index = weekday + 1;
        }

        form.push(
          <div
            key={`openingTimes${weekday}`}
            className={
              foundOpeningTime.isOpen ? classes.fadeIn : classes.fadeOut
            }
          >
            <TextInput
              type="time"
              label="Open"
              source={`openingTimesMap.${weekday}.open`}
              key={`openTime${weekday}`}
              validate={[timeValidator]}
              inputProps={{
                step: 900,
              }}
              disabled
            />
            <TextInput
              type="time"
              label="Close"
              source={`openingTimesMap.${weekday}.close`}
              key={`closeTime${weekday}`}
              validate={[timeValidator, closingTimeValidator]}
              inputProps={{
                step: 900,
              }}
              disabled
            />
          </div>
        );
      }
      return form;
    }}
  </FormDataConsumer>
));

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import pure from "recompose/pure";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { optimiseImage } from "../common/image-proxy";

const avatarSize = 40;
const userAvatarStyles = {
  avatar: {
    height: avatarSize,
    width: avatarSize,
    margin: 2,
  },
};

const UserAvatar = withStyles(userAvatarStyles)(
  ({ classes, source, nameSource, record }) => {
    const value = get(record, source);
    let profileImage = <AccountCircle className={classes.avatar} />;
    if (value) {
      profileImage = (
        <Avatar
          className={classes.avatar}
          src={optimiseImage(value, {
            size: avatarSize,
          })}
        />
      );
    }

    const name = get(record, nameSource);
    let tooltipText = "";
    if (name) {
      tooltipText = `${name.first} ${name.last}`;
    }

    return <Tooltip title={tooltipText}>{profileImage}</Tooltip>;
  }
);

export default pure(UserAvatar);

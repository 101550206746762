import React from "react";
import {
  Datagrid,
  NumberField,
  SimpleShowLayout,
  Show,
  ArrayField,
  FunctionField,
  TextField,
} from "react-admin";
import SquareImageField from "../components/SquareImageField";
import LocalDateTimeField from "../components/LocalDateTimeField";
import UserProfileQuickPreviewButton from "../components/UserProfileQuickPreviewButton";
import { Typography, Card, Divider } from "@material-ui/core";
import { BookingStatus } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/booking_pb";
import { CurrencyField } from "../components/CurrencyField";

const BookingTitle = ({ record }) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: record.space.timezone,
  };

  return (
    <span>
      Booking{" "}
      {record
        ? `for ${new Date(record.startsAt).toLocaleDateString(
            navigator.locale,
            options
          )} at ${record.space.name}`
        : ""}
    </span>
  );
};

const SpaceDetailsAside = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <Card style={{ width: 280, marginLeft: 20 }}>
      <SimpleShowLayout record={record}>
        <Typography variant="h6" gutterBottom>
          {record.space.name}
        </Typography>
        <SquareImageField
          size="m"
          record={record}
          source="space.image"
          label=""
          sortable={false}
        />

        <LocalDateTimeField
          record={record}
          source="startsAt"
          timezoneSource="space.timezone"
          label="Date"
          showDate={true}
          showTime={false}
          locales={navigator.language}
        />
        <FunctionField
          label="Available?"
          render={({ status }) =>
            status === BookingStatus.BOOKING_STATUS_AVAILABLE ? (
              <TextField
                source="text"
                record={{
                  text: "Open",
                }}
              />
            ) : (
              <TextField
                source="text"
                style={{ fontWeight: "bold" }}
                record={{
                  text: "Closed",
                }}
              />
            )
          }
        />
        <LocalDateTimeField
          record={record}
          source="startsAt"
          timezoneSource="space.timezone"
          label="Opening time"
          showDate={false}
          showTime
          locales={navigator.language}
          options={{ hour: "2-digit", minute: "2-digit" }}
        />
        <LocalDateTimeField
          record={record}
          source="endsAt"
          timezoneSource="space.timezone"
          label="Closing time"
          showDate={false}
          showTime
          locales={navigator.language}
          options={{ hour: "2-digit", minute: "2-digit" }}
        />
        <Divider />
        <NumberField
          record={record}
          source="capacity"
          label="Number of desks available"
        />
        <Divider />
        <CurrencyField
          label="Price per hour per booking"
          source="costPerHour"
          spaceId={record.space?.id}
          addLabel={true}
        />
        <CurrencyField
          label="Total estimated payout"
          source="totalCost"
          spaceId={record.space?.id}
          addLabel={true}
          style={{ fontWeight: "bold" }}
        />
      </SimpleShowLayout>
    </Card>
  );
};

const memberRowStyle = (record, index) => ({
  textDecoration: record.cancelledAt ? "line-through" : "none",
});

const show = (props) => (
  <Show {...props} title={<BookingTitle />} aside={<SpaceDetailsAside />}>
    <SimpleShowLayout>
      <Typography variant="h6">Attendees</Typography>
      <ArrayField
        source="membersList"
        label="Attendees (an attendee has cancelled if their details are scored out below)"
      >
        <Datagrid rowStyle={memberRowStyle}>
          <UserProfileQuickPreviewButton
            source="user"
            sortable={false}
            label=""
          />
          <LocalDateTimeField
            source="startsAt"
            label="Arriving at"
            showTime
            showDate={false}
            sortable={false}
            timezoneSource="timezone"
            options={{ hour: "2-digit", minute: "2-digit" }}
          />
          <LocalDateTimeField
            source="endsAt"
            label="Leaving at"
            showTime
            showDate={false}
            sortable={false}
            timezoneSource="timezone"
            options={{ hour: "2-digit", minute: "2-digit" }}
          />

          <FunctionField
            label="Duration"
            render={(record) => bookingHoursToHoursMinutes(record)}
          />
          <CurrencyField
            source="cost"
            label="Estimated payout"
            spaceIdSource="space.id"
          />
          <FunctionField
            label="Status"
            render={(record) => {
              let statusText = "Confirmed";

              if (!record.cancelledAt) {
                return (
                  <TextField
                    source="text"
                    record={{ text: statusText }}
                    style={{
                      fontWeight: "bold",
                      color: "#008800",
                    }}
                  />
                );
              }

              statusText = "Cancelled";

              let RefundedText = () => (
                <span
                  style={{ textDecoration: "none", display: "inline-block" }}
                >
                  You were still paid
                </span>
              );
              if (record.refunded) {
                RefundedText = () => (
                  <span
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    You were not paid
                  </span>
                );
              }

              return (
                <>
                  <TextField
                    source="text"
                    record={{ text: statusText }}
                    style={{
                      display: "block",
                      float: "left",
                      paddingRight: "10px",
                      fontWeight: "bold",
                      color: "#cc0000",
                    }}
                  />
                  <RefundedText />
                </>
              );
            }}
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const bookingHoursToHoursMinutes = (booking) => {
  const mins = booking.hours * 60;
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  let composed = "";
  if (h > 0) {
    composed = `${composed} ${h} ${h === 1 ? "hour" : "hours"}`;
  }

  if (m > 0) {
    composed = `${composed} ${m} ${m === 1 ? "minute" : "minutes"}`;
  }
  return composed;
};

export default show;

const SIX_HOURS_IN_MSEC = Object.freeze(1000 * 60 * 60 * 6);

export const bankAccountMapper = (bankAccount) => {
  if (!bankAccount.createdAt) {
    bankAccount.editable = false;
    return bankAccount;
  }

  const createdAtDate = new Date(bankAccount.createdAt);

  if (+createdAtDate < new Date() - SIX_HOURS_IN_MSEC) {
    bankAccount.editable = false;
  } else {
    bankAccount.editable = true;
  }

  return bankAccount;
};

import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Loading } from "react-admin";

const styles = {
  tooltipLabel: {
    fontFamily:
      "Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif",
  },
};

const SpaceViewsChart = ({ data, loading, error }) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR, {error.message}</p>;
  }
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        nameKey="name"
        margin={{ top: 0, right: 0, bottom: 40, left: 10 }}
        fontFamily="Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif"
      >
        <CartesianGrid stroke="#ccc" vertical={false} />
        <XAxis
          dataKey="name"
          tickFormatter={(value) =>
            new Date(value).toLocaleDateString(navigator.language, {
              day: "2-digit",
            })
          }
          tickLine={false}
          minTickGap={10}
          axisLine={{ strokeWidth: 2 }}
          fontSize={13}
        />
        <YAxis
          label={{ value: "Views", angle: 90 }}
          axisLine={false}
          orientation="right"
          tickLine={false}
          fontSize={13}
          width={70}
        />
        <Tooltip
          formatter={(value) => [value, "Views"]}
          separator=": "
          labelFormatter={(value) => new Date(value).toLocaleDateString()}
          labelStyle={styles.tooltipLabel}
        />
        <Line
          type="monotone"
          dataKey="count"
          label="Views"
          stroke="#6092C0"
          strokeWidth={3}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SpaceViewsChart;

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export const Empty = ({ withContactButton, resourceLabel }) => {
  const resourceName = resourceLabel.toLowerCase();

  return (
    <Box textAlign="center" m={8} display="flex" flexDirection="column">
      <Typography variant="h4" paragraph>
        No {resourceName} yet 😔
      </Typography>
      {withContactButton && (
        <div display="block">
          <Typography variant="body1">
            But you can always contact us to add them 🎉
          </Typography>
          <Box mt="1em">
            <Button
              color="primary"
              size="medium"
              variant="outlined"
              onClick={() =>
                window.Intercom(
                  "showNewMessage",
                  `I'd like to add my ${resourceName} to Desana. How can I do this?`
                )
              }
            >
              Message Us
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
};

import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import IconMessage from "@material-ui/icons/MessageOutlined";
import React from "react";
import {
  ArrayInput,
  Button,
  Edit,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TabbedFormTabs,
  TextField,
  TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { imageFieldFormatter } from "../common/image-field-formatter";
import { optimiseImage } from "../common/image-proxy";
import SaveOnlyToolbar from "../components/SaveOnlyToolbar";
import { getSpacesForSpaceRole, hasPermission } from "../utils/auth";
import {
  countryOptions,
  currencyOptions,
  getAccountFields,
} from "./getAccountFields";
import OrganisationAdminInput from "./OrganisationAdminInput";

const customStyles = makeStyles({
  input: {
    display: "block",
    '& div': { width: "400px" }
  },
});

const Aside = () => (
  <div style={{ maxWidth: 200, margin: "1em" }}>
    <Typography variant="h6">Which information will workers see?</Typography>
    <br />
    <Typography variant="body2">
      Workers will be able to see all the information under the "Details" tab.
      Please provide a little bit of information about your organisation,
      including your organisation's logo.
    </Typography>
  </div>
);

const requiredValidator = required();
const taxRateValidator = [minValue(0), maxValue(100)];

const OrganisationEdit = ({ redirect, permissions, ...props }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMenuOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  const menuWidth = isXSmall ? 0 : isMenuOpen ? 255 : 70;
  const asideWidth = isSmall ? 0 : 215;
  const asideMargin = isSmall ? `` : " - 2em";

  const offset = ` - ${menuWidth + asideWidth}px${asideMargin}`;

  return (
    <Edit
      undoable={false}
      title="Your Organisation Details"
      aside={isSmall ? null : <Aside />}
      {...props}
    >
      <TabbedForm
        toolbar={<SaveOnlyToolbar />}
        tabs={
          <TabbedFormTabs
            variant="scrollable"
            style={{
              width: `calc(100vw${offset})`,
            }}
          />
        }
        redirect={false}
      >
        {hasPermission(
          permissions,
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_DETAILS
        ) ? (
          <FormTab label="Details">
            <ImageInput
              source="profileImage"
              label="Organisation logo"
              accept="image/*"
              multiple={false}
              labelSingle="Drop a logo to upload or click to select one. ENSURE LOGOS ARE SQUARE!"
            >
              <FunctionField
                render={(record) => {
                  if (record.rawFile) {
                    return <ImageField record={record} />;
                  }
                  const optimisedRecord = imageFieldFormatter(record);
                  return <ImageField record={optimisedRecord} source="image" />;
                }}
              />
            </ImageInput>
            <TextInput source="name" validate={requiredValidator} />
            <TextInput source="hometown" />
            <TextInput multiline fullWidth source="bio" />
          </FormTab>
        ) : (
          <FormTab label="Details">
            <FunctionField
              label="Image"
              render={(record) => (
                <ImageField
                  source="profileImage"
                  record={{
                    profileImage: optimiseImage(record.profileImage.image, {
                      size: 160,
                    }),
                  }}
                />
              )}
            />
            <TextField source="name" />
            <TextField source="hometown" />
            <TextField fullWidth source="bio" />
          </FormTab>
        )}
        {hasPermission(
          permissions,
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_ADMINS
        ) ? (
          <FormTab label="Admin Users">
            <ArrayInput source="adminsList" label="">
              <OrganisationAdminInput />
            </ArrayInput>
          </FormTab>
        ) : (
          hasPermission(
            permissions,
            OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_ADMINS
          ) && (
            <FormTab label="Admin Users">
              <ArrayInput source="adminsList" disabled label="">
                <OrganisationAdminInput disabled />
              </ArrayInput>
            </FormTab>
          )
        )}
        {hasPermission(
          permissions,
          OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYMENT_DETAILS,
          SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_PAYMENT_DETAILS
        ) && (
            <FormTab label="Bank Details">
              <BankDetailsInput permissions={permissions} />
            </FormTab>
          )}
      </TabbedForm>
    </Edit>
  );
};

const BankDetailsInput = ({ record, permissions }) => {
  const classes = customStyles();

  const canAddAndRemoveBankAccounts =
    record?.bankAccountsEditable &&
    hasPermission(
      permissions,
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_PAYMENT_DETAILS
    );

  const hasAccessToAllSpacesOrSomeSpaces = getSpacesForSpaceRole(permissions, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_PAYMENT_DETAILS,
  ]);

  const hasAccessToAllSpaces = hasAccessToAllSpacesOrSomeSpaces === true;

  return (
    <>
      {!canAddAndRemoveBankAccounts && <ChangeBankDetails />}
      <ArrayInput
        source="bankAccountsList"
        label=""
        disabled={!canAddAndRemoveBankAccounts}
      >
        <SimpleFormIterator
          disableAdd={!canAddAndRemoveBankAccounts}
          disableRemove={!canAddAndRemoveBankAccounts}
        >
          <TextInput
            source="companyName"
            label="Registered Company Name"
            validate={requiredValidator}
            className={classes.input}
          />
          <TextInput
            source="bankName"
            label="Bank Name"
            validate={requiredValidator}
            className={classes.input}
          />
          <SelectInput
            source="country"
            label="Country"
            choices={countryOptions}
            validate={requiredValidator}
            defaultValue={77}
            className={classes.input}
          />
          <SelectInput
            source="currency"
            label="Currency"
            validate={requiredValidator}
            choices={currencyOptions}
            defaultValue={20}
            className={classes.input}
          />
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              if (scopedFormData?.country === 77) {
                return (
                  <TextInput
                    source={getSource("vatNumber")}
                    label="VAT Number (optional)"
                    helperText="Only required for UK spaces"
                    disabled={!canAddAndRemoveBankAccounts}
                    className={classes.input}
                  />
                );
              }
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              if (scopedFormData?.country === 77) {
                return (
                  <NumberInput
                    source={getSource("taxRate")}
                    label="Tax Rate (%)"
                    validate={taxRateValidator}
                    defaultValue={0}
                    disabled={!canAddAndRemoveBankAccounts}
                    className={classes.input}
                  />
                );
              }
            }}
          </FormDataConsumer>
          <FormDataConsumer
            label={
              hasAccessToAllSpaces
                ? "Specific to space: (optional)"
                : "Specific to space:"
            }
          >
            {({ formData, scopedFormData, getSource, ...rest }) => (
              <ReferenceInput
                source={getSource("spaceId")}
                reference="spaces"
                filter={{ owner: { id: [formData.id] } }}
                perPage={100}
                allowEmpty={hasAccessToAllSpaces}
                className={classes.input}
                {...rest}
              >
                <SelectInput
                  optionText="name"
                  required={!hasAccessToAllSpaces}
                  className={classes.input}
                />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer label="Account details">
            {getAccountFields(classes)}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

const ChangeBankDetails = () => {
  const { values } = useFormState();

  if (!values?.name || !values?.id) {
    return null;
  }

  return (
    <Box component="span" m={2}>
      <Card mt={2}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Want to change your bank details?
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you'd like to change your bank details, please get in touch with
            us:
          </Typography>
          <Button
            label="Change Bank Details"
            alignIcon="right"
            color="primary"
            variant="outlined"
            onClick={() =>
              window.Intercom(
                "showNewMessage",
                `I'd like to change my bank details for my organisation "${values.name}" (${values.id}) to:\n\n[ENTER CHANGES HERE]`
              )
            }
            style={{ marginTop: "20px" }}
          >
            <IconMessage />
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OrganisationEdit;

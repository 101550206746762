import React, { Component } from "react";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import {
  Button,
  SimpleShowLayout,
  Query,
  Loading,
  ImageField,
} from "react-admin";
import FullNameField from "./FullNameField";
import LocalDateTimeField from "./LocalDateTimeField";
import { optimiseImage } from "../common/image-proxy";
import UserAvatar from "./UserAvatar";

const styles = (theme) => ({
  field: {
    paddingLeft: 16,
    paddingRight: 16,
    "& span": {
      display: "inline-block",
      maxWidth: "30em",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  name: {
    padding: 16,
  },
  drawer: {
    width: "25em",
  },
  details: {
    padding: 0,
    "& img": {
      width: "400px",
      margin: 0,
      height: "300px",
      maxHeight: "300px",
      objectFit: "cover",
    },
  },
});

const UserPreviewView = ({ classes, record, ...props }) => {
  return (
    <Query type="GET_ONE" resource="users" payload={{ id: record.id }}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <p>Error loading user profile...</p>;
        }

        if (data.profileImage) {
          data.profileImage = optimiseImage(data.profileImage, {
            width: 400,
            height: 300,
          });
        }
        return (
          <SimpleShowLayout
            {...props}
            record={data}
            className={classes.details}
          >
            <ImageField source="profileImage" label="" sortable={false} />
            <FullNameField
              source="name"
              className={classes.name}
              variant="h6"
            />
            <LocalDateTimeField
              source="createdAt"
              label="desana.userProfile.memberSince"
              className={classes.field}
              timezoneSource={navigator.locales}
              showDate={true}
              showTime={false}
              locales={navigator.language}
              options={{ year: "numeric", month: "long" }}
            />
          </SimpleShowLayout>
        );
      }}
    </Query>
  );
};

const UserPreview = connect(null, {})(withStyles(styles)(UserPreviewView));

class UserProfileQuickPreviewButton extends Component {
  state = { showPanel: false };
  classes = {};

  constructor(props) {
    super(props);
    this.classes = props.classes;
  }

  handleClick = () => {
    this.setState({ showPanel: true });
  };

  handleCloseClick = () => {
    this.setState({ showPanel: false });
  };

  render() {
    const { showPanel } = this.state;
    const { source, record, classes } = this.props;
    if (!record || !record[source]) {
      return "User profile unavailable";
    }

    const userName = `${record[source]?.name?.first || ""} ${
      record[source]?.name?.last || ""
    }`;

    return (
      <>
        <Button onClick={this.handleClick} label={userName}>
          <UserAvatar
            record={record}
            source={`${source}.profileImage`}
            label=""
            nameSource={null}
            sortable={false}
          />
        </Button>
        <Drawer anchor="right" open={showPanel} onClose={this.handleCloseClick}>
          <div className={classes.drawer}>
            <Button label="Close" onClick={this.handleCloseClick}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <UserPreview record={record[source]} />
        </Drawer>
      </>
    );
  }
}

export default connect()(withStyles(styles)(UserProfileQuickPreviewButton));

import { Buffer } from "buffer";
import hash from "hash.js";

const PROXY_SERVICE_URL = import.meta.env.VITE_IMAGE_PROXY_SERVICE_URL;
const SALT = import.meta.env.VITE_IMAGE_PROXY_SALT;
const SECRET = import.meta.env.VITE_IMAGE_PROXY_SECRET as string;

function isDesanaAsset(url) {
  return !!url.match(/^https:\/\/(user-images|staging-user-images).desana.io/);
}

const urlSafeBase64 = (string: string) => {
  return Buffer.from(string)
    .toString("base64")
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

const hexDecode = (hex) => Buffer.from(hex, "hex");

const secret = hexDecode(SECRET);
const salt = hexDecode(SALT);

function sign(path) {
  // @ts-ignore
  const hmac = hash.hmac(hash.sha256, hexDecode(SECRET));
  hmac.update(hexDecode(SALT));
  hmac.update(path);
  // @ts-ignore
  return urlSafeBase64(hmac.digest());
}

function prepareUrl(url) {
  const assetUrl = url.replace("https", "gs");

  return urlSafeBase64(assetUrl);
}

function getSizes({ width, height, size = 50 }) {
  // Size is multiplied by DPR, to upscale for high density displays
  // Round so that we don't get lots of decimal places.
  function getSize(axis) {
    return Math.round((axis || size) * (window.devicePixelRatio || 1));
  }

  return {
    width: getSize(width),
    height: getSize(height),
  };
}

/**
 * Optimise an image, by proxying it through our image service.
 * Internally this uses https://imgproxy.net/.
 *
 * This converter function is based on this example:
 * https://github.com/imgproxy/imgproxy/blob/master/examples/signature.js
 */
export function optimiseImage(url, options = {}) {
  if (typeof url !== "string") return "";

  // We only optimise known Desana assets
  if (!isDesanaAsset(url)) {
    return url;
  }

  // Resizes the image while keeping aspect ratio to fill given size
  const resizeType = "fill";

  // Size of the optimised image
  // @ts-ignore
  const { width, height } = getSizes(options);

  // When cropping is required, focus center of image
  const gravity = "ce";

  // If image is smaller than width/height options, upscale it
  const enlarge = true;

  const encodedUrl = prepareUrl(url);
  const path = `/rs:${resizeType}/w:${width}/h:${height}/g:${gravity}/el:${enlarge}/${encodedUrl}`;

  return `${PROXY_SERVICE_URL}/${sign(path)}${path}`;
}

import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";

const ROLE_TYPE = Object.freeze({
  VIEW: "View",
  EDIT: "Edit",
});

const ORGANINSATION_ROLE_MAPPINGS = Object.freeze([
  {
    name: "Organisation Details",
    roleHierarchy: [
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_DETAILS,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Spaces",
    roleHierarchy: [
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_SPACES,
        type: ROLE_TYPE.VIEW,
      },
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_SPACES,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Bookings (Choose edit to enable respond to bookings)",
    roleHierarchy: [
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_BOOKINGS,
        type: ROLE_TYPE.VIEW,
      },
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_BOOKINGS,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Organisation Admins",
    roleHierarchy: [
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_ADMINS,
        type: ROLE_TYPE.VIEW,
      },
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_ADMINS,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Payment Details",
    roleHierarchy: [
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYMENT_DETAILS,
        type: ROLE_TYPE.VIEW,
      },
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_PAYMENT_DETAILS,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Payouts",
    roleHierarchy: [
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYOUTS,
        type: ROLE_TYPE.VIEW,
      },
    ],
  },
  {
    name: "Analytics",
    roleHierarchy: [
      {
        role: OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_ANALYTICS,
        type: ROLE_TYPE.VIEW,
      },
    ],
  },
]);

const SPACE_ROLE_MAPPINGS = Object.freeze([
  {
    name: "Space",
    roleHierarchy: [
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_DETAILS,
        type: ROLE_TYPE.VIEW,
      },
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_DETAILS,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Bookings (Choose edit to enable respond to bookings)",
    roleHierarchy: [
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
        type: ROLE_TYPE.VIEW,
      },
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Payment Details",
    roleHierarchy: [
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_PAYMENT_DETAILS,
        type: ROLE_TYPE.VIEW,
      },
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_PAYMENT_DETAILS,
        type: ROLE_TYPE.EDIT,
      },
    ],
  },
  {
    name: "Payouts",
    roleHierarchy: [
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_PAYOUTS,
        type: ROLE_TYPE.VIEW,
      },
    ],
  },
  {
    name: "Analytics",
    roleHierarchy: [
      {
        role: SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_ANALYTICS,
        type: ROLE_TYPE.VIEW,
      },
    ],
  },
]);

const getOrganisationDefaultRolesMapping = () =>
  ORGANINSATION_ROLE_MAPPINGS.map((roleTypeToAccessMapping) => ({
    ...roleTypeToAccessMapping,
    currentRole: -1,
  }));

const getSpacesDefaultRolesMapping = () =>
  SPACE_ROLE_MAPPINGS.map((roleTypeToAccessMapping) => ({
    ...roleTypeToAccessMapping,
    currentRole: -1,
  }));

export {
  ROLE_TYPE,
  ORGANINSATION_ROLE_MAPPINGS,
  SPACE_ROLE_MAPPINGS,
  getOrganisationDefaultRolesMapping,
  getSpacesDefaultRolesMapping,
};

import {
  AddAdminsRequest,
  OrganisationAdmin,
  RemoveAdminsRequest,
  UpdateAdminsRequest,
  UpdateOrganisationRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/organisation/v1/organisation_pb";
import { SpaceAdmin } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import {
  NotificationChannel,
  OperatorNotificationPreference,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/notifications_pb";
import { UserMini } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/user_pb";
import { isEqual, isEqualWith } from "lodash";
import { comparisonFunc } from "../../common/ignoreUndefinedAndNullComparisonFn";
import * as storage from "../../utils/storage";
import CreateBankAccount from "../bank-accounts/create";
import DeleteBankAccount from "../bank-accounts/delete";
import UpdateBankAccount from "../bank-accounts/update";
import providers from "../providers";
import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import {
  extractOrganisationRoles,
  extractSpacesRoles,
} from "./admin/extract-roles";
import mapAdmin from "./admin/map-user";
import { getOrganisationDefaultRolesMapping } from "./admin/role-mappings";

export const update = async ({ data, previousData, id }) => {
  const currentOrg = storage.get("currentOrganisation");
  let updatedOrg = currentOrg.organisation;
  let updatedBankAccounts;

  // Organistion details including profile image
  if (
    currentOrg.rolesList &&
    (currentOrg.rolesList.includes(
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER
    ) ||
      currentOrg.rolesList.includes(
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_DETAILS
      ))
  ) {
    updatedOrg = await updateOrganisationDetails({ id, data, previousData });
  }

  // Admins
  if (
    currentOrg.rolesList &&
    (currentOrg.rolesList.includes(
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER
    ) ||
      currentOrg.rolesList.includes(
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_ADMINS
      ))
  ) {
    updatedOrg = await updateAdmins(data, previousData);
  }

  // Payment details
  if (hasPaymentEditPermissions(currentOrg)) {
    updatedBankAccounts = await updateBankAccounts(data, previousData, id);
  }

  currentOrg.organisation = {
    id,
    name: updatedOrg.name,
    profileImage: updatedOrg.profileImge,
  };
  storage.set("currentOrganisation", currentOrg);
  return {
    data: {
      ...updatedOrg,
      bankAccountsList: updatedBankAccounts,
    },
  };
};

const hasPaymentEditPermissions = (currentOrg) => {
  if (
    currentOrg.rolesList &&
    (currentOrg.rolesList.includes(
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER
    ) ||
      currentOrg.rolesList.includes(
        OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_EDIT_PAYMENT_DETAILS
      ))
  ) {
    return true;
  }

  if (!currentOrg.spacesList || currentOrg.spacesList.length === 0) {
    return false;
  }

  return currentOrg.spacesList.some(({ rolesList }) =>
    rolesList.includes(SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_PAYMENT_DETAILS)
  );
};

const updateOrganisationDetails = async ({ id, data, previousData }) => {
  const { Organisations } = providers;
  const request = new UpdateOrganisationRequest();
  request.setId(id);

  if (!isEqual(data.bio, previousData.bio)) {
    request.addFieldsToUpdate("bio");
    request.setBio(data.bio);
  }

  if (!isEqual(data.hometown, previousData.hometown)) {
    request.addFieldsToUpdate("hometown");
    request.setHometown(data.hometown);
  }

  if (!isEqual(data.name, previousData.name)) {
    request.addFieldsToUpdate("name");
    request.setName(data.name);
  }

  if (!isEqual(data.vatNumber, previousData.vatNumber)) {
    request.addFieldsToUpdate("vat_number");
    request.setVatNumber(data.vatNumber);
  }

  let updated = data;
  if (request.getFieldsToUpdateList().length > 0) {
    updated = (
      await Organisations.updateOrganisation(request, {
        authorization: storage.get("desana-tkn", false),
      })
    ).toObject();
  }

  let updateImage = () => previousData.profileImage;
  if (!isEqual(data.profileImage, previousData.profileImage)) {
    updateImage = async () => {
      const formData = new FormData();
      formData.append(`image`, data.profileImage.rawFile);
      const res = await fetch(
        `${providers.imageUploadUrl}/organisations/${id}/profile-image`,
        {
          method: "POST",
          body: formData,
          headers: {
            authorization: storage.get("desana-tkn", false),
          },
        }
      );

      if (!res.ok) {
        throw new Error(await res.text());
      }
      return {
        image: await res.text(),
      };
    };
  }

  const profileImage = await updateImage();

  return { ...(updated.toObject ? updated.toObject() : updated), profileImage };
};

const mapSpaceAdmins = (spacesList) =>
  spacesList.map((s) => {
    const spaceAdmin = new SpaceAdmin();
    spaceAdmin.setId(s.id);

    const rolesList = extractSpacesRoles(s.roleTypeMapping);

    spaceAdmin.setRolesList(rolesList);

    spaceAdmin.setNotificationsList(
      (s.notificationsList || []).map(notificationTypesToDatabase)
    );
    return spaceAdmin;
  });

const updateAdmins = async (data, previousData) => {
  const { Organisations } = providers;
  if (isEqual(data.adminsList, previousData.adminsList)) {
    return previousData;
  }

  const adminsToRemove = [];
  const adminsToAdd = [];
  const adminsToUpdate = [];
  let updatedOrganisation = data;

  // Go through the previous admins and see if they exist now
  for (const previous of previousData.adminsList) {
    const found = data.adminsList.find((a) => a.email === previous.email);
    if (!found) {
      // Remove
      const orgAdmin = new OrganisationAdmin();
      orgAdmin.setEmail(previous.email);
      const user = new UserMini();
      user.setId(previous.user.id);
      orgAdmin.setUser(user);
      adminsToRemove.push(orgAdmin);
      continue;
    }

    if (isEqualWith(previous, found, comparisonFunc)) {
      continue;
    }

    // Update
    const orgAdmin = new UpdateAdminsRequest.OrganisationAdminUpdate();
    orgAdmin.setId(previous.user.id);
    const fieldsToUpdate = [];

    if (found.roleTypeMapping) {
      const rolesList = extractOrganisationRoles(
        found.roleTypeMapping,
        found.isOwner
      );

      orgAdmin.setRolesList(rolesList);
      fieldsToUpdate.push("roles");
    }

    if (found.spacesList) {
      orgAdmin.setSpacesList(mapSpaceAdmins(found.spacesList));
      fieldsToUpdate.push("spaces");
    }
    if (found.notificationsList) {
      orgAdmin.setNotificationsList(
        found.notificationsList.map((n) => notificationTypesToDatabase(n))
      );
      fieldsToUpdate.push("notifications");
    }
    if (fieldsToUpdate.length) {
      orgAdmin.setFieldsToUpdateList(fieldsToUpdate);
      adminsToUpdate.push(orgAdmin);
    }
    continue;
  }

  // Go through the current admins and see if they weren't present before
  for (const current of data.adminsList) {
    const found = previousData.adminsList.find(
      (a) => a.email === current.email
    );

    if (found) {
      continue;
    }
    const orgAdmin = new AddAdminsRequest.AddAdminsEmailsRolesRequest();
    orgAdmin.setEmail(current.email);

    // Merge new roles with default roles
    const rolesList = extractOrganisationRoles(
      current.isOwner
        ? []
        : getOrganisationDefaultRolesMapping().map((roleMapping, index) => ({
            ...roleMapping,
            ...current.roleTypeMapping[index],
          })),
      current.isOwner
    );

    orgAdmin.setRolesList(rolesList);
    orgAdmin.setNotificationsList(
      (current.notificationsList || []).map(notificationTypesToDatabase)
    );
    if (current.spacesList) {
      orgAdmin.setSpacesList(mapSpaceAdmins(current.spacesList));
    }

    adminsToAdd.push(orgAdmin);
  }

  if (adminsToRemove.length > 0) {
    const request = new RemoveAdminsRequest();
    request.setId(data.id);
    request.setAdminsList(adminsToRemove);
    const updated = await Organisations.removeAdmins(request, {
      authorization: localStorage.getItem("desana-tkn"),
    });

    updatedOrganisation = updated.getResult().toObject();
  }

  if (adminsToUpdate.length > 0) {
    const request = new UpdateAdminsRequest();
    request.setId(data.id);
    request.setAdminsList(adminsToUpdate);
    const updated = await Organisations.updateAdmins(request, {
      authorization: localStorage.getItem("desana-tkn"),
    });

    updatedOrganisation = updated.getResult().toObject();
  }

  if (adminsToAdd.length > 0) {
    const request = new AddAdminsRequest();
    request.setId(data.id);
    request.setAdminsList(adminsToAdd);
    const updated = await Organisations.addAdmins(request, {
      authorization: localStorage.getItem("desana-tkn"),
    });

    updatedOrganisation = updated.getResult().toObject();
  }

  return {
    ...updatedOrganisation,
    adminsList: updatedOrganisation.adminsList.map(mapAdmin),
  };
};

const updateBankAccounts = async (data, previousData, id) => {
  if (isEqual(data.bankAccountsList, previousData.bankAccountsList)) {
    return previousData.bankAccountsList;
  }

  // Go through the previous accounts and see if they exist now
  for (const previous of previousData.bankAccountsList) {
    let found = data.bankAccountsList.find((a) => a.id === previous.id);
    if (!found) {
      // Remove
      await DeleteBankAccount(previous);
      continue;
    }

    if (isEqual(previous, found)) {
      continue;
    }

    // Update
    const updated = await UpdateBankAccount({
      data: found,
      previousData: previous,
      id: found.id,
    });
    found = updated;

    continue;
  }

  // Go through the current bank accounts and see if they weren't present before
  for (const current of data.bankAccountsList) {
    const found = previousData.bankAccountsList.find(
      (a) => a.id === current.id
    );

    if (found) {
      continue;
    }
    const created = await CreateBankAccount({
      data: {
        ...current,
        organisationId: data.id,
      },
    });
    current.id = created.data.id;
  }

  return data.bankAccountsList;
};

const notificationTypesToDatabase = (notifications) =>
  new OperatorNotificationPreference()
    .setType(notifications)
    .setChannelsList([
      NotificationChannel.NOTIFICATION_CHANNEL_EMAIL,
      NotificationChannel.NOTIFICATION_CHANNEL_PUSH,
    ]);

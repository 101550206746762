import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const logoIconStyle = {
  avatar: {
    height: 30,
    width: 30
  }
};

const LogoIcon = withStyles(logoIconStyle)(({ classes }) => {
  return (
    <Avatar className={classes.avatar} src="/android-chrome-512x512.png" />
  );
});

export default LogoIcon;

import React from "react";
import { useGetOne, NumberField } from "react-admin";
import { get } from "lodash";
import { currencyOptions } from "../organisations/getAccountFields";

export const CurrencyField = ({
  spaceId,
  spaceIdSource,
  currencySource,
  ...props
}) => {
  if (spaceIdSource && !spaceId) {
    spaceId = get(props.record, spaceIdSource);
  }
  const { data } = useGetOne("spaces", spaceId, {
    enabled: !!spaceId && !currencySource,
  });

  if (!data && !currencySource) {
    return <NumberField {...props} />;
  }

  let currency = "XXX";

  if (currencySource) {
    currency = currencyOptions.find(
      (c) => c.id === get(props.record, currencySource)
    )?.currencyCodeString;
  } else if (data) {
    currency = currencyOptions.find(
      (c) => c.id === data.currency
    )?.currencyCodeString;
  }

  if (!currency) {
    currency = "XXX";
  }

  return (
    <NumberField
      {...props}
      options={{
        style: "currency",
        currency,
      }}
    />
  );
};

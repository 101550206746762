import React from "react";
import get from "lodash/get";
import { Typography } from "@material-ui/core";

const FullNameField = ({ record = {}, source, variant }) => {
  const name = get(record, source);
  if (!name) {
    return <span />;
  }
  return (
    <Typography variant={variant}>{`${name.first} ${name.last}`}</Typography>
  );
};
FullNameField.defaultProps = { label: "Name", variant: "body2" };

export default FullNameField;

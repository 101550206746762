import { useState } from "react";
import { Button, useGetMany } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Chip } from "@material-ui/core";

const AttendeesModal = ({ attendees, maxNumberOfAttendees, open, onClose }) =>
  attendees ? (
    <Dialog open={open} onClose={onClose} aria-label="Show Attendees">
      <DialogTitle style={{ "padding-bottom": 0 }}>Attendees</DialogTitle>
      <DialogContent style={{ "min-width": "300px", "padding-top": 0 }}>
        <DialogContentText>
          <Typography gutterBottom={true}>
            Lead bookers are able to edit this list up to 24 hours before the
            booking. Some attendees may not yet be shown.
          </Typography>
          <Chip 
            variant="outlined"
            label={
              `${attendees.length}/${maxNumberOfAttendees} attending`
            }
          />
          <ul
            style={{
              "text-align": "left",
              color: "black",
              "font-weight": "500",
            }}
          >
            {attendees.map(({ externalName, user }) => {
              const name =
                externalName || `${user.name.first} ${user.name.last}`;
              return <li>{name}</li>;
            })}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button label="Done" onClick={onClose}></Button>
      </DialogActions>
    </Dialog>
  ) : null;

const Attendees = ({ bookingSlot, resource }) => {
  const { data: resourceTypes, loaded } = useGetMany("resourceTypes", [resource?.resourceType?.id], { enabled: resource?.resourceType?.id});
  const resourceType = resourceTypes && resourceTypes.find((rt) => rt?.id === resource?.resourceType?.id);

  const [showAttendees, setShowAttendees] = useState(false);
  const attendeesText = buildNumberOfAttendeesText({
    numberOfAttendees: bookingSlot?.numberOfAttendees
  });
  let maxNumberOfAttendees = bookingSlot?.capacityRange?.hi;
  if (resourceType?.pricingModel?.pricingPerAttendee || resourceType?.concurrent) {
    maxNumberOfAttendees = bookingSlot.numberOfAttendees 
  }

  const onViewClick = (ev) => {
    ev.preventDefault();
    setShowAttendees(true);
  };
  const onAttendeesClose = (ev) => {
    setShowAttendees(false);
  };

  const attendeesSpecified = areAttendeesSpecified(bookingSlot?.attendeesList);

  if (!loaded) {
    return null;
  }
  return (
    <div>
      {!attendeesSpecified && attendeesText}
      {attendeesSpecified && attendeesText && (
        <Button onClick={onViewClick} label={attendeesText} />
      )}
      <AttendeesModal
        open={showAttendees}
        onClose={onAttendeesClose}
        attendees={bookingSlot?.attendeesList}
        maxNumberOfAttendees={maxNumberOfAttendees}
      />
    </div>
  );
};

const buildNumberOfAttendeesText = ({
  numberOfAttendees,
}) => {
  if (!numberOfAttendees) {
    return "Not specified";
  }

  return `${numberOfAttendees} attendee${
    numberOfAttendees > 1 ? "s" : ""
  }`;
};

const areAttendeesSpecified = (attendees) => !!attendees?.length;

export default Attendees;

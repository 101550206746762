import { DeleteBankAccountRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/bankaccount/v1/bank_account_pb";
import providers from "../providers";

const remove = async (params) => {
  const { BankAccounts } = providers;
  let multiple = false;

  let ids = [];
  if (params.id) {
    ids = [params.id];
  } else if (params.ids) {
    multiple = true;
    ids = params.ids;
  }

  const promises = ids.map((id) => {
    const request = new DeleteBankAccountRequest();
    request.setId(id);
    return BankAccounts.deleteBankAccount(request, {
      authorization: localStorage.getItem("desana-tkn"),
    });
  });

  await Promise.all(promises);
  if (multiple) {
    return { data: params.ids };
  } else {
    return { data: params.previousData };
  }
};

export default remove;

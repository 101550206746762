import {
  ListBankAccountsRequest,
  ListBankAccountsResponse,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/bankaccount/v1/bank_account_pb";
import { GetOrganisationRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/organisation/v1/organisation_pb";
import {
  OrganisationAdminRole,
  SpaceAdminRole,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import { Paging } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import { GET_ONE, UPDATE } from "react-admin";
import { hasPermission } from "../../utils/auth";
import * as storage from "../../utils/storage";
import { bankAccountMapper } from "../bank-accounts/mapper";
import providers from "../providers";
import mapAdmin from "./admin/map-user";
import { update } from "./update";

const organisations = (type, params) => {
  switch (type) {
    case GET_ONE:
      return get(params);
    case UPDATE:
      return update(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for organisations ${type}`
      );
  }
};

export default organisations;

const get = async (params) => {
  const { Organisations, BankAccounts } = providers;
  const request = new GetOrganisationRequest();
  request.setId(params.id);
  const response = await Organisations.getOrganisation(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  const organisation = response.getResult().toObject();

  const currentOrg = storage.get("currentOrganisation", true);
  let bankAccounts = new ListBankAccountsResponse();
  if (
    hasPermission(
      currentOrg,
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_PAYMENT_DETAILS,
      SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_PAYMENT_DETAILS
    )
  ) {
    const bankAccountsRequest = new ListBankAccountsRequest();
    bankAccountsRequest.setOrganisationId(params.id);
    bankAccountsRequest.setPaging(new Paging().setFrom(0).setSize(100));
    bankAccounts = await BankAccounts.listBankAccounts(bankAccountsRequest, {
      authorization: localStorage.getItem("desana-tkn"),
    });
  }

  const bankAccountsList = bankAccounts
    .getResultsList()
    .map((b) => bankAccountMapper(b.toObject()));

  // If any of the bank accounts are not editable, none of them are editable
  const bankAccountsEditable = !bankAccountsList.some(
    ({ editable }) => !editable
  );

  return {
    data: {
      ...organisation,
      profileImage: { image: organisation.profileImage },
      bankAccountsList,
      bankAccountsEditable,
      adminsList: organisation.adminsList.map(mapAdmin),
    },
  };
};

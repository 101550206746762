import { GET_LIST, GET_MANY, GET_ONE, UPDATE } from "react-admin";
import { getResource } from "./get";
import { listResources } from "./list";
import { updateResource } from "./update";

const resources = (type, params, resourceType) => {
  switch (type) {
    case GET_LIST:
    case GET_MANY:
      return listResources(params, resourceType);
    case GET_ONE:
      return getResource(params);
    case UPDATE:
      return updateResource(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for resources ${type}`
      );
  }
};

export default resources;

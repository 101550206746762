import {
  CREATE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
  DELETE,
  DELETE_MANY,
} from "react-admin";

import create from "./create";
import get from "./get";
import list from "./list";
import remove from "./delete";
import update from "./update";

const bankAccounts = (type, params) => {
  switch (type) {
    case CREATE:
      return create(params);
    case GET_LIST:
    case GET_MANY:
      return list(params);
    case GET_ONE:
      return get(params);
    case UPDATE:
      return update(params);
    case DELETE:
    case DELETE_MANY:
      return remove(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for bank accounts ${type}`
      );
  }
};

export default bankAccounts;

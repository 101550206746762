import { OrganisationAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";

const hasPermission = (organisation, orgRole, spaceRole) => {
  if (!organisation) {
    return false;
  }

  if (!organisation.rolesList) {
    return false;
  }

  if (
    organisation.rolesList.includes(
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER
    )
  ) {
    return true;
  }

  if (orgRole) {
    if (organisation.rolesList.includes(orgRole)) {
      return true;
    }
  }

  if (spaceRole) {
    if (
      organisation.spacesList.some((space) =>
        space.rolesList.includes(spaceRole)
      )
    ) {
      return true;
    }
  }

  return false;
};

const hasSomePermissions = (organisation, orgRoles, spaceRoles) => {
  if (!organisation) {
    return false;
  }

  if (!organisation.rolesList) {
    return false;
  }

  if (
    organisation.rolesList.includes(
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER
    )
  ) {
    return true;
  }

  if (
    orgRoles &&
    orgRoles.some((role) => organisation.rolesList.includes(role))
  ) {
    return true;
  }

  if (
    spaceRoles &&
    spaceRoles.some((role) =>
      organisation.spacesList.some((space) => space.rolesList.includes(role))
    )
  ) {
    return true;
  }

  return false;
};

/**
 * Gets a list of space IDs which this user has permission to access
 * with the given SpaceAdminRole.
 * @param {desana.host.organisation.v1.Organisation} organisation The active organisation
 * @param {desana.type.v1.authentication.SpaceAdminRole[]} spaceRoles The space roles to filter the spaces by
 * @return {boolean | string[]} False if no matches, true if all spaces, or an array of space IDs
 */
const getSpacesForSpaceRole = (organisation, spaceRoles) => {
  if (!organisation) {
    return false;
  }

  if (!organisation.rolesList) {
    return false;
  }

  if (
    organisation.rolesList.includes(
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_OWNER
    )
  ) {
    return true;
  }

  if (
    organisation.rolesList.includes(
      OrganisationAdminRole.ORGANISATION_ADMIN_ROLE_VIEW_SPACES
    )
  ) {
    return true;
  }

  const spaceIds = organisation.spacesList
    .filter((space) =>
      space.rolesList.some((role) => spaceRoles.includes(role))
    )
    .map((space) => space.id);

  if (spaceIds.length > 0) {
    return spaceIds;
  }

  return false;
};

export { hasPermission, hasSomePermissions, getSpacesForSpaceRole };

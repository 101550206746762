import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { TabbedFormTabs } from "react-admin";
import { useSelector } from "react-redux";

export const DesanaTabbedFormTabs = (props) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMenuOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const menuWidth = isXSmall ? 0 : isMenuOpen ? 270 : 85;
  const asideWidth = props.hasAside ? (isSmall ? 0 : 215) : 0;
  const asideMargin = props.hasAside ? (isSmall ? `` : " - 2em") : ``;

  const offset = ` - ${menuWidth + asideWidth}px${asideMargin}`;

  return (
    <TabbedFormTabs
      variant="scrollable"
      scrollButtons="on"
      style={{
        width: `calc(100vw${offset})`,
      }}
      {...sanitizeRestProps(props)}
    />
  );
};

const sanitizeRestProps = ({ hasAside, ...props }) => props;

import { GET_ONE } from "react-admin";
import { GetUserRequest } from "@desanaio/public-hops-grpc-web/dist/desana/user/user/v1/user_pb";
import providers from "./providers";
import * as storage from "../utils/storage";

const users = (type, params) => {
  switch (type) {
    case GET_ONE:
      return get(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for users ${type}`
      );
  }
};

export default users;

const get = async (params) => {
  const { Users } = providers;
  const request = new GetUserRequest();
  request.setId(params.id);
  const response = await Users.getUser(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  const user = response.getResult().toObject();
  return {
    data: user,
  };
};

import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
} from "react-admin";

import {
  ListSpacesRequest,
  UpdateSpaceRequest,
  GetSpaceRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/space/v1/space_pb";

import {
  Paging,
  Sorter,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import { OrganisationMini } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/organisation_pb";
import {
  SpaceAmenity,
  SpaceRule,
  SpaceType,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/space_pb";
import { Address } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/address_pb";
import { Point } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/location_pb";
import { WifiDetails } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/wifi_details_pb";
import { DeleteSpaceImageRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/space/v1/space_pb";

import providers from "./providers";
import isEqual from "lodash/isEqual";
import xor from "lodash/xorWith";
import * as storage from "../utils/storage";
import { OpeningTimes } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/opening_times_pb";
import { getSpacesForSpaceRole } from "../utils/auth";
import { SpaceAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import { optimiseImage } from "../common/image-proxy";
const spaces = (type, params) => {
  switch (type) {
    case GET_MANY:
    case GET_LIST:
    case GET_MANY_REFERENCE:
      return getList(params);
    case GET_ONE:
      return getOne(params);
    case UPDATE:
      return update(params);
    case CREATE:
    case DELETE:
      throw new Error("Not implemented");
    // url = `${apiUrl}/${resource}/${params.id}`;
    // options.method = "DELETE";
    // break;
    // case GET_MANY: {
    //   const query = {
    //     filter: JSON.stringify({ id: params.ids })
    //   };
    //   url = `${apiUrl}/${resource}?${stringify(query)}`;
    //   break;
    // }
    // case GET_MANY_REFERENCE: {
    //   const { page, perPage } = params.pagination;
    //   const { field, order } = params.sort;
    //   const query = {
    //     sort: JSON.stringify([field, order]),
    //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //     filter: JSON.stringify({
    //       ...params.filter,
    //       [params.target]: params.id
    //     })
    //   };
    //   url = `${apiUrl}/${resource}?${stringify(query)}`;
    //   break;
    default:
      throw new Error(
        `Unsupported Data Provider request type for spaces ${type}`
      );
  }
};

export default spaces;

const getList = async (params) => {
  const service = providers.Spaces;
  const currentOrganisation = storage.get("currentOrganisation");

  const owner = new OrganisationMini();
  owner.setId(currentOrganisation.organisation.id);
  const request = new ListSpacesRequest();
  request.setOwner(owner);
  if (params.pagination) {
    const { page, perPage } = params.pagination;
    const pagination = new Paging();
    pagination.setFrom((page - 1) * perPage);
    pagination.setSize(perPage);
    request.setPaging(pagination);
  }

  const currentOrg = storage.get("currentOrganisation");
  const viewSpacePermissions = getSpacesForSpaceRole(currentOrg, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_DETAILS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_DETAILS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_PAYMENT_DETAILS,
  ]);

  // If we don't have permission to edit bookings
  if (viewSpacePermissions === false) {
    throw new Error("Permission denied");
  }

  if (viewSpacePermissions !== true) {
    request.setIdsList(viewSpacePermissions);
  }

  if (params.sort && params.sort.field !== "id") {
    const { field, order } = params.sort;
    const sorter = new Sorter();

    sorter.setKey(field.replace(/([A-Z])/g, "_$1").toLowerCase());
    sorter.setOrder(order.toLowerCase());
    request.setSort(sorter);
  }

  if (params.ids && params.ids.length) {
    if (viewSpacePermissions === true) {
      request.setIdsList(params.ids);
    } else {
      request.setIdsList(
        params.ids.filter((id) => viewSpacePermissions.includes(id))
      );
    }
    request.setPaging(new Paging().setSize(request.getIdsList().length));
  }

  const response = await service.listSpaces(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  if (params.ids) {
    const filteredResults = response
      .getResultsList()
      .filter((space) => params.ids.includes(space.getId()));
    return {
      total: filteredResults.length,
      data: filteredResults.map((result) => fromDatabase(result.toObject())),
    };
  }
  return {
    total: response.getCount(),
    data: response
      .getResultsList()
      .map((result) => fromDatabase(result.toObject())),
  };
};

const getOne = async (params) => {
  const service = providers.Spaces;
  const request = new GetSpaceRequest();
  request.setId(params.id);
  const response = await service.getSpace(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  return { data: fromDatabase(response.getResult().toObject()) };
};

const update = async (params) => {
  const service = providers.Spaces;
  const { data, previousData, id } = params;
  const updateRequest = new UpdateSpaceRequest();
  updateRequest.setId(id);
  if (!isEqual(data.amenitiesList, previousData.amenitiesList)) {
    updateRequest.addFieldsToUpdate("amenities");
    data.amenitiesList.forEach((a) => {
      const amenity = new SpaceAmenity();
      amenity.setId(a);
      updateRequest.addAmenities(amenity);
    });
  }
  if (!isEqual(data.rulesList, previousData.rulesList)) {
    updateRequest.addFieldsToUpdate("rules");
    data.rulesList.forEach((a) => {
      const rule = new SpaceRule();
      rule.setId(a);
      updateRequest.addRules(rule);
    });
  }
  if (!isEqual(data.address, previousData.address)) {
    if (data.address) {
      updateRequest.addFieldsToUpdate("address");
      const address = new Address();
      address.setCity(data.address.city);
      address.setCountry(data.address.country);
      address.setInstructions(data.address.instructions);
      address.setLine1(data.address.line1);
      address.setLine2(data.address.line2);
      const point = new Point();
      point.setLat(data.address.location.lat);
      point.setLon(data.address.location.lon);
      address.setLocation(point);
      address.setRegion(data.address.region);
      address.setZip(data.address.zip);
      updateRequest.setAddress(address);
    }
  }
  if (!isEqual(data.additionalRules, previousData.additionalRules)) {
    updateRequest.addFieldsToUpdate("additional_rules");
    updateRequest.setAdditionalRules(data.additionalRules);
  }
  if (!isEqual(data.advancedAvailability, previousData.advancedAvailability)) {
    updateRequest.addFieldsToUpdate("advanced_availability");
    updateRequest.setAdvancedAvailability(data.advancedAvailability);
  }
  if (!isEqual(data.autoresponder, previousData.autoresponder)) {
    updateRequest.addFieldsToUpdate("autoresponder");
    updateRequest.setAutoresponder(data.autoresponder);
  }
  if (data.capacity !== 0 && !isEqual(data.capacity, previousData.capacity)) {
    updateRequest.addFieldsToUpdate("capacity");
    updateRequest.setCapacity(data.capacity);
  }
  if (!isEqual(data.description, previousData.description)) {
    updateRequest.addFieldsToUpdate("description");
    updateRequest.setDescription(data.description);
  }
  if (!isEqual(data.downloadSpeed, previousData.downloadSpeed)) {
    if (!isNaN(data.downloadSpeed)) {
      const val = Math.round(data.downloadSpeed);
      updateRequest.addFieldsToUpdate("download_speed");
      updateRequest.setDownloadSpeed(val);
    }
  }
  if (!isEqual(data.listed, previousData.listed)) {
    updateRequest.addFieldsToUpdate("listed");
    updateRequest.setListed(data.listed);
  }
  if (!isEqual(data.minimumAdvanceNotice, previousData.minimumAdvanceNotice)) {
    updateRequest.addFieldsToUpdate("minimum_advance_notice");
    updateRequest.setMinimumAdvanceNotice(data.minimumAdvanceNotice);
  }
  if (!isEqual(data.name, previousData.name)) {
    updateRequest.addFieldsToUpdate("name");
    updateRequest.setName(data.name);
  }
  if (!isEqual(data.uploadSpeed, previousData.uploadSpeed)) {
    if (!isNaN(data.uploadSpeed)) {
      const val = Math.round(data.uploadSpeed);
      updateRequest.addFieldsToUpdate("upload_speed");
      updateRequest.setUploadSpeed(val);
    }
  }

  if (!isEqual(data.wifiDetails, previousData.wifiDetails)) {
    if (data.wifiDetails) {
      updateRequest.addFieldsToUpdate("wifi_details");
      const wifi = new WifiDetails();
      wifi.setAdditionalDetails(data.wifiDetails.additionalDetails);
      wifi.setNetworkName(data.wifiDetails.networkName);
      wifi.setPassword(data.wifiDetails.password);
      updateRequest.setWifiDetails(wifi);
    }
  }

  if (!isEqual(data.openingTimesMap, previousData.openingTimesMap)) {
    updateRequest.addFieldsToUpdate("opening_times");
    const openingTimes = fromInputOpeningTimes(data.openingTimesMap);
    Object.entries(openingTimes).forEach(([weekday, openingTime]) => {
      updateRequest
        .getOpeningTimesMap()
        .set(
          weekday,
          new OpeningTimes()
            .setOpen(
              new OpeningTimes.Time()
                .setHour(openingTime.open.hour)
                .setMinute(openingTime.open.minute)
            )
            .setClose(
              new OpeningTimes.Time()
                .setHour(openingTime.close.hour)
                .setMinute(openingTime.close.minute)
            )
        );
    });
  }

  const hasGrpcUpdate = updateRequest.getFieldsToUpdateList().length !== 0;

  let spaceUpdateTask;
  if (hasGrpcUpdate) {
    spaceUpdateTask = async () => {
      const response = await service.updateSpace(updateRequest, {
        authorization: storage.get("desana-tkn", false),
      });
      return fromDatabase(response.getResult().toObject());
    };
  } else {
    spaceUpdateTask = async () => {
      const getRequest = new GetSpaceRequest();
      getRequest.setId(params.id);
      const response = await service.getSpace(getRequest, {
        authorization: storage.get("desana-tkn", false),
      });
      if (!response) {
        throw new Error("No response returned from server");
      }
      return fromDatabase(response.getResult().toObject());
    };
  }

  if (!isEqual(data.imagesList, previousData.imagesList)) {
    const differentImages = xor(
      data.imagesList,
      previousData.imagesList,
      isEqual
    );

    for (const diff of differentImages) {
      if (diff.rawFile) {
        const formData = new FormData();
        formData.append(`image`, diff.rawFile);
        await fetch(`${providers.imageUploadUrl}/spaces/${id}`, {
          method: "POST",
          body: formData,
          headers: {
            authorization: storage.get("desana-tkn", false),
          },
        }).then((res) => res.json());
      }

      const deleteImageRequest = new DeleteSpaceImageRequest();
      deleteImageRequest.setId(id);
      deleteImageRequest.setUrl(diff.url);
      await service.deleteSpaceImage(deleteImageRequest, {
        authorization: storage.get("desana-tkn", false),
      });
    }
  }

  const updatedSpace = await spaceUpdateTask();
  return { data: updatedSpace };
};

const getTimeString = (time) => {
  const hour = `${time.hour}`.padStart(2, "0");
  const minute = `${time.minute}`.padStart(2, "0");
  return `${hour}:${minute}`;
};

const fromDatabase = (space) => ({
  ...space,
  amenitiesList: space.amenitiesList.map((a) => a.id),
  rulesList: space.rulesList.map((a) => a.id),
  openingTimesMap: [...Array(7)].map((_, index) => {
    const openingTime = space.openingTimesMap.find(
      (ot) => ot[0] === `${index + 1}`
    );
    return {
      index: index + 1,
      isOpen: !!openingTime,
      open: openingTime ? getTimeString(openingTime[1].open) : "09:00",
      close: openingTime ? getTimeString(openingTime[1].close) : "17:00",
    };
  }),
  optimisedImagesList: space.imagesList.map((image) => ({
    ...image,
    url: optimiseImage(image.url, { size: 0 }),
  })),
});

const fromInputOpeningTimes = (input) =>
  input.reduce((acc, current) => {
    if (!current.isOpen) {
      return acc;
    }

    const openParts = current.open.split(":");
    const closeParts = current.close.split(":");

    return {
      ...acc,
      [current.index]: {
        open: {
          hour: parseInt(openParts[0], 10),
          minute: parseInt(openParts[1], 10),
        },
        close: {
          hour: parseInt(closeParts[0], 10),
          minute: parseInt(closeParts[1], 10),
        },
      },
    };
  }, {});

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import sanitizeRestProps from "ra-ui-materialui/lib/field/sanitizeFieldRestProps";
import Typography from "@material-ui/core/Typography";

const toLocaleStringSupportsLocales = (() => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString("i");
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();

const LocalDateTimeField = ({
  className,
  locales,
  options,
  record,
  showTime = false,
  showDate = true,
  source,
  timezoneSource,
  ...rest
}) => {
  const value = get(record, source);
  const timezoneValue = get(record, timezoneSource) || "Etc/UTC";
  const date = value instanceof Date ? value : new Date(value);

  let dateString = "";
  if (showTime && showDate) {
    dateString = toLocaleStringSupportsLocales
      ? date.toLocaleString(locales, { ...options, timeZone: timezoneValue })
      : date.toLocaleString();
  } else if (showDate && !showTime) {
    dateString = toLocaleStringSupportsLocales
      ? date.toLocaleDateString(locales, {
          ...options,
          timeZone: timezoneValue,
        })
      : date.toLocaleDateString();
  } else if (!showDate && showTime) {
    dateString = toLocaleStringSupportsLocales
      ? date.toLocaleTimeString(locales, {
          ...options,
          timeZone: timezoneValue,
        })
      : date.toLocaleTimeString();
  }

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeRestProps(rest)}
    >
      {dateString}
    </Typography>
  );
};

LocalDateTimeField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  locales: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.object,
  record: PropTypes.object,
  showTime: PropTypes.bool,
  showDate: PropTypes.bool,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  timezoneSource: PropTypes.string,
};

LocalDateTimeField.defaultProps = {
  addLabel: true,
  timezoneSource: "timezone",
};

export default LocalDateTimeField;
